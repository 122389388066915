import {__DEV__} from "../config/debug.config";
import {store} from "../redux/store";
import {UserAction} from "../redux/reducers/user";

const axios = require('axios');
const qs = require('qs');


export class ApiError extends Error {
    constructor(code, ...args) {
        super(...args)

        this.code = code;
    }
}

export const API_ERROR = {
    //Auth errors
    UNAUTHENTICATED: 100,

    NOT_ENOUGH_FUNDS: 201,


    //Server errors
    INTERNAL_SERVER_ERROR: 500,
}

function ApiCache (){
    this.cache =  {}

    this.getTimeNow = ()=> Date.now()

    this.set = (key, data, lifeTime)=>{
        if(!lifeTime) lifeTime = 5 * 60;
        lifeTime = lifeTime * 1000;
        this.cache[key] = {data, time: this.getTimeNow()+lifeTime}
    }

    this.get = (key)=>{
        if(!this.cache[key] || this.cache[key].time < this.getTimeNow()) return false;
        return this.cache[key].data;
    }

    this.clear = (key)=>{
        delete this.cache[key];
    }
}

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            if(key.includes('_raw')) return;
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

function clearRAWFromParams(params){
    if(!params) return params;
    let newParams = {};
    Object.keys(params).forEach(key => {
        if(key.includes('_raw')) return;
        if(typeof params[key] === 'object' && !params[key].length){
            newParams[key] = clearRAWFromParams(params[key])
        }else{
            newParams[key] = params[key];
        }
    });
    return newParams;
}

// our "constructor"
const create = (baseURL = document.location.protocol + '//api-' + document.location.hostname  ) => {

    let apiCache = new ApiCache();

    const api = axios.create({
        baseURL: baseURL,
        responseType: "json",
        headers: {
            "X-Requested-With": "XMLHttpRequest",
        },
        // timeout: 60000,
    });

    const makeRequest = (method, path, params = null, withCache = false, cacheLifeTime = false) => {
        let cacheKey = path + (params ? '_' + JSON.stringify(params) : '');
        // console.log('cacheKey', cacheKey);
        if(withCache){
            let data = apiCache.get(cacheKey);
            if(data) return Promise.resolve(data);
        }

        let props = {};
        if(method === 'get'){
            props.paramsSerializer = params => {
                return qs.stringify(params)
            }
            props.params = params ? clearRAWFromParams(params) : params;
        }else{
            const formData = new FormData();
            if(params) buildFormData(formData, params);
            props.data = formData;
            props.headers = {
                'Content-Type': 'multipart/form-data'
            };
        }

        return api({
            method: method,
            url: path,
            withCredentials: true,

            ...props,
            // paramsSerializer: params => {
            //     return qs.stringify(params)
            // }
            // ...method === 'get' ? {params} : {data:params}
        }).then((response) => {
            console.log('api.response', response);
            if (!response) {
                return Promise.reject();
            }

            if (response.data && response.data.error) {
                if(API_ERROR.UNAUTHENTICATED === parseInt(response.data.error.code)){
                    store.dispatch(UserAction.logoutClientOnly());
                }
                return Promise.reject(response.data.error)
            }

            if(withCache) apiCache.set(cacheKey, response.data, cacheLifeTime);
            return Promise.resolve(response.data);
        });
    }


    // const getEncodedGetParams = function(obj, prefix) {
    //   let str = [], p;
    //   for(p in obj) {
    //     if (obj.hasOwnProperty(p)) {
    //       let k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
    //       str.push((v !== null && typeof v === "object") ?
    //         getEncodedGetParams(v, k) :
    //         encodeURIComponent(k) + "=" + encodeURIComponent(v));
    //     }
    //   }
    //   return str.join("&");
    // }

    // const makeRequest = (path, params = null) => {
    //   //return api.get(path, params).then((response)=>{
    //   return api.post(path, getEncodedGetParams(params)).then((response)=>{
    //     if (!response.ok){
    //       return Promise.reject(new Error( response.problem+' error' ));
    //     }
    //
    //     if(!response.data || !response.data.status || response.data.status !== 'ok') {
    //       console.warn('API error', response);
    //       let error = new ApiError(response.data.error.code, response.data.error.message || 'standart error');
    //       error.fieldName = response.data.error.field_name;
    //       error.fieldValue = response.data.error.field_value;
    //       return Promise.reject(error);
    //     }
    //     // console.warn('response', response.data.data);
    //     return Promise.resolve(response.data.data);
    //   })
    // }


    return {
        api: api,

        get: (path, params) => {
            return makeRequest('get', path, params);
        },

        getCSRFCookie: () => {
            // return Promise.resolve();
            // return makeRequest('get', '/sanctum/csrf-cookie');
            return makeRequest('get', '/api/panel/info/ping');
        },

        signUp: (data) => {
            return makeRequest('post', '/api/panel/auth/signup', data);
        },

        signIn: (email, password) => {
            return makeRequest('post', '/api/panel/auth/signin', {email, password});
        },

        logout: () => {
            return makeRequest('get', '/api/panel/auth/logout', {});
        },

        forgotPassword: (email) => {
            return makeRequest('post', '/api/panel/auth/forgot-password', {email});
        },

        resetPassword: (email, token, password, password_confirmation) => {
            return makeRequest('post', '/api/panel/auth/reset-password', {
                email,
                token,
                password,
                password_confirmation
            });
        },

        getUserInfo: () => {
            return makeRequest('get', '/api/panel/user/info', {});
        },

        profileUpdate: (data) => {
            return makeRequest('post', '/api/panel/user/profile/update', data);
        },

        promoCodeUpdate: (code) => {
            return makeRequest('post', '/api/panel/user/promocode/update', {code});
        },

        cloneApps: (appId, teamId, newApps) => {
            return makeRequest('post', '/api/panel/clone/pwa', {
                app_id: appId,
                team_id: teamId,
                newapps: newApps
            });
        },

        getStatisticsDashboard: () => {
            return makeRequest('get', '/api/panel/statistics/dashboard', {}, true);
        },
        getStatisticsAppVisitPlatforms: ({appId}) => {
            return makeRequest('get', '/api/panel/statistics/app/visitplatforms', {
                app_id: appId
            }, true);
        },
        getStatisticsAppQueryParams: ({appId}) => {
            return makeRequest('get', '/api/panel/statistics/app/queryparams', {
                app_id: appId
            }, true);
        },
        getStatisticsTop100: (pageIndex, pageSize, sortBy, filter, withCache = true) => {
            return makeRequest('get', '/api/panel/statistics/top100', {
                page: pageIndex,
                limit: pageSize,
                sort: sortBy,
                filter: filter
            }, withCache);
        },
        getStatisticsTeams: (pageIndex, pageSize, sortBy, filter, withCache = true) => {
            return makeRequest('get', '/api/panel/statistics/teams', {
                page: pageIndex,
                limit: pageSize,
                sort: sortBy,
                filter: filter
            }, withCache);
        },
        getStatisticsAppSummary: (appId, pageIndex, pageSize, sortBy, filter, withCache = false) => {
            return makeRequest('get', '/api/panel/statistics/app/summary', {
                app_id: appId,
                page: pageIndex,
                limit: pageSize,
                sort: sortBy,
                filter: filter
            }, withCache);
        },
        getStatisticsAppPostbacks: (appId, pageIndex, pageSize, sortBy, filter, withCache = false) => {
            return makeRequest('get', '/api/panel/statistics/app/postbacks', {
                app_id: appId,
                page: pageIndex,
                limit: pageSize,
                sort: sortBy,
                filter
            }, withCache);
        },
        getStatisticsAppLog: (appId, pageIndex, pageSize, sortBy, filter, withCache = false) => {
            return makeRequest('get', '/api/panel/statistics/app/log', {
                app_id: appId,
                page: pageIndex,
                limit: pageSize,
                sort: sortBy,
                filter
            }, withCache);
        },
        getStatisticsGeneral: (filter, withCache = false) => {
            return makeRequest('get', '/api/panel/statistics/general', {filter}, withCache);
        },

        getResourceList: (resource, pageIndex, pageSize, sortBy, filter, withCache = false) => {
            return makeRequest('get', `/api/panel/res/${resource}/list`, {
                page: pageIndex,
                limit: pageSize,
                sort: sortBy,
                filter
            }, withCache);
        },

        getResource: (resource, resId, withCache = false) => {
            return makeRequest('get', `/api/panel/res/${resource}/id/${resId}`, {}, withCache);
        },

        resourceUpdate: (resource, formData) => {
            return makeRequest('post', `/api/panel/res/${resource}/update`, formData);
        },

        resourceAction: (resource, action, formData) => {
            return makeRequest('post', `/api/panel/res/${resource}/action/${action}`, formData);
        },

        resourceDelete: (resource, id, customDeleteOptions = {}) => {
            return makeRequest('post', `/api/panel/res/${resource}/delete`, {id, ...customDeleteOptions});
        },

        resourceBulkDelete: (resource, ids, customDeleteOptions = {}) => {
            return makeRequest('post', `/api/panel/res/${resource}/bulkdelete`, {ids, ...customDeleteOptions});
        },

        getRoles: () => {
            return makeRequest('get', '/api/panel/info/roles', {});
        },

        getPermissions: () => {
            return makeRequest('get', '/api/panel/info/permissions', {});
        },

        getExternalServicesTypes: () => {
            return makeRequest('get', '/api/panel/info/extservicetypes', {}, true);
        },

        getExternalServicesByCategory: (category) => {
            return makeRequest('get', '/api/panel/info/extservices', {category});
        },

        getAdPlatforms: () => {
            return makeRequest('get', '/api/panel/info/adplatforms', {}, true);
        },

        getCountries: () => {
            return makeRequest('get', '/api/panel/info/countries', {}, true);
        },

        getLocales: () => {
            return makeRequest('get', '/api/panel/info/locales', {}, true);
        },

        getLocaleswithcountries: () => {
            return makeRequest('get', '/api/panel/info/localeswithcountries', {}, true);
        },

        getUrlMakerTypes: () => {
            return makeRequest('get', '/api/panel/info/urlmakertypes', {}, true);
        },

        getProxyTypes: () => {
            return makeRequest('get', '/api/panel/info/proxytypes', {}, true);
        },

        getOSPlatforms: () => {
            return makeRequest('get', '/api/panel/info/osplatforms', {}, true);
        },

        getLandings: () => {
            return makeRequest('get', '/api/panel/info/landings', {}, true);
        },

        getSettings: () => {
            return makeRequest('get', '/api/panel/info/settings', {}, true);
        },

        commentsGenerate: (category, lang, count) => {
            return makeRequest('get', '/api/panel/comments/generate', {
                category, lang, count
            }, false);
        },

        paymentTopUp: (currency, amount) => {
            return makeRequest('post', '/api/panel/payment/topup', {
                currency, amount
            }, false);
        },

        paymentGetWallet: (currency) => {
            return makeRequest('post', '/api/panel/payment/getwallet', {
                currency
            }, false);
        },

        paymentRenewSubscription: (teamId) => {
            return makeRequest('post', '/api/panel/payment/renew-subscription', {
                team_id: teamId
            }, false);
        },

        paymentWithdraw: (currency, amount, wallet) => {
            return makeRequest('post', '/api/panel/payment/withdraw', {
                currency, amount, wallet
            }, false);
        },
        paymentWithdrawAdmin: (currency, amount, wallet, reason) => {
            return makeRequest('post', '/api/panel/payment/withdraw-admin', {
                currency, amount, wallet, reason
            }, false);
        },
        distributeDividends: () => {
            return makeRequest('get', '/api/panel/project/finance/distributedividends', {
            }, false);
        },

        getProjectSettings: () => {
            return makeRequest('get', `/api/panel/project/settings`, {}, false);
        },

        updateProjectSettings: (formData) => {
            return makeRequest('post', `/api/panel/project/settings/update`, formData);
        },

        clonePushNewsletter: (id) => {
            return makeRequest('post', '/api/panel/clone/pushnewsletter', {
                id,
            });
        },

    }
}

export default create()


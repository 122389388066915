import React, {useMemo} from 'react'
import PropTypes from 'prop-types'

export const InfoBox = ({
                             className = '',
                             inline = false,
                             label = '',
                             labelBold = false,
                             flex = {},
                             text,
                             children,
                             ...rest
                         }) => {
    return (
        <div className={`form-element ${inline ? 'form-element-inline' : ''} ${className}`}>
            {label && <div className={`form-label ${labelBold? 'font-bold' : ''}`}>{label}</div>}
            <div className={`bg-grey-100 dark:bg-grey-800 px-4 py-4 rounded`}>
                {text}
            </div>
        </div>
    )
}
InfoBox.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

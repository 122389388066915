import useSWR, {useSWRConfig} from 'swr'
import {useEffect} from "react";
import Api from "./api";

const fetcher = (url, params) => Api.get(url, params)

// const fetcher = (...args) => fetch(...args).then(res => res.json())

export function useRevalidate() {
    const { cache, mutate } = useSWRConfig();

    return (path) => {
        const pattern = new RegExp(path);
        for (const key of cache.keys()) {
            if(pattern.test(key)) mutate(key);
        }
    }
}

export function useDashboard () {
    const { data, error, mutate } = useSWR(`/api/panel/statistics/dashboard`, fetcher)

    return {
        dashboard: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    }
}

export function usePaymentOrder (orderId, isRefresh = false) {
    const { data, error, mutate } = useSWR(orderId ? `/api/panel/payment/order/${orderId}` : null, fetcher, {
        refreshInterval: isRefresh ? 5 * 1000 : 0,
    })

    return {
        order: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    }
}

export function useProjectFinance () {
    const { data, error, mutate } = useSWR(`/api/panel/project/finance`, fetcher, {
        refreshInterval: 5 * 60 * 1000,
    })

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    }
}

export function usePushSubscriptionStats () {
    const { data, error, mutate } = useSWR(`/api/panel/push/stats`, fetcher, {
        refreshInterval: 5 * 60 * 1000,
    })

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    }
}

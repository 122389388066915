import React, {useState, useMemo, useEffect, useCallback, useRef} from 'react'
import {useTable, useSortBy, usePagination, useRowSelect} from 'react-table'
import {PageWithText} from './../pagination'
import {FiChevronDown, FiChevronUp} from 'react-icons/fi'
import {OverlayLoader} from './../loader'
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import qs from "qs";
import {useTranslation} from "react-i18next";
import {FiEdit, FiTrash2} from 'react-icons/fi'


export const HeaderSmall = ({title, className = ''})=>{
    return (
        <small className={`font-bold ${className}`}>{title}</small>
    )
}

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <input
                type="checkbox"
                ref={resolvedRef}
                {...rest}
                className="form-checkbox h-4 w-4"
            />
        )
    }
)

export const Action = ({
                           id, recordData,
                           title, Icon, color = 'blue',
                           href = '', onHrefPrepare = (href, rowData) => href,
                           onClick = (id, dataTable)=>{},
                           canShow = (recordData)=>true,
                           target,
                           dataTable, className = '',
})=> {
    let { url } = useRouteMatch();
    let history = useHistory();

    const _href = useMemo(()=>{
        if(!href) return false;

        return onHrefPrepare(href, recordData).replace(/:id/g,id).replace(/:url/g,url);
    }, [href, id, url, recordData]);

    const _onClick = useCallback(e => {
        if(_href && !_href.startsWith('http')){
            e.preventDefault();
            history.push(_href);
        }

        onClick && onClick(id, dataTable, recordData);
    }, [id, dataTable, _href, ])

    if(!canShow(recordData)) return null;

    let params = {
        target,
        onClick: _onClick,
        className: `btn btn-sm btn-rounded btn-icon bg-transparent btn-start hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600 ${className}`,
    }
    if(_href) params.href = _href;

    return <a {...params}>
        {<Icon className={`stroke-current ${title ? `mr-2`:``}`} />}
        <span>{title}</span>
    </a>
}
export const ActionEdit = ({title, href, ...rest})=> {
    const {t} = useTranslation();
    return <Action title={title === false ? '' : (title || t('datatable.action.edit'))} Icon={FiEdit} {...rest} href={href || `:url/edit/:id`} />
}
export const ActionDelete = ({title, ...rest})=> {
    const {t} = useTranslation();
    return <Action title={title || t('datatable.action.delete')} Icon={FiTrash2} color="red" {...rest} />
}

function getAccessors(columns, accessors = []){
    columns.forEach((column, idx)=>{
        if(column.accessor) accessors.push(column.accessor);
        if(column.columns) accessors = getAccessors(column.columns, accessors)
    })

    return accessors;
}

const Datatable = React.forwardRef(({
                       columns, fetchData,
                       pageIndex: initPageIndex = 0, pageSize: initPageSize = 25,
                       sortBy: initSortBy = {id: 'desc'},
                       canSelectRow = false, onRowSelect = (selectedRowsIds)=>{}, selectedRowIds = [],
                       actions = [],
                       filter = false,
                       pageSizes = [1, 10, 25, 50, 100, 500],
                   }, ref) => {
    const location = useLocation()
    const history = useHistory();
    const mountedRef = useRef(true)

    let allAccessors = useMemo(()=>{
        return getAccessors(columns);
    }, [columns]);
    let initSort = useMemo(
        () => {
            let result = [];
            let querySort = qs.parse(location.search.split('?')[1])

            initPageIndex = querySort.page ? parseInt(querySort.page) : initPageIndex;
            initPageSize = querySort.limit ? parseInt(querySort.limit) : initPageSize;

            if (querySort.sort) {
                initSortBy = querySort.sort;
            }
            for (let k in initSortBy) {
                if (!initSortBy.hasOwnProperty(k)) continue;
                if (!allAccessors.includes(k)) continue;
                result.push({id: k, desc: initSortBy[k] === 'desc'})
            }

            return result;
        },
        [location.search, allAccessors]
    );

    const [isLoading, setLoading] = useState(false)
    const [pageInfo, setPageInfo] = useState({
        data: [],
        pageCount: 0,
        pageIndex: initPageIndex,
        pageSize: initPageSize,
    })
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize, sortBy, selectedRowIds: _selectedRowIds}
    } = useTable(
        {
            columns,
            data: pageInfo.data,
            initialState: {
                pageIndex: pageInfo.pageIndex,
                pageSize: pageInfo.pageSize,
                sortBy: initSort,
                selectedRowIds: selectedRowIds,
            },
            pageCount: pageInfo.pageCount,
            manualPagination: true,
            manualSortBy: true,
            defaultCanSort: false,
            disableMultiSort: false,
            maxMultiSortColCount: 3,
            dataTable: ref?.current,
        },
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            if (canSelectRow) {
                hooks.visibleColumns.push(columns => [
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => (
                            <>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </>
                        )
                    },
                    ...columns
                ])
            }
            if(actions && actions.length > 0){
                hooks.visibleColumns.push(columns => [
                    ...columns,
                    {
                        id: 'actions',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: 'Actions',
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => (
                            <>
                                {actions.map((ActionComp, k) => <ActionComp.type {...ActionComp.props} key={k} id={row.values.id} recordData={row.original} dataTable={ref.current} />)}
                            </>
                        )
                    },
                ])
            }
        }
    )

    let sortByMemo = useMemo(
        () => sortBy.reduce((result, item) => {
            result[item.id] = item.desc ? 'desc' : 'asc';
            return result;
        }, {}),
        [sortBy]
    );

    let loadPageInProgress = false;
    const loadPage = useCallback(() => {
        if(loadPageInProgress) return;
        loadPageInProgress = true;
        setLoading(true);
        fetchData({pageIndex, pageSize, sortBy: sortByMemo, filter})
            .then((data) => {
                loadPageInProgress = false;
                if (!mountedRef.current) return null
                setPageInfo({
                    ...pageInfo,
                    data: data.data,
                    pageCount: data.meta.last_page,
                    pageIndex: pageIndex,
                    pageSize: pageSize,
                })
                setLoading(false);

                history.replace(`?${qs.stringify({
                    page: pageIndex,
                    limit: pageSize,
                    sort: sortByMemo
                })}`, history.location.state)
            })
            .catch((e) => {
                loadPageInProgress = false;
                if (!mountedRef.current) return null
                console.log('DataContent.fetchData.error', e);
                setPageInfo({
                    ...pageInfo,
                    pageIndex: pageIndex - 1,
                })
                setLoading(false);
            })
    }, [pageIndex, pageSize, sortByMemo, filter]);
    useEffect(()=>{
        if(!pageInfo?.data || !_selectedRowIds) return;
        let ids = Object.keys(_selectedRowIds || {}).filter((key)=>_selectedRowIds[key] && pageInfo?.data[key]).map((key, idx)=>{
            return pageInfo?.data[key].id;
        })
        onRowSelect(ids);
    }, [Object.keys(_selectedRowIds || {}).join(','), pageInfo?.data])
    useEffect(()=>{
        if(pageIndex === 0) return loadPage();
        gotoPage(0);
    }, [filter])
    useEffect(()=>{
        mountedRef.current = true;
        loadPage();
        return () => {
            mountedRef.current = false;
        }
    }, [pageIndex, pageSize, sortByMemo])

    React.useImperativeHandle(
        ref,
        () => ({
            refreshPage: loadPage
        }),
    )

    // Render the UI for your table
    return (
        <OverlayLoader show={isLoading}>
            <table {...getTableProps()}  className="table">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps([
                                column.getSortByToggleProps(),
                                column.headerProps || {},
                            ])}>
                                <div className="flex flex-row items-center justify-start">
                                    <span>{column.render('Header')}</span>
                                    {/* Add a sort direction indicator */}
                                    <span className="ml-auto">
                  {column.isSorted ? (
                      column.isSortedDesc ? (
                          <FiChevronDown className="stroke-current text-2xs"/>
                      ) : (
                          <FiChevronUp className="stroke-current text-2xs"/>
                      )
                  ) : (
                      ''
                  )}
                </span>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr className="hover:bg-grey-50" {...row.getRowProps()}>
                            {row.cells.map((cell, idx) => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>

            <div className="flex flex-row items-center justify-between my-4">
                <div className="flex flex-wrap items-center justify-start space-x-2 pagination">
                    {pageIndex !== 0 && (
                        <PageWithText onClick={() => gotoPage(0)}>First</PageWithText>
                    )}
                    {canPreviousPage && (
                        <PageWithText onClick={() => previousPage()}>Previous</PageWithText>
                    )}
                    {canNextPage && (
                        <PageWithText onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </PageWithText>
                    )}
                    {pageIndex !== pageCount - 1 && (
                        <PageWithText
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}>
                            Last
                        </PageWithText>
                    )}
                </div>

                <span>
                Page{' '}
                    <b>{pageIndex + 1} of {pageOptions.length}</b>{' '}
            </span>

                <select
                    className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}>
                    {pageSizes.map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </OverlayLoader>
    )
})

export default Datatable

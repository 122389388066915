import React from 'react'
import PropTypes from 'prop-types'

const Widget = ({title = null, description = null, right = null, children, titleUppercase, className = ''}) => {
  return (
    <div className={`widget  p-4 mb-4 rounded-lg bg-white border border-grey-100 dark:bg-grey-895 dark:border-grey-890 ${className}`}>
      {(title || description || right) && (
        <div className="flex flex-row items-start justify-between ">
          <div className="flex flex-col">
            <div className={`text-sm font-light text-grey-500 ${titleUppercase ? 'uppercase' : ''}`}>{title}</div>
            <div className="text-sm font-bold">{description}</div>
          </div>
          {right}
        </div>
      )}
      {children}
    </div>
  )
}

Widget.propTypes = {
  title: PropTypes.any,
  titleUppercase: PropTypes.bool,
  description: PropTypes.any,
  right: PropTypes.any,
  children: PropTypes.any
}
export default Widget

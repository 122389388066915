import React from 'react'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {FiMenu, FiUserPlus} from 'react-icons/fi'
import {FaTelegram} from 'react-icons/fa'
import Dropdown5 from './dropdown-5'
import './styles.css'
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../redux/store";
import {MdHelpOutline, MdOutlineHelp} from "react-icons/md";

const Navbar = () => {
    const {t} = useTranslation();
    const {collapsed} = useSelector(
        state => ({
            collapsed: state.uiConfig.collapsed
        }),
        shallowEqual
    )
    const {settings} = useAppSelector();

    const dispatch = useDispatch()
    return (
        <div className="navbar navbar-1 border-b">
            <div className="navbar-inner w-full flex items-center justify-start">
                <button
                    onClick={() =>
                        dispatch({
                            type: 'SET_CONFIG_KEY',
                            key: 'collapsed',
                            value: !collapsed
                        })
                    }
                    className="mx-4">
                    <FiMenu size={20}/>
                </button>
                <span className="ml-auto"></span>

                {settings.news_tg_channel && <a href={settings.news_tg_channel} target={`_blank`}
                                                className="hidden lg:flex relative
                            btn btn-default btn-rounded btn-icon bg-transparent hover:bg-blue-50  hover:text-blue-600
                            items-center justify-center ">
                    <FaTelegram size={16} className="stroke-current mr-2"/>
                    <span className={`hidden sm:block`}>{t(`navbar.tg_news`)}</span>
                </a>}
                <a href={`https://gdskaa.gitbook.io/untitled`} target={`_blank`}
                   className="hidden lg:flex relative
                            btn btn-default btn-rounded btn-icon bg-transparent hover:bg-blue-50  hover:text-blue-600
                            items-center justify-center ">
                    <MdOutlineHelp size={16} className="stroke-current mr-2"/>
                    <span className={`hidden sm:block`}>{t(`navbar.docs`)}</span>
                </a>

                <Dropdown5/>
                <span className="mr-5"></span>
            </div>
        </div>
    )
}

export default Navbar

import React, {useState} from 'react'
import Widget from "./index";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../redux/store";
import {TextArea, TextInput} from "../form/field/text-inputs";
import {Action} from "../datatable";
import {FiCopy, FiEdit, FiPlus, FiPlusSquare, FiUserPlus} from "react-icons/fi";
import {copyToClipboard} from "../../helper";
import {ModalTopUp} from "../modals";
import {ModalPromoCode} from "../modals/modal.promocode";

function EmptyList({data, total}){
    const {t} = useTranslation();
    return <div className={`p-4 text-base place-self-center text-center items-center justify-center`}>
        {t(`widget.referrals.empty_ref_list`)}
    </div>
}

function LastReferralsList({data, total}){
    const {t} = useTranslation();

    return (
        <>
            <div className="form-label">Last 5 partners</div>
            {!data || data.length <= 0 ?
                <EmptyList />
                : data.map((referral, idx)=>{
                        return <div key={referral.id} className={`my-1 text-base`}>
                            <small>{idx+1}.</small>{referral.name}
                        </div>
                    })}

            {/*<div className={`align-self-end font-bold`}>Total: {total}</div>*/}
        </>
    )
}

export const WidgetReferrals = ({data, total, promoCode, className = ''}) => {
    const {t} = useTranslation();
    const {user} = useAppSelector()
    const [isPromoCodeModalVisible, setPromoCodeModalVisible] = useState(false);

    let inviteLink = `${document.location.protocol}//${document.location.hostname}${document.location.port && document.location.port !== 80 ? `:${document.location.port}` : ``}/auth/signup?rid=${user.invite_code}`;

    return (
        <Widget title={t(`widget.referrals.title`)} description={t(`widget.referrals.description`)} className={className}>
            <div className={`mt-4 flex flex-col lg:flex-row gap-8`}>
                <div className={`flex-1 flex items-center`}>
                    <div className={`flex-auto`}>
                        <div className={`form-label`}>{t(`widget.referrals.promo_code`)}</div>
                        <div className={`flex flex-row gap-1`}>
                            {!promoCode ? <Action className={``} Icon={FiPlusSquare} onClick={() => setPromoCodeModalVisible(true)}
                                    title={t(`widget.referrals.create_promo_code`)}
                                />
                                : <>
                            <div className={`text-2xl text-green-700 font-bold  mt-1`}>{promoCode}</div>
                            <Action className={``} Icon={FiCopy} onClick={() => copyToClipboard(promoCode)}/>
                            <Action className={``} Icon={FiEdit} onClick={() => setPromoCodeModalVisible(true)}/>
                            </>}
                        </div>


                        {/*<TextArea label={t(`widget.referrals.invite_link`)}*/}
                        {/*          defaultValue={inviteLink} readOnly />*/}
                        <TextInput className={`mt-4`} label={t(`widget.referrals.invite_link`)} defaultValue={inviteLink} readOnly/>
                        <Action className={``} Icon={FiCopy} onClick={() => copyToClipboard(inviteLink)}
                                title={t(`widget.referrals.copy_link`)}/>
                    </div>
                </div>
                <div className={`w-full lg:w-1/3 flex flex-col`}>
                    <LastReferralsList data={data} total={total}/>
                </div>
            </div>

            <ModalPromoCode
                isOpen={isPromoCodeModalVisible}
                onCancel={()=>setPromoCodeModalVisible(false)}
            />
        </Widget>
    )
}

import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from "react-i18next";
import {Select} from "./selects";
import Api from "../../../service/api";
import * as R from 'ramda'
import {TextInput} from "./text-inputs";

export const ExtServiceType = ({
                           formRegister = ()=>{},
                           formErrors  =[],
                           formInitialValues  =[],
                           inputRef = false,
                           inline = false,
                           ...rest
                       }) => {
    let propServiceType = formInitialValues['service'] || '';
    const {t} = useTranslation();
    const [serviceType, setServiceType] = useState(propServiceType)
    const [serviceTypes, setServiceTypes] = useState([])
    const [serviceFields, setServiceFields] = useState([])
    const { ref: serviceRef, ...serviceRest  } = formRegister('service', {
        required: t('form.error.required', {name: t(`form.field.extservice_type.servicetype`)}),
    });
    const onSelectChange = useCallback((e) => {
        let value = e.currentTarget.value;
        const idx = R.findIndex(R.propEq('value', value))(serviceTypes)
        if(idx < 0) return;
        setServiceFields(serviceTypes[idx].fields);
        setServiceType(value);
        serviceRest.onChange(e);
    }, [serviceTypes]);
    useEffect(() => {
        Api.getExternalServicesTypes()
            .then((data) => {
                setServiceTypes([
                    {value: '', label: '', fields: []},
                    ...data.map((item, idx, allItems)=>{
                        return {value: item.id, label: item.name, fields: item.fields};
                    })
                ]);
            })
    }, []);
    useEffect(() => {
        if(!serviceTypes) return;
        serviceTypes.forEach((item, idx, allItems)=> {
            if (item.value === serviceType) {
                setServiceFields(item.fields);
            }
        })
    }, [serviceType, serviceTypes]);
    useEffect(() => {
        setServiceType(propServiceType);
        rest.setValue('service', propServiceType);
    }, [propServiceType]);

    return (
        <>
            <Select key={'service-'+serviceType} {...rest}
                    label={t('form.field.extservice_type.servicetype')}
                    name='service'
                    inline={inline}
                    inputRef={serviceRef}
                    {...serviceRest}
                    options={serviceTypes}
                    onChange={onSelectChange}
                    defaultValue={serviceType}
                    errorMessage={formErrors['service'] ? formErrors['service'].message : ''}
            />
            {serviceFields ? serviceFields.map((field, idx, allFields)=>{
                let fieldName = `service_data.${field}`;
                const { ref: fieldRef, ...fieldRest  } = formRegister(fieldName, {
                    required: t('form.error.required', {name: t(`resource_page.external_service.field.service_field.${field}`)})
                });
                return (
                    <TextInput key={field+'-'+idx}
                               inputRef={fieldRef}
                               {...fieldRest}
                               name={fieldName}
                               defaultValue={formInitialValues.service_data && formInitialValues.service_data[field] ? formInitialValues.service_data[field] : ''}
                               label={t(`resource_page.external_service.field.service_field.${field}`)}
                               errorMessage={formErrors.service_data && formErrors.service_data[field] ? formErrors.service_data[field].message : ''}
                    />
                )
            }) : []}
        </>
    )
}
ExtServiceType.propTypes = {
    inline: PropTypes.bool,
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}

import React, {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import AuthLayout from './layout'
import ResetPasswordForm from '../../components/form/auth/reset.password'
import {useTranslation} from 'react-i18next';
import {useQuery} from "../../helper";
import CompleteAndSignIn from "../../components/form/auth/complete.signin";

const ResetPassword = () => {
    const {t} = useTranslation();
    const query = useQuery();
    const {token} = useParams();
    const [{isComplete, formData}, setComplete] = useState({isComplete: false, formData: false})

    return (
        <AuthLayout imgUrl="/pages/auth/illustration.svg"
                    title={t('auth.reset_password.title')}
                    description={t('auth.reset_password.description')}>
            {!isComplete ?
                <>
                    <ResetPasswordForm token={token}
                                       email={query.get("email")}
                                       onComplete={(result, formData) => setComplete({isComplete: true, formData})}/>
                    <div className="flex flex-row w-full mt-6">
                        <span>
                          <Link className="link" to="/auth/signin">
                            {t('auth.forgot_password.goback_to_login')}
                          </Link>
                        </span>
                    </div>
               </>
                : <CompleteAndSignIn title={t('auth.reset_password.complete')}
                                     description={t('auth.reset_password.complete_description')}
                                     buttonText={t('auth.reset_password.complete_button')}
                                     signinData={{token, email: query.get("email"), ...formData}} /> }
        </AuthLayout>
    )
}

export default ResetPassword

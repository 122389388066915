import React, {useCallback, useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {TextInput, TextArea} from "../../field/text-inputs";
import {useTranslation} from "react-i18next";
import {FlexGrid} from "../../../grid";
import {random} from "../../../../helper";
import {Image} from "../../field/image";
import {BiCommentAdd} from "react-icons/bi";
import * as R from "ramda";
import Api from "../../../../service/api";
import {useToasts} from "react-toast-notifications";
import {FiEdit, FiRefreshCw, FiTrash2} from "react-icons/fi";
import {OverlayLoader} from "../../../loader";

export const Comment = ({
                            id,
                            onChange,
                            onRemove,
                            onRefresh,
                            avatar,
                            fio,
                            comment,
                            answer,
                            rating,
                            likes,
                             ...rest
                         }) => {
    const { t } = useTranslation();

    const onChangeField = useCallback((e)=>{
        typeof onChange === 'function' && onChange(id, e.currentTarget.name, e.currentTarget.value)
    }, [onChange]);
    const onChangeAvatar = useCallback((img)=>{
        typeof onChange === 'function' && onChange(id, 'avatar', img)
    }, [onChange]);

    rating = useMemo(()=>{
        return rating || random(4,5);
    }, [rating])
    likes = useMemo(()=>{
        return likes || random(50,200);
    }, [likes])

    useEffect(() => {
        if(typeof onChange === 'function'){
            onChange(id, 'rating', rating);
            onChange(id, 'likes', likes);
        }
    }, []);

    let color = 'blue';
    return (
        <div className={"relative"}>
            <FlexGrid
                className={'w-full hover:bg-grey-100 hover:dark:bg-grey-800 px-4 py-4 rounded'}
                columns={[{
                // className: `py-2 px-2`,
                content: <Image css={{w: 20, h: 20}}
                                name={'avatar'}
                                defaultValue={avatar}
                                onDrop={onChangeAvatar}
                                onRemove={()=>onChangeAvatar(null)}
                                className={"rounded-full"}
                                dropMessage={t(`form.field.comment.avatar_drop_message`)} />,
            },{
                className: `py-2 px-2`,
                flex: 1,
                content: <>
                    <TextInput
                        className={"w-full"}
                        name={"fio"}
                        defaultValue={fio}
                        label={t(`form.field.comment.fio`)}
                        errorMessage={''}
                        onChange={onChangeField} />
                    <FlexGrid columns={[{
                            className: ``,
                            flex: 1,
                            content: <>
                                <TextArea
                                    name={"comment"}
                                    defaultValue={comment}
                                    rows={4}
                                    label={t(`form.field.comment.comment`)}
                                    errorMessage={''}
                                    onChange={onChangeField} />
                                <TextInput
                                    name={"rating"}
                                    defaultValue={rating}
                                    label={t(`form.field.comment.rating`)}
                                    errorMessage={''}
                                    onChange={onChangeField} />
                                </>,
                        },{
                            className: `pl-2`,
                            flex: 1,
                            content: <>
                                <TextArea
                                    name={"answer"}
                                    defaultValue={answer}
                                    rows={4}
                                    label={t(`form.field.comment.answer`)}
                                    errorMessage={''}
                                    onChange={onChangeField} />
                                <TextInput
                                    name={"likes"}
                                    defaultValue={likes}
                                    label={t(`form.field.comment.likes`)}
                                    errorMessage={''}
                                    onChange={onChangeField} />
                            </>,
                        },]} />
                </>,
            }]}
            />
            <button onClick={()=>onRefresh(id)}
                    type={"button"}
                    className={`absolute right-0 top-0 mt-1 mr-12 btn btn-transparent btn-lg btn-rounded hover:btn-outlined bg-transparent border-${color}-500 hover:border-${color}-700  hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600`}>
                <FiRefreshCw />
            </button>
            <button onClick={()=>onRemove(id)}
                    type={"button"}
                    className={`absolute right-0 top-0 mt-1 mr-1 btn btn-transparent btn-lg btn-rounded hover:btn-outlined bg-transparent border-${color}-500 hover:border-${color}-700  hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600`}>
                <FiTrash2 />
            </button>
        </div>
    );
}


export const Comments = ({
                              className = '',
                              inputRef = false,
                              inline = false,
                              label = '',
                              labelBold = false,
                              name = '',
                              defaultValue = [],
                              lang,
                              category,
                              onChange,
                              ...rest
                          }) => {
    const { t } = useTranslation();
    const { addToast } = useToasts()
    const [isLoadingGeneration, setLoadingGeneration] = useState(false)
    const [commentsCount, setCommentsCount] = useState(5)
    const [comments, setComments] = useState(defaultValue || [])

    const onGenerateComments = useCallback(()=>{
        setLoadingGeneration(true);
        Api.commentsGenerate(category, lang, commentsCount)
            .then((newComments)=>{
                setLoadingGeneration(false);

                if(!newComments || newComments.length == 0){
                    addToast(t(`form.field.comment.generate_nodata`), {
                        appearance: 'info',
                        autoDismiss: true,
                    })
                    return;
                }
                newComments = newComments.map((comment, idx)=>{
                    comment.id = Date.now() + `_${idx}`;
                    comment.time = Date.now();
                    return comment;
                })

                setComments([...comments, ...newComments])

                addToast(t(`form.field.comment.generate_success`, {count: newComments.length}), {
                    appearance: 'success',
                    autoDismiss: true,
                })
            })
            .catch((error)=>{
                setLoadingGeneration(false);
                console.error('new commments', error);
                addToast(t(`form.field.comment.generate_error`), {
                    appearance: 'error',
                    autoDismiss: true,
                })
            })
    }, [category, lang, comments])
    const onAddComment = useCallback(()=>{
        setComments([...comments, {id: Date.now(), time: Date.now()}])
    }, [comments])
    const onRemoveComment = useCallback((commentId)=>{
        const idx = R.findIndex(R.propEq('id', commentId))(comments)
        if(idx < 0) return;
        comments.splice(idx, 1);
        let newComments = [...comments];
        setComments(newComments)
        typeof onChange === 'function' && onChange(newComments);
    }, [comments])
    const onRefreshComment = useCallback((commentId)=>{
        const idx = R.findIndex(R.propEq('id', commentId))(comments)
        if(idx < 0) return;

        setLoadingGeneration(true);
        Api.commentsGenerate(category, lang, 1)
            .then((newComments)=>{
                setLoadingGeneration(false);

                if(!newComments || newComments.length == 0){
                    addToast(t(`form.field.comment.generate_nodata`), {
                        appearance: 'info',
                        autoDismiss: true,
                    })
                    return;
                }


                newComments[0].id = comments[idx].id;
                newComments[0].time = Date.now();
                comments[idx] = newComments[0];

                setComments([...comments])

                addToast(t(`form.field.comment.generate_success`, {count: newComments.length}), {
                    appearance: 'success',
                    autoDismiss: true,
                })
            })
            .catch((error)=>{
                setLoadingGeneration(false);
                console.error('refresh commment', error);
                addToast(t(`form.field.comment.generate_error`), {
                    appearance: 'error',
                    autoDismiss: true,
                })
            })
    }, [lang, category, comments])

    const onCommentChange = useCallback((commentId, fieldName, value)=>{
        let commentIdx = R.findIndex(R.propEq('id', commentId))(comments)
        if(commentIdx < 0) return;
        comments[commentIdx][fieldName] = value;
        let newComments = [...comments];
        setComments(newComments);
        typeof onChange === 'function' && onChange(newComments);
    }, [comments])

    // useEffect(()=>{
    //
    // }, [comments])

    let color = 'blue';
    return (
        <OverlayLoader show={isLoadingGeneration}>
        <div className={`form-element ${inline ? 'form-element-inline' : ''} ${className}`}>
            <div className={`form-label ${labelBold? 'font-bold' : ''}`}>{label || t(`form.field.comment.title`)}</div>
                {comments && comments.map((comment, idx, allComments)=>{
                    return <Comment key={`${comment.id}_${comment.time}`} {...comment}
                                    onRefresh={onRefreshComment} onChange={onCommentChange} onRemove={onRemoveComment} />
                })}
                <button onClick={onGenerateComments} className={`btn btn-sm btn-rounded btn-icon bg-transparent hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600 `} type={"button"}>
                    <BiCommentAdd className="stroke-current mr-2" />
                    <span>{t(`form.field.comment.btn_generate`, {count: commentsCount, category})}</span>
                </button>
                <button onClick={onAddComment} className={`btn btn-sm btn-rounded btn-icon bg-transparent hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600 `} type={"button"}>
                    <BiCommentAdd className="stroke-current mr-2" />
                    <span>{t(`form.field.comment.btn_addcomment`)}</span>
                </button>
        </div>
        </OverlayLoader>
    );

    // return (
    //     <div className={type.toLocaleLowerCase() === 'hidden' ? 'hidden' : `form-element flex-auto ${inline ? 'form-element-inline' : ''} ${className}`}>
    //         {label && <div className="form-label">{label}</div>}
    //         <input
    //             {...rest}
    //             {...(inputRef ? {ref: inputRef} : {})}
    //             name={name}
    //             type={type}
    //             className={`form-input ${
    //                 // errorMessage ? 'border-red-500' : ''
    //                 errorMessage ? ' form-input-invalid' : ''
    //                 + validMessage ? ' form-input-valid' : ''
    //             }`}
    //             placeholder={placeholder}
    //         />
    //         {errorMessage && (
    //             <div className="form-error">{errorMessage}</div>
    //         )}
    //         {validMessage && (
    //             <div className="form-success">{validMessage}</div>
    //         )}
    //         {hint && (<div className="form-hint" dangerouslySetInnerHTML={{__html: hint}}/>)}
    //     </div>
    // )
}
Comments.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    hint: PropTypes.string,
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}

import React, {useCallback, useState} from 'react'
import {Modal} from './modal'
import {FiX} from "react-icons/fi";
import {Trans, useTranslation} from "react-i18next";
import {Loader} from "../loader";
import {Checkbox} from "../form/field/checkbox";
import Alert from "../alert";

export const ModalConfirm = ({
                                 title, icon,
                                 buttonTitle, buttonClassName,
                                 body,
                                 onCancel, onConfirm,
                                 isLoading = false,
                                 ...rest
                             }) => {
    return (
        <Modal {...rest} onClose={onCancel}>
            <div className="relative p-4 flex-auto">
                <div className="flex items-start justify-start p-2 space-x-4">
                    <div className="flex-shrink-0 w-12">{icon}</div>
                    <div className="flex flex-col w-full">
                        <div className="text-lg mb-2 font-bold">{title}</div>
                        {body}
                    </div>
                </div>
            </div>
            <div
                className="flex items-center justify-end p-4 border-t border-grey-200 dark:border-grey-700 border-solid rounded-b space-x-2">

                {isLoading ? <Loader show={true} className={"mb-4"} /> : <>
                    <button
                        className="btn btn-default btn-rounded bg-white hover:bg-grey-100 text-grey-900"
                        type="button"
                        onClick={onCancel}>
                        Cancel
                    </button>
                    <button
                        className={buttonClassName}
                        type="button"
                        onClick={onConfirm}>
                        {buttonTitle}
                    </button>
                </>}
            </div>
        </Modal>
    )
}


export const ModalConfirmDelete = ({title, itemIds, body, ...rest}) => {
    const {t} = useTranslation();

    return (
        <ModalConfirm
            icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiX size={18} className="stroke-current text-red-500"/>
                </span>
            }
            title={title || t('modal.confirmdelete.title')}
            body={
                body || <div className="text-sm text-grey-500">
                    <Trans
                        i18nKey={Array.isArray(itemIds) ?
                            "modal.confirmdelete.description_multi"
                            : "modal.confirmdelete.description"}
                        components={{ b: <strong className={`text-red-600`} /> }}
                        values={{count: Array.isArray(itemIds) ? itemIds.length : 0}}
                    />
                </div>
            }
            buttonTitle={t('modal.confirmdelete.button_confirm')}
            buttonClassName="btn btn-default btn-rounded bg-red-500 hover:bg-red-600 text-white"
            {...rest}
        />
    )
}

export const ModalConfirmDeleteDomains = ({onConfirm, itemIds, ...props}) => {
    const {t} = useTranslation();
    const [withApps, setWithApps] = useState(false)

    const _onConfirm = useCallback((e)=>{
        onConfirm && onConfirm(e, {withapps: withApps})
    }, [withApps, onConfirm])
    return (
        <ModalConfirmDelete {...props} itemIds={itemIds}
                            body={
                            <>
                                <div className="text-sm text-grey-500">
                                    <Trans
                                        i18nKey={Array.isArray(itemIds) ?
                                            "modal.confirmdelete.description_multi"
                                            : "modal.confirmdelete.description"}
                                        components={{ b: <strong className={`text-red-600`} /> }}
                                        values={{count: Array.isArray(itemIds) ? itemIds.length : 0}}
                                    />
                                </div>
                                <div className={`mt-4`}>
                                    <Checkbox label={t(`modal.confirmdeletedomains.remove_with_apps`)}
                                              name={`removeApps`}
                                              onChange={(e, isChecked) => setWithApps(isChecked)}  />
                                    {withApps && <Alert
                                        color="bg-transparent border-red-500 text-red-500"
                                        borderLeft
                                        raised>
                                        {t(`modal.confirmdeletedomains.all_apps_willbe_deleted`, )}
                                    </Alert>}
                                </div>
                            </>
                            }
                            onConfirm={_onConfirm} />
    )
}



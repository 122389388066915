import Immutable from 'seamless-immutable'
import Api from "../../service/api";
import {NavigationMenuAction} from "./navigation.menu";
import {store} from "../store";
import {asset, SettingsAction} from "./settings";

export const INITIAL_STATE = Immutable({
    isLoggedIn: false,
    name: '',
    email: '',
    avatar: '/images/avatar.jpg',
    invite_code: '',
    ref_id: '',
    permissions: [],
    roles: [],
});


export const UserAction = {
    signIn: ({email, password}) => (dispatch) => {
        return Api.getCSRFCookie().then(() => Api.signIn(email, password))
            .then(() => {
                return dispatch(UserAction.loadUserInfo());
            });
    },
    loadUserInfo: () => (dispatch) => {
        return Api.getUserInfo()
            .then((userInfo) => Promise.all([
                dispatch(UserAction.loadUserInfoCompleted(userInfo)),
                dispatch(SettingsAction.loadSettings()),
                dispatch(NavigationMenuAction.updateMenuByUser(userInfo.permissions))
            ]));
    },
    loadUserInfoCompleted: (userInfo) => ({
        type: 'USER_INFO_LOADED',
        userInfo: userInfo,
    }),
    updateUserProfile: (userData) => (dispatch) => {
        return Api.profileUpdate(userData)
            .then((updateResult) => {
                if(!updateResult.status) return Promise.reject();
                return dispatch(UserAction.loadUserInfoCompleted(updateResult.user));
            });
    },
    logout: () => (dispatch) => {
        return Api.logout().then(
            () => dispatch(UserAction.logoutClientOnly()),
        );
    },
    logoutClientOnly: () => ({
        type: 'USER_LOGOUT',
    }),
}

function userInfoLoaded(state, action) {
    state = state.merge([action.userInfo, {isLoggedIn: true}]);
    console.log(state);
    return state;
}

function logout(state, action) {
    return INITIAL_STATE;
}

export default function user(
    state = INITIAL_STATE,
    action
) {
    switch (action.type) {
        case 'USER_INFO_LOADED':
            return userInfoLoaded(state, action);
        case 'USER_LOGOUT':
            return logout(state, action);
        default:
            return state;
    }
}


export function hasPermission(userState, permission){
    if(typeof permission !== 'object') permission = [permission];
    for(let i=0;i<permission.length;i++){
        if(userState.permissions.indexOf(permission[i]) >= 0) return true;
    }
    return false;
}

export function getAvatar(userState){
    return asset(userState.avatar || store.getState().settings.default_avatar);
}

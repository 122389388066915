import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import AuthLayout from './layout'
import ForgotPasswordForm from '../../components/form/auth/forgot.password'
import {useTranslation} from 'react-i18next';

const ForgotPassword = () => {
    const {t} = useTranslation();
    const [isComplete, setComplete] = useState(false)

    return (
        <AuthLayout imgUrl="/pages/auth/illustration.svg"
                    title={t('auth.forgot_password.title')}
                    description={t('auth.forgot_password.description')}>
            {!isComplete ?
                <ForgotPasswordForm onComplete={() => setComplete(true)}/>
                : (
                    <div className="w-full mb-6">
                        <div className="text-green-700 font-black ">
                            <h6>{t('auth.forgot_password.completed')}</h6>
                        </div>
                        <p>{t('auth.forgot_password.wait_email')}</p>
                    </div>
                )}


            <div className="flex flex-row w-full mt-6">
                <span className="text-secondary mr-1">{t('auth.newuser')}</span>
                <span>
              <Link className="link" to="/auth/signup">
                {t('auth.create_account')}
              </Link>
            </span>
            </div>
            <div className="flex flex-row w-full">
                <span>
              <Link className="link" to="/auth/signin">
                {t('auth.forgot_password.goback_to_login')}
              </Link>
            </span>
            </div>
        </AuthLayout>
    )
}

export default ForgotPassword

import React, {useCallback, useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import RSelect, {components} from 'react-select'
import RCreatableSelect from 'react-select/creatable';
import RAsyncSelect from 'react-select/async'
import RAsyncCreatableSelect from 'react-select/async-creatable';
import Api from "../../../service/api";
import * as R from "ramda";
import {APP, POSTBACK_EVENT, VISIT_TYPE} from "../../../config/resources";
import i18n from "./../../../i18n/config";
import {FaAppStoreIos, FaGooglePlay, FaTheaterMasks} from "react-icons/fa";
import {IoLogoPwa} from "react-icons/io5";
import {appTypeIsActive} from "../../../redux/reducers/settings";
import {useAppSelector} from "../../../redux/store";
import {hasPermission} from "../../../redux/reducers/user";

export const CATEGORY = {
    DOMAIN_REGISTRAR: 'domain_registrar',
    SMS: 'sms',
    PUSH: 'push',
}
const TYPE = {
    NAMECHEAP: 'namecheap',
    CLOUDFLARE: 'cloudflare',
    ONESIGNAL: 'onesignal',
    SMS_ACTIVATE: 'sms_activate',
}

export const RSLabelDomain = (props) => {
    const {settings} = useAppSelector();
    return (
        <div className={"flex justify-between"}>
            {props.domain}
            <small>
                {appTypeIsActive(settings, APP.TYPE.CLOAK) && <><FaTheaterMasks size={14} className={"text-blue-500 inline-block ml-2"} /> {props.apps_cloak_count}</>}
                {appTypeIsActive(settings, APP.TYPE.PWA) && <><IoLogoPwa size={20} className={"text-blue-500 inline-block ml-2"} /> {props.apps_pwa_count}</>}
                {appTypeIsActive(settings, APP.TYPE.ANDROID) && <><FaGooglePlay size={12} className={"text-blue-500 inline-block ml-2"} /> {props.apps_android_count}</>}
                {appTypeIsActive(settings, APP.TYPE.IOS) && <><FaAppStoreIos size={12} className={"text-blue-500 inline-block ml-2"} /> {props.apps_ios_count}</>}
            </small>

        </div>
    )
};

export const LoadOptionsDataProvider = {
    extServiceTypes: (inputValue, callback)=>{
        return Api.getExternalServicesTypes()
            .then((data) => {
                return data.map((item, idx, allItems)=>{
                    return {value: item.id, label: item.name};
                })
            })
    },
    adPlatforms: (inputValue, callback)=>{
        return Api.getAdPlatforms()
            .then((data) => {
                return data.map((item, idx, allItems)=>{
                    return {value: item.id, label: item.name};
                })
            })
    },
    users: (filter, elem)=>(inputValue, callback)=>{
        return Api.getResourceList('user',
                1,
                0, undefined,
                {...filter, text: inputValue})
            .then((data) => {
                return data.data.map((item, idx, allItems)=>{
                    return {...item, value: item.id, label: `${item.name} (id:${item.id})`};
                })
            });
    },
    roles: (filter, elem)=>(inputValue, callback)=>{
        return Api.getResourceList('role',
            1,
            0, undefined,
            {...filter, role: inputValue})
            .then((data) => {
                return data.data.map((item, idx, allItems)=>{
                    return {...item, value: item.id, label: `${item.name} (id:${item.id})`};
                })
            });
    },
    subscriptions: (filter, elem)=>(inputValue, callback)=>{
        return Api.getResourceList('subscription',
            1,
            0, undefined,
            {...filter, text: inputValue})
            .then((data) => {
                return [
                    {value: 0, label: 'Subscription not set'},
                    ...data.data.map((item, idx, allItems)=>{
                        return {...item, value: item.id, label: `${item.title} (id:${item.id})`};
                    })
                ]
            });
    },
    appVisitPlatforms: (filter, elem)=>(inputValue, callback)=>{
        return Api.getStatisticsAppVisitPlatforms(filter)
            .then((data) => {
                return data.map((item, idx, allItems)=>{
                    return {value: item, label: item};
                })
            });
    },
    appQueryParams: (filter, elem)=>(inputValue, callback)=>{
        return Api.getStatisticsAppQueryParams(filter)
            .then((data) => {
                return data.map((item, idx, allItems)=>{
                    return {value: item, label: elem.t ? elem.t(`datatable.column.${item}`, item) : item};
                })
            });
    },
    visitTypes: (elemSettings)=>(inputValue, callback)=>{
        let visitTypes = [VISIT_TYPE.WHITEPAGE, VISIT_TYPE.LANDING, VISIT_TYPE.APP]
        if(elemSettings.user && hasPermission(elemSettings.user, 'statistics admin')) visitTypes.push(VISIT_TYPE.BROWSER_ID);
        return Promise.resolve(visitTypes.map((visitType, idx)=>{
            return {value: visitType, label: i18n.t(`list.visit_type.${visitType}`)}
        }))
    },

    postbackEvents: (inputValue, callback)=>{
        return Promise.resolve([
            {value: POSTBACK_EVENT.INSTALL, label: POSTBACK_EVENT.INSTALL},
            {value: POSTBACK_EVENT.REG, label: POSTBACK_EVENT.REG},
            {value: POSTBACK_EVENT.FIRST_DEPOSIT, label: POSTBACK_EVENT.FIRST_DEPOSIT},
            {value: POSTBACK_EVENT.DEPOSIT, label: POSTBACK_EVENT.DEPOSIT},
            {value: POSTBACK_EVENT.SUBSCRIBE, label: POSTBACK_EVENT.SUBSCRIBE},
            // {value: POSTBACK_EVENT.LOG, label: POSTBACK_EVENT.INSTALL},
        ])
    },

    countries: (inputValue, callback)=>{
        inputValue = inputValue.toLowerCase();
        return Api.getCountries()
            .then((data) => {
                return Object.keys(data).reduce((result, item, idx, allItems)=>{
                    if(!inputValue || data[item].toLowerCase().includes(inputValue.toLowerCase()) || item.includes(inputValue)){
                        result.push({value: item, label: `${data[item]} (${item.toUpperCase()})`});
                    }

                    return result;
                }, [])
            })
    },
    locales: (inputValue, callback)=>{
        return Api.getLocales()
            .then((data) => {
                return Object.keys(data).reduce((result, item, idx, allItems)=>{
                    if(!inputValue || data[item].name.toLowerCase().includes(inputValue.toLowerCase()) || item.includes(inputValue)){
                        result.push({value: item, label: `${data[item].name} (${item})`});
                    }

                    return result;
                }, [])
            })
    },
    localeswithcountries: (inputValue, callback)=>{
        return Api.getLocaleswithcountries()
            .then((data) => {
                let lowerInputValue = inputValue.toLowerCase();
                return Object.keys(data).reduce((result, item, idx, allItems)=>{
                    if(!inputValue || data[item].toLowerCase().includes(lowerInputValue) || item.toLowerCase().includes(lowerInputValue)){
                        result.push({value: item, label: `${data[item]} (${item})`});
                    }

                    return result;
                }, [])
            })
    },
    urlMakerTypes: (inputValue, callback)=>{
        return Api.getUrlMakerTypes()
            .then((data) => {
                return Object.keys(data).reduce((result, item, idx, allItems)=>{
                    if(!inputValue || data[item].toLowerCase().includes(inputValue)){
                        result.push({value: item, label: `${data[item]}`});
                    }

                    return result;
                }, [])
            })
    },
    extServices: (filter)=>(inputValue, callback)=>{
        return Api.getResourceList('external_service',
                                1,
                                0, undefined,
                                // {category, service})
                                filter)
            .then((data) => {
                let values = data.data.map((item, idx, allItems)=>{
                    return {value: item.id, label: item.title};
                })
                return values;
            })
    },
    teamsAvailable: (inputValue, callback)=>{
        return Api.getResourceList('team', undefined,
                undefined, undefined, {text: inputValue}, true)
            .then((data) => {
                return data.data.map((item, idx, allItems)=>{
                    return {value: item.id, label: item.title};
                })
            })
    },
    domains: (filter, sortBy = undefined)=>(inputValue, callback)=>{
       return Api.getResourceList('domain',
                1,
                0, sortBy,
                {...filter, text: inputValue}, true)
                .then((data) => {
                    let values = data.data.map((item, idx, allItems)=>{
                        return {...item, value: item.id, label: <RSLabelDomain {...item} />};
                    })
                    return values;
                })
    },
    adcampaigns: (filter)=>(inputValue, callback)=>{
        return Api.getResourceList('adcampaign',
            1,
            0, undefined,
            {...filter, text: inputValue})
            .then((data) => {
                let values = data.data.map((item, idx, allItems)=>{
                    return {...item, value: item.id, label: item.title};
                })
                return values;
            })
    },
    apps: (filter)=>(inputValue, callback)=>{
        return Api.getResourceList('app',
            1,
            0, undefined,
            {...filter, text: inputValue})
            .then((data) => {
                let values = data.data.map((item, idx, allItems)=>{
                    return {...item, value: item.id, label: item.title};
                })
                return values;
            })
    },
    resourceList: (resource, filter, elem)=>(inputValue, callback)=>{
        return Api.getResourceList(resource,
            1,
            0, undefined,
            {...filter, text: inputValue})
            .then((data) => {
                let values = data.data.map((item, idx, allItems)=>{
                    return {...item, value: item.id, label: `${item.title} (id:${item.id})`};
                })
                return values;
            })
    },
    landings: (inputValue, callback)=>{
        return Api.getLandings()
            .then((data) => {
                return data.map((item, idx, allItems)=>{
                    return {value: item.id, label: item.title, ...item};
                })
            })
    },
}

// const ReactSelectInput = ({ children, ...props }) => {
//     const { inputRef, inputName, value } = props.selectProps;
//     return (
//         <div>
//             <components.Input {...props}  >
//                 {children}
//             </components.Input>
//             <input type="hidden" ref={inputRef} name={inputName} defaultValue={value?.value}  />
//         </div>
//     );
// };

const orderOptions = values => {
    if(!values || !values.filter) return values;
    return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed));
};

const createOption = label => ({
    label,
    value: label,
});

export const ReactSelect = ({
                            className = '',
                           inputRef = false,
                           inline = false,
                           label = '',
                           name = 'name',
                           options = [],
                           hint = '',
                           errorMessage = '',
                           validMessage = '',
                           loadOptions,
                           defaultValue,
                           isClearable,
                           isCreatable = false,
                           isSearchable = false,
                           hideDropDown = false,
                           ...rest
                       }) => {

    const [value, setValue] = useState(orderOptions(defaultValue))
    const [inputValue, setInputValue] = useState('')
    let SelectType = loadOptions ? (isCreatable ? RAsyncCreatableSelect : RAsyncSelect) :  (isCreatable ? RCreatableSelect : RSelect);
    if(loadOptions && typeof loadOptions == 'string'){
        switch(loadOptions.toLowerCase()){
            case 'resources': loadOptions = LoadOptionsDataProvider.resourceList(rest.resource, rest.filter, rest)
                break;
            case 'users': loadOptions = LoadOptionsDataProvider.users(rest.filter, rest)
                break;
            case 'roles': loadOptions = LoadOptionsDataProvider.roles(rest.filter, rest)
                break;
            case 'subscriptions': loadOptions = LoadOptionsDataProvider.subscriptions(rest.filter, rest)
                break;
            case 'landings': loadOptions = LoadOptionsDataProvider.landings
                break;
            case 'visittypes': loadOptions = LoadOptionsDataProvider.visitTypes(rest)
                break;
            case 'postbackevents': loadOptions = LoadOptionsDataProvider.postbackEvents
                break;
            case 'appvisitplatforms': loadOptions = LoadOptionsDataProvider.appVisitPlatforms(rest.filter, rest)
                break;
            case 'appqueryparams': loadOptions = LoadOptionsDataProvider.appQueryParams(rest.filter, rest)
                break;
            case 'adplatforms': loadOptions = LoadOptionsDataProvider.adPlatforms
                break;
            case 'countries': loadOptions = LoadOptionsDataProvider.countries
                break;
            case 'locales': loadOptions = LoadOptionsDataProvider.locales
                break;
            case 'localeswithcountries': loadOptions = LoadOptionsDataProvider.localeswithcountries
                break;
            case 'urlmakertypes': loadOptions = LoadOptionsDataProvider.urlMakerTypes
                break;
            case 'extservicetypes': loadOptions = LoadOptionsDataProvider.extServiceTypes
                break;
            case 'extservices': loadOptions = LoadOptionsDataProvider.extServices(rest.filter)
                break;
            case 'domains': loadOptions = LoadOptionsDataProvider.domains(rest.filter, rest.sortBy)
                break;
            case 'adcampaigns': loadOptions = LoadOptionsDataProvider.adcampaigns(rest.filter)
                break;
            case 'apps': loadOptions = LoadOptionsDataProvider.apps(rest.filter)
                break;
            case 'teamsavailable': loadOptions = LoadOptionsDataProvider.teamsAvailable
                break;
            default: throw new Error(`ReactSelect.loadOptions data provider [${loadOptions}] not found`);
                break;
        }
    }

    const onKeyDown = useCallback((event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                let newValue = [...(value||[]), createOption(inputValue)];
                setValue(newValue);
                setInputValue('');
                if(rest.onChange && typeof rest.onChange === 'function') rest.onChange(newValue);
                event.preventDefault();
                event.stopPropagation();
        }
    }, [value, inputValue]);

    // useEffect(() => {
    //     if(rest.onChange && typeof rest.onChange === 'function') rest.onChange(defaultValue);
    // }, []);
    useEffect(() => {
        if(rest.isMulti && !defaultValue){
            defaultValue = [];
        }else if(typeof defaultValue === 'string') {
            let optionIdx = R.findIndex(R.propEq('value', defaultValue))(options)
            defaultValue = optionIdx > -1 ? options[optionIdx] : { value: defaultValue, label: defaultValue};
        }
        // console.log('defaultValue', defaultValue, options)
        if( ( /*!value &&*/ value !== defaultValue) /*|| defaultValue === null*/){
            setValue(defaultValue)
        }
        // if( ( !value || !defaultValue || value.value !== defaultValue.value) /*|| defaultValue === null*/) setValue(defaultValue)
    }, [JSON.stringify(defaultValue), JSON.stringify(options)]);


    const onChange = useCallback((_value, { action, removedValue }) => {
        if(rest.isMulti){
            switch (action) {
                case 'remove-value':
                case 'pop-value':
                    if (removedValue?.isFixed) {
                        return;
                    }
                    break;
                case 'clear':
                    _value = defaultValue ? defaultValue.filter(v => v?.isFixed) : [];
                    break;
            }
        }

        setValue(orderOptions(_value));
        if(rest.onChange && typeof rest.onChange === 'function') rest.onChange(_value);
    }, [rest.isMulti, defaultValue , rest.onChange]);

    if(rest.isMulti){
        try{
            isClearable = isClearable || typeof(isClearable) === 'undefined' ? (value && value.some ? value.some(v => !v.isFixed) : false )  : false
        }catch(e){
            console.error(e);
            isClearable = false;
        }
    }
    const components = useMemo(()=>{
        if(!hideDropDown) return {};

        return { DropdownIndicator: null,}
    }, [hideDropDown]);

    // console.log('defaultValue', defaultValue, name, value, orderOptions(defaultValue));
    return (
        <div className={`form-element ${inline ? 'form-element-inline' : ''} ${className}`} >
            {label && <div className="form-label">{label}</div>}
            {/*{rest.defaultValue ?*/}
            <SelectType
                key={`${name}-${JSON.stringify(value)}-${JSON.stringify(rest.filter)}-${JSON.stringify(rest.sortBy)}`}
                className={rest.classNameSelect || 'w-full'}
                cacheOptions
                defaultOptions
                components={components}
                {...rest}
                loadOptions={loadOptions}
                options={options}
                value={value}
                inputValue={inputValue}
                isClearable={isClearable}
                isSearchable={isSearchable}
                menuIsOpen={hideDropDown ? false : undefined}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onInputChange={setInputValue}
                // components={{ Input: ReactSelectInput }}
                inputRef={inputRef}
                styles={{
                    control: base => ({
                        ...base,

                        WebkitAppearance: 'none',
                        appearance: 'none',
                        backgroundColor: '#fff',
                        borderColor: `${
                                errorMessage ? '#f44336' : (
                                    validMessage ? '#4caf50' : '#e2e8f0'
                                )
                        }`,
                        borderWidth: '1px',
                        borderRadius: '0.25rem',
                    }),
                    multiValue: (base, state) => {
                        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
                    },
                    multiValueLabel: (base, state) => {
                        return state.data.isFixed
                            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                            : base;
                    },
                    multiValueRemove: (base, state) => {
                        return state.data.isFixed ? { ...base, display: 'none' } : base;
                    },
                }}
                name={name}
                inputName={name}
            />
            {/*: null}*/}
            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (<div className="form-hint">{hint}</div>)}
        </div>
    )
}
ReactSelect.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string
        })
    ),
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    hideDropDown: PropTypes.bool,
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}

export default function uiConfig(
    state = {
        layout: 'main',
        collapsed: false,
        rightSidebar: false,
        backdrop: false,
        showLogo: true,
        backgroundTheme: 'light',
        leftSidebarTheme: 'dark',
        navbarTheme: 'light',
        rightSidebarTheme: 'light'
    },
    action
) {
    switch (action.type) {
        case 'SET_CONFIG':
            return {
                ...state,
                ...action.config
            }
        case 'SET_CONFIG_KEY':
            let {key, value} = {...action}
            return {
                ...state,
                [`${key}`]: value
            }
        default:
            return state
    }
}

import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react'
import {usePopper} from 'react-popper'
import AccountLinks from './account-links'
import {useAppSelector} from "../../redux/store";
import {getAvatar} from "../../redux/reducers/user";

const Dropdown = () => {
  const {user, settings} = useAppSelector()
  const [hidden, setHidden] = useState(true)

  const avatar = useMemo(() => {
    return getAvatar(user);
  }, [user]);

  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)

  const {styles, attributes} = usePopper(
    buttonRef.current,
    dropdownRef.current,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [100, 0]
            // offset: ({ placement, reference, popper }) => {
            //   return [popper.width, 0];
            // },
          }
        }
      ]
    }
  )

  const handleClickOutside = useCallback((event) => {
    if(buttonRef.current.contains(event.target)) return false;

    if(!hidden &&
        ((!buttonRef.current.contains(event.target) && !dropdownRef.current.contains(event.target))
        || dropdownRef.current.contains(event.target))
      ){
      setTimeout(()=>setHidden(true), 300);
    }
  }, [hidden, dropdownRef, buttonRef])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const handleDropdownClick = () => {
    setHidden(!hidden)
  }

  let popper = useMemo(()=>{
    if(!styles.popper) return styles.popper;
    return {
      ...styles.popper,
      transform: '',
      top: '60px',
      right: 0,
      left: 'auto'
    }

    return styles.popper;
  }, [styles.popper])

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={handleDropdownClick}
        className="flex h-16 w-auto rounded-full ml-2 relative">
        {/*<span className={`absolute top-0 left-0 pt-4 flex  flex-row gap-2`}>*/}
        <span className={`pt-4 flex  flex-row gap-2`}>
          <img
            className="h-8 w-8 rounded-full shadow"
            src={avatar}
            alt="avatar"
          />
          {/*<span*/}
          {/*  className="absolute uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full bg-red-500 text-white shadow-outline-white"*/}
          {/*  style={{top: 10, right: -4}}>*/}
          {/*  2*/}
          {/*</span>*/}
          <div className={`flex flex-col justify-center text-left w-16`}>
            <span className={`truncate`}>{user.name}</span>
             {settings.monetization_active && <small>${user.balance}</small>}
          </div>
        </span>
      </button>
      <div ref={dropdownRef} style={popper} {...attributes.popper}>
        <div
          style={styles.offset}
          className={`dropdown ${hidden ? '' : 'open'}`}>
          <div className="dropdown-content w-48 bottom-end" >
            <AccountLinks/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dropdown

import React, {Suspense} from 'react'
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from "react-redux";
import Index from './pages/index'
// import ErrorPage from './pages/error-page'
import SignIn from './pages/auth/signin'
import SignUp from './pages/auth/signup'
import ForgotPassword from './pages/auth/forgot.password'
import {EmailVerifyError, EmailVerifyCompleted} from './pages/auth/email.verify'
import ResetPassword from './pages/auth/reset.password'
import ErrorPage from "./pages/error-page";

// import Users from './pages/users'
// const Users = React.lazy(() => import('./pages/users'))

export function PrivateRoute({component: Component, componentProps, children, permission, ...rest}) {
    let location = useLocation()
    const {isLoggedIn, permissions} = useSelector(
        state => ({
            isLoggedIn: state.user.isLoggedIn,
            permissions: state.user.permissions,
        }),
        shallowEqual
    )

    if(permission && permissions.indexOf(permission) < 0){
        return <Redirect
            to={{
                pathname: "/error/nopermission",
                state: {from: location}
            }}
        />
    }

    if(!isLoggedIn){
        return <Redirect
            to={{
                pathname: "/auth/signin",
                state: {from: location}
            }}
        />
    }

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Route {...rest} render={(props) => <Component {...props} title={`Props through render`} {...componentProps} />} />
        </Suspense>
    );
}

const Routes = () => {
    const {navigationMenu} = useSelector(
        state => ({
            navigationMenu: state.navigationMenu
        }),
        shallowEqual
    )

    return (
        <Switch>
            {/*<Route exact path="/" component={Index} />*/}
            <Route exact path="/">
                <Redirect to="/dashboard" />
            </Route>

            <Route path="/auth/signin" component={SignIn}/>
            <Route path="/auth/signup" component={SignUp}/>
            <Route path="/auth/email/verify/error" component={EmailVerifyError}/>
            <Route path="/auth/email/verify/completed" component={EmailVerifyCompleted}/>
            <Route path="/auth/forgot-password" component={ForgotPassword}/>
            <Route path="/auth/reset-password/:token" component={ResetPassword}/>

            {
                navigationMenu.reduce((menu, currentValue) => {
                    currentValue.items.map((subMenu) => {
                        return menu.push(<PrivateRoute key={subMenu.url} path={subMenu.url}
                                                permission={subMenu.permission}
                                                component={React.lazy(() => import(`./pages${subMenu.url}/`) )} />);
                    });

                    return menu;
                }, [])
            }

            <PrivateRoute component={ErrorPage}/>
        </Switch>
    )
}
export default Routes

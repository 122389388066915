import React from 'react'
import PropTypes from 'prop-types'

export const Select = ({
                           inputRef = false,
                           inline = false,
                           label = 'Label',
                           name = 'name',
                           options = [],
                           defaultValue = '',
                           hint = '',
                           errorMessage = '',
                           validMessage = '',
                           onChange,
                           ...rest
                       }) => {
    return (
        <div className={`form-element ${inline ? 'form-element-inline' : ''}`} >
            <div className="form-label">{label}</div>
            <select
                // key={`${name}-${defaultValue}`}
                //{...rest}

                {...(inputRef ? {ref: inputRef} : {})}
                className={`form-select ${
                    // errorMessage ? 'border-red-500' : ''
                    errorMessage ? ' form-select-invalid' : ''
                    + validMessage ? ' form-select-valid' : ''
                }`}
                // defaultValue={defaultValue}
                value={defaultValue}
                onChange={onChange}
                name={name}>
                {options.map((option, i) => (
                    //{...(defaultValue === option.value ? {selected: 'selected'}: {})}
                    <option key={i+'_'+option.value} value={option.value}  >{option.label}</option>
                ))}
            </select>
            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (<div className="form-hint">{hint}</div>)}
        </div>
    )
}
Select.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string
        })
    ),
    hint: PropTypes.string,
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}

import React, {StrictMode} from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import { CookiesProvider } from 'react-cookie';
import {store} from './redux/store'
import App from './app'
import './i18n/config'
import * as serviceWorker from './serviceWorker'

import './service/reactotron.config'

ReactDOM.render(
    // <StrictMode>
        <CookiesProvider>
            <Provider store={store}>
              <App />
            </Provider>
        </CookiesProvider>
    // </StrictMode>
    ,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import {shallowEqual, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export default ({}) => {
    const history = useHistory();
    const {isLoggedIn} = useSelector(
        state => ({
            isLoggedIn: state.user.isLoggedIn
        }),
        shallowEqual
    )
    if(isLoggedIn){
        setTimeout(()=>history.push("/dashboard"), 1);
    }

    return null;
}

import React from 'react'
import Widget from "./index";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../redux/store";
import {FiPackage} from "react-icons/fi";
import {LinkButton} from "../button";
import {TeamSubscription} from "../subscription";

function TeamsEmptyList({}){
    const {t} = useTranslation();
    return (
        <div className={`flex flex-col justify-center items-center w-full`}>
            <div className={`p-4 text-base place-self-center text-center items-center justify-center`}>
                {t(`widget.teams.create_first_team`)}
            </div>
            <LinkButton to={`/teams/add`} title={t(`widget.teams.btn_create_team`)}
                        icon={<FiPackage className="stroke-current mr-2"/>}
                        className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded btn-icon mb-4" />
        </div>
    )
}

function TeamsItem({id, title, used_services, subscription, ...rest}){
    const {t} = useTranslation();
    return (
        <div className={`flex flex-col flex-1`}>
            <div className={`pb-1 text-base font-bold  flex flex-row items-center`}>
                <FiPackage className="stroke-current mr-2"/> <span className={``}>{title}</span>
            </div>
            <TeamSubscription teamId={id} {...subscription} usedServices={used_services} onSubscriptionUpdate={()=>{}} />
        </div>
    )
}

function TeamsList({data, total}){
    const {t} = useTranslation();

    return (
        <>
            {/*<div className="form-label">Last 5 partners</div>*/}
            {!data || data.length <= 0 ?
                <TeamsEmptyList />
                : data.map((team, idx)=>{
                        return <TeamsItem key={team.id} {...team} />
                    })}

            {/*<div className={`align-self-end font-bold`}>Total: {total}</div>*/}
        </>
    )
}

export const WidgetTeamsInfo = ({data, total, className = ''}) => {
    const {t} = useTranslation();
    const {user} = useAppSelector()

    return (
        <Widget title={t(`widget.teams.title`)} description={t(`widget.teams.description`)} className={className}>
            <div className={`mt-4 flex flex-col gap-8`}>

                <TeamsList data={data} />

            </div>
        </Widget>
    )
}


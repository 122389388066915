import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {GroupBox} from "./groupbox";

export const Radiobox = ({
                                className = '',
                                inputRef = false,
                                inline = false,
                                label = 'Label',
                                name = '',
                                value,
                                defaultValue = false,
                                hint = '',
                                errorMessage = '',
                                validMessage = '',
                                ...rest
                            }) => {

    return (
        <div className={`form-element ${inline ? 'form-element-inline' : ''} ${className}`}>
            <div className="flex items-center justify-start space-x-2">
                <input
                    {...rest}
                    {...(inputRef ? {ref: inputRef} : {})}
                    name={name}
                    type="radio"
                    defaultValue={value}
                    defaultChecked={defaultValue === value}
                    // checked={defaultValue === value}
                    id={`radiobox_${name}_${value}`}
                    className={`form-radio text-blue-500 h-4 w-4 ${
                        // errorMessage ? 'border-red-500' : ''
                        errorMessage ? ' form-radio-invalid text-red-500 ' : ''
                        + validMessage ? ' form-radio-valid text-green-500' : ''
                    }`}
                />
                <label htmlFor={`radiobox_${name}_${value}`} className={` ${
                    rest.disabled ? ` text-grey-400 ` : ''
                    + errorMessage ? ' text-red-500 ' : ''
                    + validMessage ? ' text-green-500' : ''
                }`}>{label}</label>
            </div>
            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (<div className="form-hint ml-6">{hint}</div>)}
        </div>
    )
}
Radiobox.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    defaultValue: PropTypes.any,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}

export const RadioboxCards = ({
                             className = '',
                             inputRef = false,
                             inline = false,
                             label = '',
                             name = '',
                             options = [],
                             defaultValue = false,
                             hint = '',
                             errorMessage = '',
                             validMessage = '',
                             hideDescription = false,
                             disabled = false,
                             onChange = ()=>{},
                             ...rest
                         }) => {
    const [value, setValue] = useState(defaultValue)
    useEffect(()=>{
        setValue(defaultValue);
    }, [defaultValue])
    let onSelect = (val)=>{
        onChange(val);
        setValue(val);
    }
    return (
        <>
            <GroupBox label={label} className={className} flex={{direction: 'col'}}>
                <div className={"w-full flex flex-row items-start justify-start space-x-2"}>
                    {options.map((option, idx)=>{
                        let isActive = value === option.value;
                        let isDisabled = disabled || option.disabled;
                        return  (
                            <div key={idx}
                                 onClick={()=>!isDisabled && onSelect(option.value)}
                                 className={`${isActive ? 'border border-blue-500' : (!isDisabled ? 'hover:bg-blue-50 cursor-pointer' : 'bg-grey-100')} rounded-lg   flex-1 flex flex-col items-center justify-start space-y-2 p-8 text-center`}>
                                <div className={`flex items-center justify-center rounded-md ${!isDisabled ? 'text-blue-500' : ''}`}>
                                    {option.icon}
                                </div>
                                <div className="text-lg leading-6">{option.title}</div>
                                {!hideDescription && <div className="text-base text-grey-500 leading-6">{option.description}</div>}
                            </div>
                        )
                    })}
                </div>

                {errorMessage && (
                    <div className="form-error">{errorMessage}</div>
                )}
                {validMessage && (
                    <div className="form-success">{validMessage}</div>
                )}
                {hint && (<div className="form-hint ml-6">{hint}</div>)}

            </GroupBox>
        </>
    )
}
RadioboxCards.propTypes = {
    className: PropTypes.string,
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.element,
        title: PropTypes.string,
        description: PropTypes.string,
        value: PropTypes.string,
    })),
    defaultValue: PropTypes.any,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string,
    hideDescription: PropTypes.bool,
    disabled: PropTypes.bool,
}


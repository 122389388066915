import Immutable from 'seamless-immutable'
import Api from "../../service/api";
import {store} from "../store";

export const INITIAL_STATE = Immutable({
    default_lang: 'en',
    default_avatar: '/images/avatar.jpg',
    asset_url: '/',
    project_name: '',
    project_logo: '',
    monetization_active: false,
    referral_program_active: false,
    app_types_active: [],
});


export const SettingsAction = {
    loadSettings: () => (dispatch) => {
        return Api.getSettings()
            .then((settings) => dispatch(SettingsAction.loadCompleted(settings)));
    },
    loadCompleted: (settings) => ({
        type: 'SETTINGS_LOADED',
        settings: settings,
    }),
}

function settingsLoaded(state, action) {
    state = state.merge(action.settings);
    return state;
}


export default function settings(
    state = INITIAL_STATE,
    action
) {
    switch (action.type) {
        case 'SETTINGS_LOADED':
            return settingsLoaded(state, action);
        default:
            return state;
    }
}


export function asset(assetPath, settingsState, tm = Date.now()){
    assetPath = assetPath || '';
    if(assetPath.startsWith('blob')) return assetPath;

    if(tm) assetPath = assetPath + (assetPath.indexOf('?') >= 0 ? '&' : '?') + 'tm='+tm;
    if(assetPath.startsWith('http') || assetPath.startsWith('/')) return assetPath;

    return (settingsState || store.getState().settings).asset_url + assetPath;
}

export function appTypeIsActive(settingsState, appType){
    if(!settingsState?.app_types_active || !settingsState?.app_types_active.includes(appType)) return false;
    return true;
}


import React, {useState, useEffect, useRef} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import Portal from '../portal'
import {toRGB} from '../../helper/colors'

function updateBodyFixed(isOpen){
    let body = document.querySelector('body');
    if(isOpen) {
        body.style.top = `-${window.scrollY}px`;
        body.classList.add('fixed')
    } else {
        body.classList.remove('fixed');
        window.scrollTo(0, parseInt(body.style.top || '0') * -1);
        body.style.top = ``;
    }
}

export const Modal = ({
                 className = '',
                 isOpen,
                 isModal = false,
                 children,
                 onClose,
                 maxWidth = 'max-w-xs sm:max-w-sm lg:max-w-lg'
               }) => {
  const {backgroundTheme} = useSelector(
    (state) => ({
      backgroundTheme: state.uiConfig.backgroundTheme
    }),
    shallowEqual
  )

  const modalRef = useRef(null)

  useEffect(() => {
      if(isModal) return;
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false
      if (!isOpen || modalRef.current.contains(event.target)) {
        return false
      }

      onClose && onClose(!isOpen)
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, modalRef])

  useEffect(()=>{
      updateBodyFixed(isOpen);
      return ()=>{
          updateBodyFixed(false);
      }
  }, [isOpen])

  if(!isOpen) return null;

  return (
      <Portal selector="#portal">
          <div className="modal-backdrop fade-in"></div>
          <div
            className={`modal show ${backgroundTheme === 'dark' ? 'dark-mode' : ''} `}
            style={backgroundTheme === 'dark' ? {} : {backgroundColor:'#00000050'} }
            data-background={backgroundTheme}>
            <div
              className={`relative w-auto lg:my-4 mx-auto ${maxWidth} ${className}`}
              ref={modalRef}>
              <div className="bg-white text-grey-900 border-grey-200 dark:bg-grey-800 dark:text-white dark:border-grey-700 border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none">
                {children}
              </div>
            </div>
          </div>
    </Portal>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

export const FlexGrid = ({className = '', columns = null}) => {
  return (
      <div className={`flex ${className}`}>
        {columns?.map(({content, flex = 'initial', className}, idx, allColumns)=>{
          return (
              <div key={idx} className={`flex-${flex || 'row'} ${className}`}>
                {content}
              </div>
          )
        })}
      </div>
  )
}

FlexGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
      flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      className: PropTypes.string,
  })),
}


import React from 'react'
import { useTranslation } from 'react-i18next';
import RouteOnSignin from './../../components/form/auth/route.on.signin'
import SvgLogo from "../../components/left-sidebar/logo-svg";
import {useAppSelector} from "../../redux/store";

const Layout = ({imgUrl, title, description, children}) => {
  const { t } = useTranslation();
  const {settings} = useAppSelector()

  return (
    <>
      <RouteOnSignin />
      <div className="w-full flex flex-row h-screen overflow-hidden">
        <div className="hidden sm:flex sm:flex-col w-1/2 items-center justify-center bg-grey-50 border-r border-grey-100 text-blue-600">
          <SvgLogo size={180} className={`mb-4`} />
          <span className={`text-2xl uppercase font-bold`}>{settings.project_name}</span>
          {/*<img*/}
          {/*  className="object-contain w-auto h-64 mb-8"*/}
          {/*  src={imgUrl}*/}
          {/*  alt="svg"*/}
          {/*/>*/}
        </div>
        <div className="w-full sm:w-1/2 bg-white flex flex-col items-start  p-4 pt-12 lg:px-24 overflow-y-auto">
          <div className="flex flex-col w-full mb-4">
            <div className="text-sm uppercase font-light text-grey-500">
              {title}
            </div>
            <div className="text-sm font-bold">
              {description}
            </div>
          </div>

          {children}

          <div className="flex flex-col w-full text-xs mt-4">
            {/*<div className="flex flex-row space-x-2">*/}
            {/*  <Link to="/privacy-policy">Privacy policy</Link>*/}
            {/*  <Link to="/terms-of-service">Terms of service</Link>*/}
            {/*  <Link to="/contact-us">Contact us</Link>*/}
            {/*</div>*/}
            <div className="text-grey-500">&copy; {settings.project_name}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout

import React from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import './styles.css'

const Backdrop = () => {
  const {uiConfig} = useSelector(
    (state) => ({
      config: state.uiConfig
    }),
    shallowEqual
  )
  let {backdrop} = {...uiConfig}

  return <div className={`backdrop ${backdrop ? 'fade-in' : ''}`}></div>
}

export default Backdrop

import React, {useState} from 'react'
import PropTypes from 'prop-types'

import './styles.css'
import ReactQuill from "react-quill";

export const Index = ({
                              className = '',
                              inputRef = false,
                              inline = false,
                              label = '',
                              labelClassName,
                              name = '',
                              defaultValue = '',
                                value = '',
                              placeholder = '',
                              hint = '',
                              errorMessage = '',
                              validMessage = '',
                              ...rest
                          }) => {

    return (
        <div className={`form-element flex-auto ${inline ? 'form-element-inline' : ''} ${className}`}>
            {label && <div className={`form-label ${labelClassName}`}>{label}</div>}
            <ReactQuill
                //{...rest}
                //{...(inputRef ? {ref: inputRef} : {})}
                // name={name}
                theme="snow"
                defaultValue={defaultValue}
                // value={value || defaultValue}
                onChange={rest.onChange}
                onKeyDown={(e)=>{
                    if(e.keyCode === 13){
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
                className={`form-input ${
                    // errorMessage ? 'border-red-500' : ''
                    errorMessage ? ' form-input-invalid' : ''
                    + validMessage ? ' form-input-valid' : ''
                }`}
                placeholder={placeholder}
            />

            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (typeof hint === 'string' ? <div className="form-hint" dangerouslySetInnerHTML={{__html: hint}}/> : <div className="form-hint">{hint}</div>)}
        </div>
    )
}
Index.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}


import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'
import Immutable from 'seamless-immutable'
import Config from './../config/debug.config'

let reactotron = false;
if (Config.useReactotron) {
    reactotron = Reactotron
        .configure({ name: '7AppsDevClient' })
        .configure() // we can use plugins here -- more on this later
        .use(reactotronRedux({onRestore: Immutable}))
        .connect() // let's connect!

// Let's clear Reactotron on every time we load the app
    Reactotron.clear()

// Totally hacky, but this allows you to not both importing reactotron-react-native
// on every file.  This is just DEV mode, so no big deal.
    console.tron = Reactotron
}
export default reactotron



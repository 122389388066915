import React, {useCallback, useState, useRef, useEffect, useMemo} from 'react'
import {useTranslation} from "react-i18next";
import {GroupBox} from "../../field/groupbox";
import {FlexGrid} from "../../../grid";
import {FiTrash2} from 'react-icons/fi'
import getField from "../get.field";
import {useFieldArray} from "react-hook-form";

export const NewAppDetailsBlock = ({
                                             item,
                                             idx,
                                             color,
                                             formErrors,
                                             formInitialValues,
                                             fieldProps,
                                             remove,
                                             ...rest
                                         }) => {
    const { t } = useTranslation();

    let columns = useMemo(()=>{
        return [{
            className: `pr-2 w-4`,
            content: <>{idx + 1}</>
        },{
            className: `pr-2 w-48`,
            content: getField({...rest}, formErrors, formInitialValues,
                {
                    // key: item.id,
                    // defaultValue: item.subdomain,
                    error: {
                        validate: value => !value || (value.match(/^[a-zA-Z]+[a-zA-Z0-9]*$/) && true) || t(`resource_page.app.field.subdomain_error_letter`),
                    },
                    name: `${fieldProps.name}.${idx}.subdomain`,
                    type: 'text',
                    placeholder: t('form.field.placeholder', {name: t('form.field.subdomain')}),
                    className: 'flex-none',
                },
                0),
        }, {
            flex: 'auto',
            className: ``,
            content: getField({...rest}, formErrors, formInitialValues,
                {
                    error: {
                        required: t('form.error.required', {name: t('form.field.domain')}),
                    },
                    name: `${fieldProps.name}.${idx}.domain_id`,
                    type: 'reactselect',
                    isClearable: true,
                    isMulti: false,
                    isSearchable: true,
                    loadOptions: 'domains',
                    filter: {team_id: fieldProps.teamId},
                    sortBy: {
                        [`apps_${fieldProps.appType}_count`]: 'asc'
                    },
                    className: 'flex-grow',
                },
                1),
        }, {
            className: `flex items-start`,
            content: <button onClick={()=>remove(idx)} className={`btn btn-lg btn-rounded btn-icon bg-transparent hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600`}>
                <FiTrash2 />
            </button>,
        }]
    }, [idx, fieldProps, formErrors, formInitialValues, remove]);
    return (
        <FlexGrid key={`newapps.${item.id}`} className={"w-full"} columns={columns}/>
    )
}

export const CloneNewAppsDetailsBlock = ({
                             onRemoveBlock,
                             ...rest
                           }) => {
    const { t } = useTranslation();
    const { fields: repeatFields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: rest.control,
        name: `${rest.fieldProps.name}`,
    });

  const key = useMemo(()=>Math.random(), [rest?.formErrors && Object.keys(rest?.formErrors).length > 0 ? rest?.formErrors : ''])

  let color = 'blue';
  return (
      <GroupBox key={key}
                className={'relative mt-2 px-4 py-4 rounded'}
                flex={{direction: 'col'}}
                label={t(`resource_page.app.clone.newapp.title`)}
                labelBold={true}>

          {repeatFields.length > 0 && repeatFields.map((item, idx)=>{
                  return <NewAppDetailsBlock key={idx} item={item} idx={idx} remove={remove} color={color} {...rest} />
          })}
          {(!repeatFields || repeatFields.length < 20) &&
          <button onClick={() => append({ key: "", value: "" })}
                  type={"button"}
                  className={`btn btn-sm btn-rounded btn-icon bg-transparent hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600`}>
              <span>{t(`resource_page.app.clone.newapp.btn_addapp`)}</span>
          </button>}
      </GroupBox>
  )
}

import React, {useEffect, useState} from 'react'
import {Modal} from './modal'
import {FiX} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {ModalConfirm} from "./modal.confirm";
import {ModalInfo} from "./modal.info";
import {MdCreditScore} from "react-icons/md";
import Api from "../../service/api";
import {OverlayLoader} from "../loader";
import FormPage from "../../pages/resource/form.page";
import {APP, SUBSCRIPTION} from "../../config/resources";
import FormGen from "../form/formgen2";
import {useToasts} from "react-toast-notifications";
import {useAppSelector} from "../../redux/store";
import {hasPermission} from "../../redux/reducers/user";

export const ModalSubscriptionUpdate = ({
                                 teamId,
                                 onConfirm,
                                 ...rest
                             }) => {
    const {t} = useTranslation();
    const {user, settings} = useAppSelector();
    const { addToast } = useToasts()
    const [isLoading, setIsLoading] = useState(false);
    const [team, setTeam] = useState({});

    const [formFields, setFormFields] = useState([
        {
            name: 'id',
            type: 'hidden',
            defaultValue: teamId,
        },
        {
            label: t(`form.field.subscription`),
            error: {required: t('form.error.required', {name: t('form.field.subscription')})},
            name: 'subscription_id',
            type: 'reactselect',
            isClearable: true,
            isSearchable: false,
            isMulti: false,
            loadOptions: 'resources',
            resource: "subscription",
            defaultValue: [],
            watch: (item, data, { name, type }, allFields, formFieldProps)=>{
                if(name !== 'subscription_id') return;
                if(!data.subscription_id) return;

                if(allFields[3]){
                    let newFields = [...allFields];
                    newFields[3].fields[0].placeholder = data.subscription_id.price;
                    Object.keys(data.subscription_id.services?.apps_count).forEach((key)=>{
                        newFields[3].fields[1].fields.forEach((field)=>{
                            if(field.name !== `subscription_services_custom.apps_count.${key}`) return;
                            field.placeholder = data.subscription_id.services.apps_count[key];
                        })
                    });
                }

                // setFormFields(newFields);
            },
        },
        ...(
            hasPermission(user, ['team subscription_change_date']) ?
             [{
                 label: t('filter.daterange'),
                 error: {},
                 name: 'subscription_end',
                 type: 'datetime',
                 defaultValue: '',
             },
             {
                     label: t(`form.field.custom_settings`),
                     name: `custom`,
                     type: 'activeitems',
                     defaultValue: false,
                     fields: [
                         {
                             label: t('form.field.price'),
                             defaultValue: '',
                             name: 'subscription_price_custom',
                             type: settings.monetization_active ? 'text' : 'hidden',
                             placeholder: t('form.field.placeholder', {name: t('form.field.price')})
                         },
                         {
                             label: t('form.field.services'),
                             type: 'groupbox',
                             labelBold: true,
                             showBorder: false,
                             className: 'mt-6',
                             classNameContent: 'flex-wrap gap-2',
                             flex: {direction: 'row', space: '-'},
                             fields: [APP.TYPE.PWA_ALL, APP.TYPE.ANDROID, APP.TYPE.IOS].map((appType, idx)=>{
                                 let label = t(`resource_page.subscription.field.app_type_count`, {appType: t(`resource_page.app.field.app_type_${appType}`)});
                                 return (
                                     {
                                         label: label,
                                         name: `subscription_services_custom.apps_count.${appType}`,
                                         type: 'text',
                                         className: 'flex-1',
                                         placeholder: t('form.field.placeholder', {name: label }),
                                         hint: t(`resource_page.subscription.field.app_type_count_unlimited`),
                                     }
                                 )
                             })

                         },
                     ],
                 },]
                : [{name: 'empty',
                    type: 'hidden',}]
        )

    ]);

    const onSubmit = (formData, setError, setAlerts) => {
        return Api.getCSRFCookie().then(() => Api.resourceAction('team', 'subscriptionUpdate', formData))
    }
    const onComplete = (response, formData) => {
        addToast(t(`team_subscription.message_complete`), {
            appearance: 'success',
            autoDismiss: true,
        })
        onConfirm();
    }

    useEffect(()=>{
        const fetchData = async () => {
            setIsLoading(true);
            try {
                let resInfo = await Api.getResource('team', teamId)

                let hasCustom = resInfo.data.subscription.custom?.price ? true : false;
                if(resInfo.data.subscription.custom?.services?.apps_count){
                    for(let k in resInfo.data.subscription.custom?.services?.apps_count){
                        if(!resInfo.data.subscription.custom?.services?.apps_count.hasOwnProperty(k)) continue;
                        if(resInfo.data.subscription.custom?.services?.apps_count[k]) hasCustom = true;
                    }
                }

                let initialData = {
                    title: resInfo.data.title,
                    subscription_id: resInfo.data.subscription.id ? {value: resInfo.data.subscription.id, label: resInfo.data.subscription.title} : null,
                    subscription_end: resInfo.data.subscription.date_end || '',
                    subscription_price_custom: resInfo.data.subscription.custom?.price,
                    subscription_services_custom: resInfo.data.subscription.custom?.services,
                    custom: hasCustom,
                }

                setTeam(initialData);
                setIsLoading(false);
            } catch (e) {
                console.error('Error.TeamSubscriptionUpdate', e);
            }
        }
        rest.isOpen && fetchData();
    }, [teamId, rest.isOpen])

    return (
        <ModalInfo
            className={`lg:max-w-xl`}
            icon={
                <span className="h-10 w-10 bg-blue-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    {/*<FiX size={18} className="stroke-current text-red-500"/>*/}
                    <MdCreditScore className={`text-blue-500`} />
                </span>
            }
            title={t('modal.subscriptionupdate.title')}
            body={
                <OverlayLoader show={isLoading}>
                    <div className="text-sm text-grey-500">
                        <div className={`mb-2`}>Team name: <span className={`font-bold`}>{team?.title}</span></div>

                        {!isLoading &&
                        <FormGen items={formFields}
                                 initialValues={team}
                                 onSubmit={onSubmit}
                                 onComplete={onComplete}
                                 submitText={t(`modal.subscriptionupdate.btn_save`)}/>}
                    </div>
                </OverlayLoader>
            }
            {...rest}
        />
    )
}



import React from 'react'
import FormGen from '../formgen2'
import {useTranslation} from "react-i18next";
import Api from "../../../service/api";

const ResetPassword = ({email, token, onComplete}) => {
    const {t} = useTranslation();

    const onSubmit = (formData, setError, setAlerts) => {
        return Api.getCSRFCookie().then(() => Api.resetPassword(email, token, formData.password, formData.password_confirmation));
    }
    let items = [
        {
            label: t('auth.reset_password.field.password'),
            error: {
                required: t('form.error.required', {name: t('auth.reset_password.field.password')}),
                minLength: {
                    value: 8,
                    message: t('form.error.minlength', {count: 8, name: t('auth.reset_password.field.password')}),
                },
                maxLength: {
                    value: 50,
                    message: t('form.error.maxlength', {count: 50, name: t('auth.reset_password.field.password')}),
                }
            },
            name: 'password',
            type: 'password',
            placeholder: t('form.field.placeholder', {name: t('auth.reset_password.field.password')})
        },
        {
            label: t('auth.reset_password.field.password_confirmation'),
            error: {
                required: t('form.error.required', {name: t('auth.reset_password.field.password_confirmation')}),
                validate: {
                    type: 'sameasfield',
                    field: 'password',
                    message: t('form.error.same_as_field', {name: t('auth.reset_password.field.password')})
                },
            },
            name: 'password_confirmation',
            type: 'password',
            placeholder: t('form.field.placeholder', {name: t('auth.reset_password.field.password_confirmation')})
        },
    ]
    return (
        <>
            <div className="flex flex-col">
                <FormGen items={items}
                         onSubmit={onSubmit}
                         onComplete={onComplete}
                         submitText={t('auth.reset_password.submit_button')}/>
            </div>
        </>
    )
}

export default ResetPassword

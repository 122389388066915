import {combineReducers} from 'redux'
import uiConfig from './ui.config'
// import leftSidebar from './left-sidebar'
import navigationMenu from './navigation.menu'
import user from './user'
import settings from './settings'

const rootReducer = combineReducers({
  navigationMenu,
  uiConfig,
  user,
  settings,
})

export default rootReducer

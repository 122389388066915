import React, {useCallback, useState, useRef, useEffect} from 'react'
import * as R from 'ramda';
import {useTranslation} from "react-i18next";
import {GroupBox} from "../../field/groupbox";
import {FlexGrid} from "../../../grid";
import {TextInput} from "../../field/text-inputs";
import {CATEGORY, ReactSelect} from "../../field/react-selects";
import {FiTrash2} from 'react-icons/fi'
import getField from "../get.field";
import {useFieldArray} from "react-hook-form";

const REPEAT_HITS_OPTIONS = [
    {value: 2, label: "2 or more"},
    {value: 3, label: "3 or more"},
    {value: 4, label: "4 or more"},
    {value: 5, label: "5 or more"},
];

export const FormOfferUrlBlock = ({
                             isFixed = false,
                             name,
                             offerId,

                             onRemoveBlock,
                             ...rest
                           }) => {
    const { t } = useTranslation();
    const { fields: repeatFields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: rest.control,
        name: `${rest.fieldProps.name}.${name}.repeat`,
    });

  let color = 'blue';
  return (
      <GroupBox
                className={'relative mt-2 hover:bg-grey-100 hover:dark:bg-grey-800 px-4 py-4 rounded'}
                flex={{direction: 'col'}}
                label={t(`resource_page.adcampaign.field.${name === 'default' ? 'offer_default' : 'offer_for_country'}`, {geo: name.toUpperCase()})}
                labelBold={true}>
              {!isFixed &&
                      <button onClick={()=>onRemoveBlock(offerId, name)}
                              type={"button"}
                              className={`absolute right-0 top-0 mt-1 mr-1 btn btn-transparent btn-lg btn-rounded hover:btn-outlined bg-transparent border-${color}-500 hover:border-${color}-700  hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600`}>
                          <FiTrash2 />
                      </button> }

            <div className={"w-full"}>
                {name !== 'default' && getField({...rest}, rest.formErrors, rest.formInitialValues,
                   {
                            label: t(`resource_page.adcampaign.field.offer_geo`),
                            error: {required: t('form.error.required', {name: t('resource_page.adcampaign.field.offer_geo')})},
                            name: `${rest.fieldProps.name}.${name}.geo`,
                            type: 'reactselect',
                            isClearable: true,
                            isMulti: true,
                            isSearchable: true,
                            loadOptions: 'countries',
                        },
                        0)}
                {getField({...rest}, rest.formErrors, rest.formInitialValues,
                   {
                        label: t(`resource_page.adcampaign.field.offer_url`),
                        error: {required: t('form.error.required', {name: t('resource_page.adcampaign.field.offer_url')})},
                        name: `${rest.fieldProps.name}.${name}.url`,
                        type: 'url',
                        pattern: "https://.*",
                    },
                    1)}
            </div>

            {repeatFields.length > 0 && repeatFields.map((item, idx)=><FlexGrid key={`repeat.${item.id}`} className={"w-full"} columns={[{
                    className: `pr-2 w-48`,
                    content: getField({...rest}, rest.formErrors, rest.formInitialValues,
                        {
                            error: {required: true},
                            name: `${rest.fieldProps.name}.${name}.repeat.${idx}.key`,
                            type: 'reactselect',
                            isClearable: false,
                            options: REPEAT_HITS_OPTIONS,
                        },
                        0),
                  }, {
                    flex: 'auto',
                    className: ``,
                    content: getField({...rest}, rest.formErrors, rest.formInitialValues,
                        {
                                error: {required: true},
                                name: `${rest.fieldProps.name}.${name}.repeat.${idx}.value`,
                                type: 'url',
                                pattern: "https://.*",
                            },
                        1),
                  }, {
                  className: `flex items-start`,
                  content: <button onClick={()=>remove(idx)} className={`btn btn-lg btn-rounded btn-icon bg-transparent hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600`}>
                    <FiTrash2 />
                  </button>,
                }]}/>
            )}
        {(!repeatFields || repeatFields.length < 5) &&
          <button onClick={() => append({ key: "", value: "" })}
                  type={"button"}
                  className={`btn btn-sm btn-rounded btn-icon bg-transparent hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600`}>
            <span>{"Add offer for repeat app open"}</span>
          </button>}
      </GroupBox>


  )
}

import React, {useEffect, useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import DropZone from "../../dropzone";
import {useTranslation} from "react-i18next";
import {FiTrash2} from 'react-icons/fi'
import {FilesSimple} from "./files";
import {asset} from "../../../redux/reducers/settings";

export const Image = ({
                              inline = false,
                              label = '',
                              defaultValue = false,
                              hint = '',
                              errorMessage = '',
                              validMessage = '',
                              dropMessage,
                              onRemove = false,
                              onDrop = false,
                              className,
                              css,
                              ...rest
                          }) => {
    const {t} = useTranslation();
    const [image, setImage] = useState(defaultValue)

    const _onRemove = useCallback(() => {
            setImage(false);
            onRemove && typeof onRemove === 'function' && onRemove(rest.name);
            // rest.name && reset({ [rest.name]: new File() });
        },
     [onRemove]
    )
    const _onDrop = useCallback(acceptedFiles => {
            console.log('acceptedFiles', acceptedFiles);

            if(!acceptedFiles || acceptedFiles.length === 0) return;
            Object.assign(acceptedFiles[0], {
                preview: URL.createObjectURL(acceptedFiles[0])
            })

            setImage( acceptedFiles[0] );
            onDrop && typeof onDrop === 'function' && onDrop(acceptedFiles[0]);
        },
        [onDrop]
    )

    useEffect(
        () => setImage(defaultValue),
        [defaultValue]
    );

    // useEffect(
    //     () => {
    //         // Make sure to revoke the data uris to avoid memory leaks
    //         image && typeof image === 'object' &&  URL.revokeObjectURL(image.preview)
    //     },
    //     [image]
    // )

    let color = 'blue';

    return (
        <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
            {label && <div className="form-label">{label}</div>}

            {image ? (
                    <MediaPreview media={image} onRemove={_onRemove} css={css} className={className} />
                ) : (
                    <DropZone {...rest} options={{
                                accept: rest.accept || 'image/*',
                                maxSize: 5 * 1000 * 1000, //5mb
                                maxFiles: 1,
                                multiple: false,
                                onDrop: _onDrop,
                            }}
                            dropMessage={dropMessage || t('form.field.image_drop_message')}
                            size={{w: css?.w || 40, h: css?.h || 40}} />
            )}

            {errorMessage && (
                <div className="form-error whitespace-normal">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success whitespace-normal">{validMessage}</div>
            )}
            {hint && (typeof hint === 'string' ? <div className="form-hint whitespace-normal" dangerouslySetInnerHTML={{__html: hint}}/> : <div className="form-hint whitespace-normal">{hint}</div>)}
        </div>
    )
}
Image.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.string,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}

const VideoPreview = ({video, onRemove, css, className = ''}) => {
    let src = asset(typeof video === 'object' ? video.preview : video);
    return (
        <>
            <video autoPlay preload="auto" muted loop className={`w-${css?.w || 32} h-${css?.h || 40} object-contain border-dashed border-4 border-grey-200 shadow ${className}`}>
                <source src={src} type="video/mp4" />
            </video>
            <button onClick={(e)=>{e.preventDefault(); onRemove()}}
                    className={`btn btn-sm btn-icon bg-transparent rounded-b hover:bg-blue-50 text-blue-500 hover:text-blue-600 w-${css?.w || 32}`}>
                <FiTrash2 className="stroke-current mr-2" />
                <span>{"remove"}</span>
            </button>
        </>
    )
}

const ImagePreview = ({image, onRemove, css, className = ''}) => {
    let src = asset(typeof image === 'object' ? image.preview : image);
    return (
        <>
            <img src={src} alt="thumbnail" className={`w-${css?.w || 32} h-${css?.h || 40} object-contain border-dashed border-4 border-grey-200 shadow ${className}`}/>
            <button onClick={(e)=>{e.preventDefault(); onRemove()}}
                    className={`btn btn-sm btn-icon bg-transparent rounded-b hover:bg-blue-50 text-blue-500 hover:text-blue-600 w-${css?.w || 32}`}>
                <FiTrash2 className="stroke-current mr-2" />
                <span>{"remove"}</span>
            </button>
        </>
    )
}

const MediaPreview = ({media, onRemove, css, className = ''}) => {
    let src = typeof media === 'object' ? media.name : media;

    return src.includes('.mp4') ?
            <VideoPreview video={media} onRemove={onRemove} css={css} className={className} />
            : <ImagePreview image={media} onRemove={onRemove} css={css} className={className} />
}

const ImagesPreview = ({files, onRemove}) => {
    if(!files || files.length <= 0) return null;

    return (
        <div className={`flex flex-column flex-wrap  items-center`}>
            {files.map((media, idx)=>{
                return (
                    <div key={media} className={"mt-2 mr-2"}>
                        <MediaPreview media={media} onRemove={()=>onRemove(idx)} />
                    </div>
                )
            })}
        </div>
    )
}

export const Images = ({
                           defaultValue,
                           onChange,
                           accept= 'image/*',
                          ...rest
                      }) => {
    const {t} = useTranslation();
    const [images, setImages] = useState(defaultValue)

    const onChangeImages = useCallback((files) => {
            typeof onChange === 'function' && onChange(files);
            setImages(files)
        },
        []
    )
    const onRemoveImage = useCallback((fieldName) => {
            typeof onChange === 'function' && onChange(null);
            setImages(null);
        },
        [images]
    )

    return (
        <>
            <FilesSimple dropMessage={t('form.field.image_drop_message')}
                   {...rest}
                   maxFiles={10}
                   maxSizeMb={5}
                   accept={accept}
                   filesPreview={<ImagesPreview />}
                   defaultValue={images}
                   css={{h:20}}
                   onRemove={onRemoveImage}
                   onDrop={onChangeImages}
            />
        </>
    );
}

import React from 'react'
import {useAppSelector} from "../../redux/store";
import {asset} from "../../redux/reducers/settings";
import {SvgUrl} from "../image";

const SvgLogo = ({size = 40,  color = 'currentColor', className = ''}) => {
    const {settings} = useAppSelector()

    // return <SvgUrl url={asset(settings.project_logo)} className={className} width={size} height={size} />

    return (
        <img src={asset(settings.project_logo)} width={size} height={size} className={className} />
    )
    // return (
    //     <svg xmlns="http://www.w3.org/2000/svg" className={className} width={size} height={size} stroke={color} fill="none" viewBox="0 0 136 151">
    //         <g fill="none" fillRule="evenodd" stroke={color} transform="translate(5 5)">
    //             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="10" d="M126,98.6666667 L126,42.2857143 C125.994875,37.2551352 123.327177,32.6086216 119,30.0933333 L70,1.90285714 C65.6683849,-0.615014634 60.3316151,-0.615014634 56,1.90285714 L7,30.0933333 C2.67282344,32.6086216 0.00512504118,37.2551352 0,42.2857143 L0,98.6666667 C0.00512504118,103.697246 2.67282344,108.343759 7,110.859048 L56,139.049524 C60.3316151,141.567396 65.6683849,141.567396 70,139.049524 L119,110.859048 C123.327177,108.343759 125.994875,103.697246 126,98.6666667 Z"/>
    //             <polyline strokeLinecap="round" strokeLinejoin="round" strokeWidth="10" points="1.89 34.956 63 70.547 124.11 34.956"/>
    //             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="10" d="M63,139.51619 L63,69.47619"/>
    //             <path fill={color} fillRule="nonzero" strokeWidth=".5" d="M89.7114901,76.1142923 L104.883606,78.1006731 L101.636298,83.6585294 L89.7114901,76.1142923 Z M86.5992613,76.9759268 C85.8968485,77.7860123 85.4267723,78.8245635 85.4267723,79.8427927 L85.4267723,107.752391 C85.4267723,108.77062 85.8968485,109.300521 86.5992613,109.350555 L100.463809,85.6589518 L86.5992613,76.9759268 Z M106.391092,78.3792011 L102.841206,84.3790346 L106.391092,86.5376832 L109.6384,82.6241722 C110.610971,81.1935926 110.610971,79.1571342 109.573562,78.8140134 L106.391092,78.3792011 Z M89.7114901,106.850899 L104.883606,88.4473993 L101.636298,86.4033211 L89.7114901,106.850899 Z"/>
    //             <path fill={color} fillRule="nonzero" strokeWidth=".522" d="M38.0897884,104.432397 L44,86.6691201 L39.8645455,84.0330014 L35.8095893,95.3612915 L32.5624823,79.3783483 L29.3830306,77.3516273 L26.3378574,89.3235971 L23.9431013,81.457853 L22,89.129807 L24.1880151,95.5708018 L28.2576491,98.1649637 L31.0116594,87.4427743 L34.0113249,101.832607 L38.0897884,104.432397 Z M15.1309262,83.0566282 L17.6708303,84.6612989 L18.2842127,82.2433468 L20,75.4878325 C19.7913865,75.3764198 19.5816864,75.2439347 19.3724471,75.0913547 C19.8651856,73.1373391 19.2033953,71.5865708 17.3870764,70.4390496 L11,66.4037971 L11.3282101,87.9947048 L15.2435946,90.468382 L15.1309262,83.0566282 Z M18.9992502,77.8561719 C19.0120668,78.7267322 18.8605659,79.3210884 18.5447475,79.6392406 C18.194587,79.9872618 17.5383986,79.8605641 16.5761824,79.2591477 L15.0890896,78.3308169 L15,72.5220775 L16.4935795,73.4544592 C17.3903759,74.0142931 18.0289827,74.6702303 18.4093998,75.4222708 C18.7898169,76.1743112 18.9864337,76.9856116 18.9992502,77.8561719 Z M42,102.263872 L43.1330503,98.7780804 L46.5889024,100.976696 L44.8513642,93.4896036 L46.792592,87.5140392 L53,113.145741 L48.6666254,110.388844 L47.6035155,105.828833 L42,102.263872 Z"/>
    //             <path fill={color} fillRule="nonzero" strokeWidth=".5" d="M90.3099363,31.1940063 L65.8343766,18.1045509 C63.9756374,17.1191232 60.9103569,17.1558799 58.9952561,18.1866125 L33.7928439,31.8778525 C31.9132633,32.908585 31.9433839,34.6608011 33.8554342,35.7897767 L59.0015011,50.7859805 C60.8756483,51.9149561 63.8745937,51.8781993 65.7017263,50.703919 L90.2336315,35.1059305 C92.1002232,33.9316501 92.1364377,32.1794341 90.3099363,31.1940063 Z M46.2618014,35.2942259 C45.1917347,35.4537619 44.0868829,35.0712132 43.7960889,34.4554297 C43.4983958,33.8367628 44.1398676,33.2127196 45.2052509,33.0598645 L47.9740624,32.6565276 C49.4673302,33.117277 50.1377489,33.8105279 49.9874783,34.7400463 L46.2618014,35.2942259 Z M47.0084771,31.3607405 C46.2241229,30.91198 46.2215764,30.1877876 47.0036088,29.7514241 C47.7860767,29.3150605 49.0670499,29.3208507 49.8494311,29.7642911 L53.4712426,31.8202419 L66.1163548,29.7794238 L67.1953241,27.5271652 C67.4936949,26.921686 68.5947999,26.5476666 69.6521708,26.6846897 C70.7159122,26.8242611 71.3338948,27.4260125 71.0422256,28.0300356 L70.5697323,29.0112659 L72.2865059,28.7113158 C73.3530914,28.5208233 74.4389365,28.8560251 74.7109921,29.449349 C74.9902635,30.0477617 74.3413582,30.689097 73.2841291,30.8780568 L56.7371596,33.6786602 L61.1247003,36.1820826 C62.5489473,36.9964004 61.6807426,38.4524786 59.9158985,38.781608 L47.0084771,31.3607405 Z M64.7880441,41.6006401 L63.8035113,43.8641583 C63.5227109,44.5059896 62.44123,44.8967845 61.3882168,44.7398423 C60.3274834,44.5774763 59.6950742,43.9285776 59.9684121,43.2937157 C61.6227142,39.519391 62.893443,36.7161156 63.7317566,34.8630035 C64.6044639,32.9883112 67.4914476,32.2463918 69.0192349,32.3016798 C68.3332938,33.7696145 67.3115752,35.9808942 65.9696876,38.9556006 L69.6204873,41.0518642 C70.3915462,41.4953045 70.3939136,42.249967 69.6265639,42.7288919 C68.8527142,43.2121706 67.6005603,43.2324966 66.8276906,42.7837361 L64.7880441,41.6006401 Z"/>
    //         </g>
    //     </svg>
    // )
}

export default SvgLogo

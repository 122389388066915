import React, {useCallback, useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from "react-i18next";
import {BiCommentAdd} from "react-icons/bi";
import * as R from "ramda";
import {FiEdit, FiRefreshCw, FiTrash2} from "react-icons/fi";
import {FlexGrid} from "../../grid";

export const ItemWrapper = ({
                            id,
                            itemComponent,
                            onChangeField,
                            onRemove,
                            disableWrapper,
                             ...rest
                         }) => {
    const { t } = useTranslation();

    const onChangeFieldByEvent = useCallback((e)=>{
        typeof onChangeField === 'function' && onChangeField(id, e.currentTarget.name, e.currentTarget.value)
    }, [id, onChangeField]);

    return (
        <FlexGrid
            className={`w-full hover:bg-grey-100 hover:dark:bg-grey-800 ${disableWrapper ? `` : `px-4 py-2 gap-2`}  rounded`}
            columns={[
                {
                    flex: 1,
                    content: <>
                        <itemComponent.type {...itemComponent.props} {...rest}
                                            id={id}
                                            onChangeField={onChangeField}
                                            onChangeFieldByEvent={onChangeFieldByEvent}
                                            onRemove={onRemove}
                        />
                    </>
                },
                disableWrapper ? null : {
                    className: `flex justify-items-center items-center`,
                    content: <button onClick={() => onRemove(id)}
                                     type={"button"}
                                     className={`btn btn-transparent btn-lg btn-rounded hover:btn-outlined bg-transparent border-blue-500 hover:border-blue-700  hover:bg-blue-50 text-blue-500 hover:text-blue-600`}>
                        <FiTrash2/>
                    </button>
                }
            ].filter((column)=>column)}
        />
    )
    ;
}


export const DynamicItems = ({
                                 className = '',
                                 inline = false,
                                 label = '',
                                 labelBold = false,
                                 name = '',
                                 defaultValue = [],
                                  itemComponent,
                                 disableItemWrapper = false,
                                 btnAddText,
                                 errorMessage,
                                 validMessage,
                                  onChange,
                              ...rest
                          }) => {
    const { t } = useTranslation();
    const [items, setItems] = useState(defaultValue || [])

    const onAdd = useCallback(()=>{
        setItems([...items, {id: Date.now(), time: Date.now()}])
    }, [items])
    const onRemove = useCallback((fieldId)=>{
        const idx = R.findIndex(R.propEq('id', fieldId))(items)
        if(idx < 0) return;
        items.splice(idx, 1);
        let newItems = [...items];
        setItems(newItems)
        typeof onChange === 'function' && onChange(newItems);
    }, [items, onChange])
    const onChangeField = useCallback((itemId, fieldName, value)=>{
        let idx = R.findIndex(R.propEq('id', itemId))(items)
        if(idx < 0) return;
        items[idx][fieldName] = value;
        let newItems = [...items];
        setItems(newItems);
        typeof onChange === 'function' && onChange(newItems);
    }, [items, onChange])

    // useEffect(()=>{
    //
    // }, [items])
// console.log('rest', rest)
    return (
        <div className={`form-element ${inline || rest?.fieldProps?.inline ? 'form-element-inline' : ''} ${className || rest?.fieldProps?.className}`}>
            <div className={`form-label ${labelBold || rest?.fieldProps?.labelBold? 'font-bold' : ''}`}>{label || rest?.fieldProps?.label || t(`form.field.comment.title`)}</div>

            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}

                {items && items.map((item, idx, allComments)=>{
                    // return <itemComponent.type {...itemComponent.props} key={`${item.id}`} {...item} />
                    return <ItemWrapper key={`${item.id}`} idx={idx} itemComponent={itemComponent} {...item}
                                        fieldProps={rest?.fieldProps}
                                        disableWrapper={disableItemWrapper || rest?.fieldProps?.disableItemWrapper}
                                        onChangeField={onChangeField} onRemove={onRemove} />
                })}
                <button onClick={onAdd} className={`btn btn-sm btn-rounded btn-icon bg-transparent hover:bg-blue-50 text-blue-500 hover:text-blue-600 `} type={"button"}>
                    <BiCommentAdd className="stroke-current mr-2" />
                    <span>{btnAddText || t(`form.field.dynamic_items.btn_add`)}</span>
                </button>
        </div>
    );
}
DynamicItems.propTypes = {
    inline: PropTypes.bool,
    labelBold: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}

import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from "react-i18next";
import {GroupBox} from "../../field/groupbox";
import getField, {getItemDefaultValue} from "../get.field";


export const ActiveItems = (props) => {
    const { t } = useTranslation();
    const isEnabled = props.watch(props.fieldProps.name,
        getItemDefaultValue({
                name: props.fieldProps.name,
                type: 'checkbox',
                defaultValue: false,
            },
            props.formInitialValues));

    useEffect(()=>{
        if(isEnabled) return;
        const fieldNames = [props.fieldProps.name, ...props.fieldProps.fields?.map((field, idx, allFields)=>field?.name)];
        props.unregister(
            fieldNames,
            {keepValue: true, keepDefaultValue: true}
        );
    }, [isEnabled, props.unregister])

    return (
        <>
            {getField({...props}, props.formErrors, props.formInitialValues,
                {
                    inline: false,
                    error: {},
                    ...props.fieldProps,
                    type: 'checkbox',
                },
                0)}

            {isEnabled ?
                <GroupBox className={"mb-2"} showBorder={true} flex={{direction: 'col', items: 'stretch', space: '-'}}>
                    {props.fieldProps.fields?.map((field, idx, allFields)=>{
                        return <div key={`${idx}:enabled:${isEnabled}`} >
                            {getField({...props}, props.formErrors, props.formInitialValues,
                                field,
                                idx+1)}
                        </div>
                    })}
                </GroupBox> : null}


        </>
    )
}

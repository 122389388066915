import React from 'react'
import {FiBox, FiMenu} from 'react-icons/fi'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import SvgLogo from "./logo-svg";
import {useAppSelector} from "../../redux/store";

const Logo = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const {settings, uiConfig} = useAppSelector()
    // const {uiConfig} = useSelector(
    //     state => ({
    //         uiConfig: state.uiConfig,
    //     }),
    //     shallowEqual
    // )
    const {collapsed, showLogo} = {...uiConfig}
    if (!showLogo) return null;
    return (
        <div className="logo truncate">
            <Link
                to="/"
                className="flex flex-row items-center justify-start space-x-2">
                {/*<FiBox size={28}/>*/}
                <SvgLogo />
                <span>{settings.project_name}</span>
            </Link>
            <button
                onClick={() =>
                    dispatch({
                        type: 'SET_CONFIG_KEY',
                        key: 'collapsed',
                        value: !collapsed
                    })
                }
                className="ml-auto mr-4 block lg:hidden">
                <FiMenu size={20}/>
            </button>
        </div>
    )
}

export default Logo

import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import _Datetime from 'react-datetime'
import moment from 'moment';
import './styles.css'

export const DateTime = ({
                              className = '',
                              inputRef = false,
                              inline = false,
                              defaultValue = new Date(),
                              label = '',
                              name = '',
                              placeholder = '',
                              hint = '',
                              errorMessage = '',
                              validMessage = '',
                              dateFormat = "YYYY-MM-DD",
                              timeFormat = false,
                              viewMode = DateTime.VIEW_MODE.DAYS,
                              closeOnSelect = true,
                              onChange,
                              ...rest
                          }) => {
    const _onChange = useCallback((value)=>{
        onChange && onChange(value.format(dateFormat || timeFormat))
    }, [dateFormat, onChange])

    return (
        <div className={`form-element flex-auto ${inline ? 'form-element-inline' : ''} ${className}`}>
            {label && <div className="form-label">{label}</div>}

            <_Datetime
                key={defaultValue || 'dtkey'}
                defaultValue={defaultValue}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                input={true}
                closeOnSelect={closeOnSelect}
                inputProps={{
                    // ...rest,
                    ...(inputRef ? {ref: inputRef} : {}),
                    name: name,
                    className: `form-input ${
                        // errorMessage ? 'border-red-500' : ''
                        errorMessage ? ' form-input-invalid' : ''
                        + validMessage ? ' form-input-valid' : ''
                    }`,
                    placeholder: placeholder,
                }}
                viewMode={viewMode}
                onChange={_onChange}
            />
            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (<div className="form-hint" dangerouslySetInnerHTML={{__html: hint}}/>)}
        </div>
    )
}
DateTime.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    hint: PropTypes.string,
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string,
    closeOnSelect: PropTypes.bool,
    onChange: PropTypes.func
}
DateTime.VIEW_MODE = {
    MONTH: 'months',
    DAYS: 'days',
    TIME: 'time',
}

export const DateTimeRange = ({
                             className = '',
                             inputRef = false,
                             inline = false,
                             defaultValue,
                             label = '',
                             name = '',
                             placeholder = '',
                             hint = '',
                             errorMessage = '',
                             validMessage = '',
                             dateFormat = "YYYY-MM-DD",
                             closeOnSelect = true,
                             onChange,
                             ...rest
                         }) => {
    const [dateRange, setDateRange] = useState({from: moment(defaultValue?.from).format(dateFormat), to: moment(new Date()).format(dateFormat)});

    useEffect(()=>{
        let from = defaultValue?.from ? moment(defaultValue.from).format(dateFormat) : dateRange.from;
        let to = defaultValue?.to ? moment(defaultValue.to).format(dateFormat) : dateRange.to;
        setDateRange({from: from, to: to});
    }, [defaultValue?.from, defaultValue?.to])
    useEffect(()=>{
        onChange && onChange(dateRange)
    }, [dateRange, onChange])

    const _onChange = useCallback((field, value)=>{
        // setDateRange({...dateRange, [field]: value.utc().format(dateFormat)});
        setDateRange({...dateRange, [field]: value.format(dateFormat)});
    }, [dateFormat, dateRange])
    return (
        <div className={`form-element flex-auto ${inline ? 'form-element-inline' : ''} ${className}`}>
            {label && <div className="form-label">{label}</div>}

            <div className={"flex space-x-2"} >
                 <div className={"flex-1 "}>
                     <_Datetime
                         defaultValue={dateRange?.from}
                         dateFormat={dateFormat}
                         timeFormat={false}
                         input={true}
                         closeOnSelect={closeOnSelect}
                         inputProps={{
                             // ...rest,
                             ...(inputRef ? {ref: inputRef} : {}),
                             name: `${name}.from`,
                             className: `form-input ${
                                 // errorMessage ? 'border-red-500' : ''
                                 errorMessage ? ' form-input-invalid' : ''
                                 + validMessage ? ' form-input-valid' : ''
                             }`,
                             placeholder: placeholder,
                         }}
                         viewMode={'days'}
                         onChange={(val)=>_onChange('from', val)}
                     />
                 </div>
                 <div className={"flex-1"}>
                     <_Datetime
                         defaultValue={dateRange?.to}
                         dateFormat={dateFormat}
                         timeFormat={false}
                         input={true}
                         closeOnSelect={closeOnSelect}
                         inputProps={{
                             // ...rest,
                             ...(inputRef ? {ref: inputRef} : {}),
                             name: `${name}.to`,
                             className: `form-input ${
                                 // errorMessage ? 'border-red-500' : ''
                                 errorMessage ? ' form-input-invalid' : ''
                                 + validMessage ? ' form-input-valid' : ''
                             }`,
                             placeholder: placeholder,
                         }}
                         viewMode={'days'}
                         onChange={(val)=>_onChange('to', val)}
                     />
                 </div>
            </div>

            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (<div className="form-hint" dangerouslySetInnerHTML={{__html: hint}}/>)}
        </div>
    )
}
DateTimeRange.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    hint: PropTypes.string,
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string,
    closeOnSelect: PropTypes.bool,
    onChange: PropTypes.func
}

import React from 'react'
import Loader from 'react-loader-advanced';
import PropagateLoader from "react-spinners/PropagateLoader";
import {getColor} from "../../helper/colors";


export const OverlayLoader = ({children, show, color = getColor('text-light-blue-700')}) => {
  //getColor('text-blue-grey-700')
  return (
      <Loader show={show} message={
            <PropagateLoader color={color} loading={show} size={15} />
      } backgroundStyle={{backgroundColor: '#00000006'}}>
        {children}
      </Loader>
  )
}


import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {TextInput, TextArea} from "../../field/text-inputs";
import {useTranslation} from "react-i18next";
import {FlexGrid} from "../../../grid";
import {DynamicItems} from "../../field/dynamic.items";
import getField from "../../formgen2/get.field";
import {WHITEPAGE} from "../../../../config/resources";

export const PixelItem = ({
                            id,
                            idx,
                            fbpid,
                            fbptoken,
                            onChangeField,
                            onChangeFieldByEvent,
                            onRemove,
                            fieldProps,
                             ...rest
                         }) => {
    const { t } = useTranslation();

    return (
        <div className={"relative"}>
            <FlexGrid columns={[{
                className: `w-42`,
                // flex: 1,
                content: <>
                    <TextInput
                        name={`fbpid`}
                        defaultValue={fbpid}
                        label={idx === 0 ? t(`form.field.fb.pixelid`) : ``}
                        errorMessage={''}
                        onChange={onChangeFieldByEvent} />
                </>,
            },{
                className: `pl-2`,
                flex: 1,
                content: <>
                    <TextInput
                        name={`fbptoken`}
                        defaultValue={fbptoken}
                        label={idx === 0 ? t(`form.field.fb.pixeltoken`) : ``}
                        errorMessage={''}
                        onChange={onChangeFieldByEvent} />
                </>,
            },]} />
        </div>
    );
}


export const Pixels = ({
                              ...rest
                          }) => {
    const { t } = useTranslation();

    return (
        <DynamicItems
            label={t('form.field.pixels.title')}
            btnAddText={t('form.field.pixels.btn_add')}
            {...rest}
            itemComponent={<PixelItem />} />
    );
}
Pixels.propTypes = DynamicItems.propTypes;

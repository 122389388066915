import OpenReplay from '@openreplay/tracker';
import trackerFetch from '@openreplay/tracker-fetch';
import trackerAxios from '@openreplay/tracker-axios';
import trackerRedux from '@openreplay/tracker-redux';
import {__DEV__} from "../config/debug.config";

const openReplay = new OpenReplay({
    projectKey: "oiGu2VcndphggOINJoYs",
    ingestPoint: "https://oreplay.7apps.dev/ingest",
    __DISABLE_SECURE_MODE: __DEV__,
});
// openReplay.start();

openReplay.use(trackerAxios());

export default openReplay;
export const openReplayReduxMiddleware = openReplay.use(trackerRedux());
// export const openReplayReduxMiddleware = null;

import React from 'react'
import Layout from '../layouts/centered'
import {Link, useHistory} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../redux/store";

const ErrorPage = () => {
  const {t} = useTranslation();
  const {settings} = useAppSelector()
  const { goBack } = useHistory()
  return (
    <Layout>
      <div className="flex flex-col w-full max-w-xl text-center">
        <img
          className="object-contain w-auto h-56 mb-8"
          src="/pages/error-page/illustration.svg"
          alt="svg"
        />
        <h1 className="text-6xl text-blue-500 mb-4">404</h1>

        <div className="mb-8 text-center text-grey-500">
          {t("page.404.description", {contactEmail: settings.settings_ui?.mail_address_from || ''})}
        </div>
        <div className="flex w-full">
          <Link
            onClick={() => goBack()}
            className="btn btn-lg btn-rounded btn-block bg-blue-500 hover:bg-blue-600 text-white">
            {t("page.404.button_goback")}
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default ErrorPage

import React, {useEffect, useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import {useTranslation} from "react-i18next";
import { JSHINT } from "jshint";
import {Controlled as _CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/dracula.css';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/javascript/javascript.js';

import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/hint/javascript-hint'
import 'codemirror/addon/hint/html-hint'

import 'codemirror/addon/lint/lint.css'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/javascript-lint'
// import 'codemirror/addon/lint/html-lint'

import 'codemirror/addon/edit/closetag'
import 'codemirror/addon/edit/closebrackets'
import 'codemirror/addon/edit/matchtags'
import 'codemirror/addon/edit/matchbrackets'
import 'codemirror/addon/display/autorefresh'

window.JSHINT = JSHINT;

export const CODEMIRROR = {
    MODE: {
        HTML: 'htmlmixed',
        JS: 'javascript'
    }
}

export const CodeMirror = ({
                              inline = false,
                              label = '',
                              defaultValue = '',
                              hint = '',
                              errorMessage = '',
                              validMessage = '',
                              onChange = false,
                              mode = CODEMIRROR.MODE.HTML,
                              css = {},
                              ...rest
                          }) => {
    const {t} = useTranslation();
    const [value, setValue] = useState(defaultValue)

    useEffect(()=>{
        setValue(defaultValue);
    }, [defaultValue])

    const _onChange = useCallback((editor, data, value) => {
            setValue(value);
            onChange && typeof onChange === 'function' && onChange(value);
        },
        [onChange]
    )

    return (
        <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
            <div className="form-label">{label}</div>

            <_CodeMirror
                {...rest}
                value={value}
                options={{
                    esversion: 6,
                    gutters: ["CodeMirror-lint-markers"],
                    mode: mode,
                    theme: 'dracula',
                    lineNumbers: true,
                    lint: {esversion: 6},
                    lineWrapping: true,
                    spellcheck: true,
                    autoRefresh: true,
                    autoCloseTags: true,
                    autoCloseBrackets: true,
                    matchTags: true,
                    matchBrackets: true,
                }}
                onBeforeChange={_onChange}
                onChange={(editor, data, value) => {
                }}
            />

            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (typeof hint === 'string' ? <div className="form-hint" dangerouslySetInnerHTML={{__html: hint}}/> : <div className="form-hint">{hint}</div>)}
        </div>
    )
}
CodeMirror.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}

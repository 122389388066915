import React, {useState, Suspense, useEffect} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {SWRConfig} from 'swr'
import { ToastProvider } from 'react-toast-notifications';
import Layouts from './layouts'
import Routes from './routes'
import './css/tailwind.css'
import './css/animate.css'
import './css/main.css'
import './css/_components.css'
import {useDispatch} from "react-redux";
import {UserAction} from "./redux/reducers/user";
import ErrorBoundary from "./components/error.boundary";
import {SettingsAction} from "./redux/reducers/settings";
import {Loader} from "./components/loader";
import {useCookies} from "react-cookie";
import qs from "qs";


const Wrapper = ({children}) => {
    return (
        <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
                <Layouts>{children}</Layouts>
            </Suspense>
        </ErrorBoundary>
    )
}

const App = () => {
    const dispatch = useDispatch()
    const [isInited, onInit] = useState(false)
    const [cookies, setCookie] = useCookies(['ref_id'])

    useEffect(()=>{
        if (isInited) return;

        let urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        if(urlParams.rid){
            let dtInOneYear = new Date();
            dtInOneYear.setFullYear(new Date().getFullYear() + 1);
            setCookie('ref_id', urlParams.rid, {path: '/', expires: dtInOneYear,})
            setCookie('ref_id', urlParams.rid, {path: '/', expires: dtInOneYear, domain: `.${document.location.hostname.split('.').slice(1).join('.')}` })
        }

        dispatch(SettingsAction.loadSettings())
            .then(()=>{
                return Promise.all([
                    dispatch(UserAction.loadUserInfo()),
                ])
            })
            .then(() => onInit(true))
            .catch(() => onInit(true));
    }, [isInited])

    if(!isInited){
        return <div className={`w-screen h-screen flex items-center justify-center`}>
            <Loader />
        </div>;
    }

    return (
        <SWRConfig
            value={{
                // provider: () => new Map(), //for cache
                focusThrottleInterval: 30000,
            }}
        >
            <ToastProvider>
                <Router>
                    <Wrapper>
                        <Routes/>
                    </Wrapper>
                </Router>
            </ToastProvider>
        </SWRConfig>
    )
}
export default App

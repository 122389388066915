import React, {useEffect, useState, useRef, useCallback} from 'react'
import {Trans, useTranslation} from "react-i18next";
import {ModalInfo} from "./modal.info";
import Api from "../../service/api";
import FormGen from "../form/formgen2";
import {RiMoneyDollarCircleLine} from "react-icons/ri";
import {useDispatch} from "react-redux";
import {UserAction} from "../../redux/reducers/user";
import {useAppSelector} from "../../redux/store";
import {FiCheck} from "react-icons/fi";
import {useDashboard} from "../../service/api.swr";

function WithdrawForm({reloadUserInfo, onWithdrawCreated}){
    const {t} = useTranslation();
    const formRef = useRef()
    const {user} = useAppSelector();
    const [formFields, setFormFields] = useState([]);

    useEffect(()=>{
        reloadUserInfo()
    }, [])

    useEffect(()=>{
        setFormFields([
            {
                name: 'currency',
                type: 'hidden',
                defaultValue: 'usdttrc20'
            },
            {
                label: t('form.field.wallet_address'),
                error: {
                    required: t('form.error.required', {name: t('form.field.wallet_address')}),
                },
                defaultValue: '',
                name: 'wallet',
                type: 'text',
                placeholder: t('form.field.placeholder', {name: t('form.field.wallet_address')}),
                hint: t(`form.field.wallet_address_hint_usdt`),
            },
            {
                label: t('form.field.amount'),
                error: {
                    required: t('form.error.required', {name: t('form.field.amount')}),
                    min: {
                        value: 10,
                        message: t('form.error.min', {value: 10, name: t('form.field.amount')}),
                    },
                    max: {
                        value: user.balance_for_withdraw,
                        message: t('form.error.max', {value: user.balance_for_withdraw, name: t('form.field.amount')}),
                    }
                },
                defaultValue: 0,
                name: 'amount',
                type: 'text',
                // placeholder: t('form.field.placeholder', {name: t('form.field.amount_topup_placeholder')}),
                hint: <>
                    <div>Fee: 1% + 1$</div>
                    <div className={`flex flex-row gap-2 text-xs`}>
                        <div>Min: 10</div>
                        <div><span >Max:</span> ${user.balance_for_withdraw}</div>
                    </div>
                </>,
            },
        ])
    }, [user.balance_for_withdraw])

    const onSubmit = (formData, setError, setAlerts) => {
        return Api.getCSRFCookie().then(() => Api.paymentWithdraw(formData.currency, formData.amount, formData.wallet));
    }

    return (
        <>
            <div className={`flex flex-row px-3 py-3 my-2 mt-6 text-xl justify-center border border-gray-400 rounded-md`}>
                <img src={`/images/tether-usdt-logo.svg`} className={`w-6`} />
                <div className={`ml-3`}>USDT (TRC20)</div>
            </div>

            <FormGen ref={formRef}
                     items={formFields}
                // initialValues={{}}
                     onSubmit={onSubmit}
                     onComplete={onWithdrawCreated}
                     submitText={t(`modal.withdraw.btn_save`)}/>
        </>
    )
}

function WithdrawFormAdmin({onWithdrawCreated}){
    const {t} = useTranslation();
    const formRef = useRef()
    const {user} = useAppSelector();
    const [formFields, setFormFields] = useState([]);

    useEffect(()=>{
        setFormFields([
            {
                name: 'currency',
                type: 'hidden',
                defaultValue: 'usdttrc20'
            },
            {
                label: t('form.field.wallet_address'),
                error: {
                    required: t('form.error.required', {name: t('form.field.wallet_address')}),
                },
                defaultValue: '',
                name: 'wallet',
                type: 'text',
                placeholder: t('form.field.placeholder', {name: t('form.field.wallet_address')}),
                hint: t(`form.field.wallet_address_hint_usdt`),
            },
            {
                label: t('form.field.amount'),
                error: {
                    required: t('form.error.required', {name: t('form.field.amount')}),
                },
                defaultValue: 0,
                name: 'amount',
                type: 'text',
            },
            {
                label: t('form.field.reason'),
                error: {
                    required: t('form.error.required', {name: t('form.field.reason')}),
                },
                defaultValue: '',
                name: 'reason',
                type: 'text',
            },
        ])
    }, [])

    const onSubmit = (formData, setError, setAlerts) => {
        return Api.getCSRFCookie().then(() => Api.paymentWithdrawAdmin(formData.currency, formData.amount, formData.wallet, formData.reason));
    }

    return (
        <>
            <div className={`flex flex-row px-3 py-3 my-2 mt-6 text-xl justify-center border border-gray-400 rounded-md`}>
                <img src={`/images/tether-usdt-logo.svg`} className={`w-6`} />
                <div className={`ml-3`}>USDT (TRC20)</div>
            </div>

            <FormGen ref={formRef}
                     items={formFields}
                // initialValues={{}}
                     onSubmit={onSubmit}
                     onComplete={onWithdrawCreated}
                     submitText={t(`modal.withdraw.btn_save`)}/>
        </>
    )
}

function WithdrawSuccess({total, sent, fee, currency}){
    const {t} = useTranslation();
    currency = currency?.toUpperCase();
    return (
        <div className="relative p-4 w-full text-center">
            <span className="h-12 w-12 mx-auto my-4 bg-green-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
              <FiCheck size={18} className="stroke-current text-green-500" />
            </span>
            <div className="text-sm text-grey-500">
                {t(`modal.withdraw.complete.description`)}
            </div>

            <div className={`text-left mt-4`}>
                <div className="text-sm text-black">
                    {t(`modal.withdraw.complete.will_receive`)} <span className={`text-md font-bold`}>{sent}{` `}{currency}</span>
                </div>
                <div className="text-sm text-black">
                    {t(`modal.withdraw.complete.fee`)} <span className={`text-md font-bold`}>{fee}{` `}{currency}</span>
                </div>
                <div className="text-sm text-black">
                    {t(`modal.withdraw.complete.total`)} <span className={`text-md font-bold`}>{total}{` `}{currency}</span>
                </div>
            </div>

        </div>
    )
}

export const ModalWithdraw = ({
                                isAdmin = false,
                                 onConfirm,
                                 ...rest
                             }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const {mutate: mutateDashboard} = useDashboard();
    const [withdraw, setWithdraw] = useState(false);

    const reloadUserInfo = useCallback(()=>{
        Api.getUserInfo()
            .then((userInfo) => Promise.all([
                dispatch(UserAction.loadUserInfoCompleted(userInfo))
            ]));
    }, [])

    const onWithdrawCreated = useCallback((result)=>{
        setWithdraw(result);
        mutateDashboard();
        reloadUserInfo();
    }, [reloadUserInfo, mutateDashboard])

    useEffect(()=>{
        setWithdraw(false);
    }, [rest.isOpen])

    return (
        <ModalInfo
            isModal={true}
            maxWidth={`max-w-xs sm:max-w-lg md:max-w-xl lg:max-w-2xl`}
            icon={
                <span className="h-10 w-10 bg-blue-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    {/*<FiX size={18} className="stroke-current text-red-500"/>*/}
                    <RiMoneyDollarCircleLine className={`text-blue-500`} />
                </span>
            }
            title={t('modal.withdraw.title')}
            body={
                withdraw ? <WithdrawSuccess {...withdraw}  />
                    :   <div className="text-sm text-grey-500 pt-2">
                        {isAdmin ?
                            <WithdrawFormAdmin onWithdrawCreated={onWithdrawCreated} />
                            : <WithdrawForm onWithdrawCreated={onWithdrawCreated} reloadUserInfo={reloadUserInfo} />}
                        </div>
            }
            {...rest}
        />
    )
}



import React, {useEffect, useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import DropZone from "../../dropzone";
import {useTranslation} from "react-i18next";
import {FiTrash2} from 'react-icons/fi'
import {asset} from "../../../redux/reducers/settings";

const FilesPreview = ({files, onRemove}) => {
    if(!files) return null;

    let color = 'blue';
    return (
        <>
            {files && files.map((file, idx)=>{
                if(!file) return null;
                let name = file.name || (file.substring ? file.substring(file.lastIndexOf('/')+1) : 'error' );
                let fileUrl = typeof file === 'string' ? asset( file ) : false;
                URL.revokeObjectURL(file.preview);
                return <div key={name} className={"flex flex-row items-center"}>
                        <a {...fileUrl ? {href:fileUrl} : null} target={'_blank'}><span className={"font-bold mr-2"}>{name}</span></a>
                        <button onClick={(e)=>{e.preventDefault(); onRemove(idx)}} className={`btn btn-sm btn-icon bg-transparent hover:bg-${color}-50 text-${color}-500 hover:text-${color}-600`}>
                            <FiTrash2 className="stroke-current mr-2" />
                            <span >{"remove"}</span>
                        </button>
                    </div>
            })}
        </>
    )
}

export const FilesSimple = ({
                              inline = false,
                              label = '',
                              defaultValue: files = false,
                              maxFiles = false,
                              accept,
                              maxSizeMb = 5, //5mb
                              hint = '',
                              dropMessage = false,
                              errorMessage = '',
                              validMessage = '',
                              onRemove = false,
                              onDrop = false,
                              css = {},
                              filesPreview = <FilesPreview  />,
                              ...rest
                          }) => {
    const {t} = useTranslation();
    files = files && (typeof files === 'object' && files.length ? files : [files]);

    const _onRemove = useCallback((fileIdx) => {
            files.splice(fileIdx, 1);
            if(files.length > 0) onDrop && typeof onDrop === 'function' && onDrop(maxFiles === 1 ? files[0] : [...files]);
            else onRemove && typeof onRemove === 'function' && onRemove(rest.name);
        },
     [files, onRemove, onDrop]
    )
    const _onDrop = useCallback(acceptedFiles => {
            for(let i=0;i<acceptedFiles.length;i++){
                console.log('acceptedFiles '+i, acceptedFiles, URL.createObjectURL(acceptedFiles[i]));

                Object.assign(acceptedFiles[i], {
                    preview: URL.createObjectURL(acceptedFiles[i])
                })
            }

            if(maxFiles === 1){
                acceptedFiles = acceptedFiles[0];
            }else{
                acceptedFiles = [...(files && files.length ? files : []), ...acceptedFiles]
            }
            // acceptedFiles = [...(files && files.length ? files : []), ...(maxFiles === 1 ? [acceptedFiles[0]] : acceptedFiles)]
            onDrop && typeof onDrop === 'function' && onDrop(acceptedFiles);
        },
        [files, onDrop]
    )

    return (
        <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
            <div className="form-label">{label}</div>

            {(!maxFiles || !files || files.length < maxFiles) && <DropZone {...rest} options={{
                            accept: accept,
                            maxSize: maxSizeMb * 1000 * 1000,
                            maxFiles: maxFiles,
                            multiple: !maxFiles || maxFiles > 1,
                            onDrop: _onDrop,
                        }}
                         dropMessage={dropMessage || t('form.field.files_drop_message')}
                         size={{w: css?.w || 'full', h: css?.h || 40}}
                         css={{borderColor: errorMessage ? 'red' : undefined}}
                        />}

            <filesPreview.type {...filesPreview.props} files={files} onRemove={_onRemove} />

            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (typeof hint === 'string' ? <div className="form-hint" dangerouslySetInnerHTML={{__html: hint}}/> : <div className="form-hint">{hint}</div>)}
        </div>
    )
}
FilesSimple.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.string,
    maxFiles: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}


export const Files = ({
                           defaultValue,
                           onChange,
                           ...rest
                       }) => {
    const {t} = useTranslation();
    const [files, setFiles] = useState(defaultValue)

    const onChangeImages = useCallback((files) => {
            typeof onChange === 'function' && onChange(files);
            setFiles(files)
        },
        []
    )
    const onRemoveImage = useCallback((fieldName) => {
            typeof onChange === 'function' && onChange(null);
            setFiles(null);
        },
        []
    )

    return (
        <>
            <FilesSimple {...rest}
                   defaultValue={files}
                   onRemove={onRemoveImage}
                   onDrop={onChangeImages}
            />
        </>
    );
}

import React, {useEffect, useState, useRef, useCallback} from 'react'
import {Trans, useTranslation} from "react-i18next";
import {ModalInfo} from "./modal.info";
import Api from "../../service/api";
import FormGen from "../form/formgen2";
import {RiMoneyDollarCircleLine} from "react-icons/ri";
import {useDispatch} from "react-redux";
import {useDashboard} from "../../service/api.swr";
import {PiNewspaperDuotone} from "react-icons/pi";
import {HiMiniTicket} from "react-icons/hi2";

function Form({onComplete}){
    const {t} = useTranslation();
    const [formFields, setFormFields] = useState([]);
    const {dashboard, mutate} = useDashboard();

    useEffect(()=>{
        setFormFields([
            {
                label: t('form.field.promo_code_personal'),
                error: {
                    required: t('form.error.required', {name: t('form.field.promo_code')}),
                },
                defaultValue: '',
                name: 'promo_code',
                type: 'text',
                placeholder: t('form.field.placeholder', {name: t('form.field.promo_code')}),
            },
        ])
    }, [])

    const onSubmit = (formData, setError, setAlerts) => {
        return Api.getCSRFCookie().then(() => Api.promoCodeUpdate(formData.promo_code));
    }

    const _onComplete = useCallback((result)=>{
        mutate();
        onComplete && onComplete()
    }, [mutate, onComplete])

    return (
        <>
            <FormGen items={formFields}
                     initialValues={dashboard}
                     onSubmit={onSubmit}
                     onComplete={_onComplete}
                     submitText={t(`modal.promo_code.btn_save`)}/>
        </>
    )
}

export const ModalPromoCode = ({
                                isAdmin = false,
                                 onConfirm, onCancel,
                                 ...rest
                             }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch()

    return (
        <ModalInfo
            isModal={true}
            maxWidth={`max-w-xs sm:max-w-lg md:max-w-xl lg:max-w-2xl`}
            icon={
                <span className="h-10 w-10 bg-blue-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    {/*<FiX size={18} className="stroke-current text-red-500"/>*/}
                    <HiMiniTicket className={`text-blue-500`} />
                </span>
            }
            title={t('modal.promo_code.title')}
            description={<div className={`text-black mb-2`}>
                <p>{t('widget.referrals.promo_code_description')}</p>
                <p className={`mt-2`}>{t('widget.referrals.description')}</p>
            </div>}
            body={
                  <div className="text-sm text-grey-500 pt-2">
                    <Form onComplete={onCancel} />
                  </div>
            }
            onCancel={onCancel}
            {...rest}
        />
    )
}



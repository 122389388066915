//
// import React from 'react'
// import {Switch, useRouteMatch} from "react-router-dom";
// import List from "./list";
// import Update from "./update";
// import {PrivateRoute} from "../../routes";
//
// const Routes = () => {
//     let { path } = useRouteMatch();
//     return (
//         <Switch>
//             <PrivateRoute exact path={path} component={List} permission={'external_service list'} />
//             <PrivateRoute path={`${path}/add`} component={Update} permission={'external_service add'} />
//             <PrivateRoute path={`${path}/edit/:id`} component={Update} permission={'external_service edit'} />
//         </Switch>
//     )
// }
// export default Routes


import React, {useEffect, useState} from 'react'
import SectionTitle from '../../components/section-title'
import Widget from '../../components/widget'
import {useTranslation} from "react-i18next";
import {FiPackage} from 'react-icons/fi'
import {AiOutlineDollar} from 'react-icons/ai'
import {RiMoneyDollarCircleLine} from 'react-icons/ri'
import {GiPayMoney, GiReceiveMoney} from 'react-icons/gi'
import {IoApps, IoGlobeOutline, IoRocketOutline} from 'react-icons/io5'
import {OverlayLoader} from "../../components/loader";
import Api from "../../service/api";
import {LinkButton} from "../../components/button";
import {WidgetLatestFinances} from "../../components/widget/latest.finances";
import {useDashboard} from "../../service/api.swr";
import {WidgetReferrals} from "../../components/widget/referrals";
import {WidgetTeamsInfo} from "../../components/widget/teams";
import {useAppSelector} from "../../redux/store";
import {ModalTopUp} from "../../components/modals";
import {copyToClipboard} from "../../helper";
import {Action} from "../../components/datatable";
import {ModalWithdraw} from "../../components/modals/modal.withdraw";


const WidgetInfoCount = ({title, count, icon, linkTo, children}) => {
    const {t} = useTranslation();
    return (
        <Widget
            titleUppercase={true}
            title={title}
            description={<span className={`text-xl`}>{count || 0}</span>}
            right={
                <icon.type size={24} className="stroke-current text-grey-500" />
            }
            >
            {children}
            {linkTo &&
                <LinkButton title={t('dashboard.widget.manage')} to={linkTo}
                    className="mt-2 btn btn-sm btn-icon bg-transparent hover:bg-blue-50 text-blue-500 hover:text-blue-600 w-full">
                    </LinkButton>}
        </Widget>
    )
}

const WidgetBalance = ({}) => {
    const {t} = useTranslation();
    const {user} = useAppSelector();
    const [isTopUpModalVisible, setTopUpModalVisible] = useState(false)
    const [isWithdrawModalVisible, setWithdrawModalVisible] = useState(false)

    return <WidgetInfoCount title={t('widget.balance.title')} count={user.balance} icon={<AiOutlineDollar />}  >
        <div className={`mt-1 ${user.balance_in_hold && user.balance_in_hold > 0 ? `` : `hidden`}`}>
            {/*<div><small>{t('widget.balance.available')} <span>{user.balance - user.balance_in_hold}</span></small></div>*/}
            <div className={`text-grey-600`}>{t('widget.balance.in_hold')} <span>${user.balance_in_hold}</span></div>
        </div>
        <div className={`flex flex-row gap-2 mt-2`}>
            <Action className={`flex-1`} Icon={GiPayMoney}
                    onClick={()=>setTopUpModalVisible(true)}
                    title={t(`widget.balance.topup`)} />
            <Action className={`flex-1`} Icon={GiReceiveMoney}
                    onClick={()=>setWithdrawModalVisible(true)}
                    title={t(`widget.balance.withdraw`)}  />
        </div>

        <ModalTopUp
            isOpen={isTopUpModalVisible}
            onCancel={()=>setTopUpModalVisible(false)}
        />
        <ModalWithdraw
            isOpen={isWithdrawModalVisible}
            onCancel={()=>setWithdrawModalVisible(false)} />
    </WidgetInfoCount>
}

const WidgetTeams = ({count}) => {
    const {t} = useTranslation();
    return <WidgetInfoCount title={t('dashboard.widget.teams')} count={count} icon={<FiPackage />} linkTo={'/teams'} />
}

const WidgetAdCampaigns = ({count}) => {
    const {t} = useTranslation();
    return <WidgetInfoCount title={t('dashboard.widget.adcampaigns')} count={count} icon={<IoRocketOutline />} linkTo={'/adcampaigns'} />
}

const WidgetApps = ({count}) => {
    const {t} = useTranslation();
    return <WidgetInfoCount title={t('dashboard.widget.apps')} count={count} icon={<IoApps />} linkTo={'/apps'} />
}

const WidgetDomains = ({count}) => {
    const {t} = useTranslation();
    return <WidgetInfoCount title={t('dashboard.widget.domains')} count={count} icon={<IoGlobeOutline />} linkTo={'/domains'} />
}

const Index = () => {
    const {t} = useTranslation();
    const {settings} = useAppSelector()
    const {dashboard, isLoading, isError} = useDashboard();

    const widgetLgWidthClassName = settings.monetization_active ? 'lg:w-1/5' : 'lg:w-1/4';
    return (
        <OverlayLoader show={isLoading}>
            <SectionTitle title={t(`dashboard.title`)}
                          subtitle={ t(`dashboard.subtitle`)}/>
            <div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">
                {/*widget*/}
                {settings.monetization_active && <div className={`w-full ${widgetLgWidthClassName}`}>
                    <WidgetBalance />
                </div>}
                {/*widget*/}
                <div className={`w-full ${widgetLgWidthClassName}`}>
                    <WidgetTeams count={dashboard?.total?.teams} />
                </div>
                {/*widget*/}
                <div className={`w-full ${widgetLgWidthClassName}`}>
                    <WidgetDomains count={dashboard?.total?.domains} />
                </div>
                {/*widget*/}
                <div className={`w-full ${widgetLgWidthClassName}`}>
                    <WidgetAdCampaigns count={dashboard?.total?.adcampaigns} />
                </div>
                {/*widget*/}
                <div className={`w-full ${widgetLgWidthClassName}`}>
                    <WidgetApps count={dashboard?.total?.apps} />
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-4 w-full gap-2 mb-2 lg:mb-4">
                {/*widget*/}
                <WidgetTeamsInfo total={dashboard?.total?.teams} data={dashboard?.teams} className={`${dashboard?.teams?.length > 1 ? `lg:row-span-5` : ``}`} />
                {/*widget*/}
                {settings.referral_program_active && <WidgetReferrals promoCode={dashboard?.promo_code} total={dashboard?.referrals?.total} data={dashboard?.referrals?.last_5} className={`lg:col-span-3`} />}
                {/*widget*/}
                {settings.monetization_active && <WidgetLatestFinances data={dashboard?.last_finances} className={`${dashboard?.teams?.length > 1 ? `lg:col-span-3` : `lg:col-span-4`}`} />}
            </div>

            {/*<div className="flex flex-col lg:flex-row w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4">*/}
            {/*    /!*widget*!/*/}
            {/*    <div className="w-full lg:w-1/3">*/}
            {/*        <WidgetTeamsInfo total={dashboard?.total?.teams} data={dashboard?.teams} className={`h-full1`} />*/}
            {/*    </div>*/}
            {/*    /!*widget*!/*/}
            {/*    <div className="w-full lg:w-2/3">*/}
            {/*        <WidgetReferrals total={dashboard?.referrals?.total} data={dashboard?.referrals?.last_5} className={`h-full1`} />*/}

            {/*        <WidgetLatestFinances data={dashboard?.last_finances} />*/}
            {/*    </div>*/}
            {/*</div>*/}



        </OverlayLoader>
    )
}
export default Index

import React, {useMemo} from 'react'
import PropTypes from 'prop-types'

export const GroupBox = ({
                             className = '',
                             classNameContent = '',
                             inline = false,
                             label = '',
                             labelBold = false,
                             flex = {},
                             showBorder = false,
                             children,
                             ...rest
                         }) => {
    return (
        <div className={`form-element ${inline ? 'form-element-inline' : ''} ${className}`}>
            {label && <div className={`form-label ${labelBold? 'font-bold' : ''}`}>{label}</div>}
            <div className={`flex flex-${flex?.direction || 'row'} items-${flex?.items || 'stretch'} 
                    justify-${flex?.justify || 'start'} space-${flex?.space || 'x-2'}
                    ${showBorder && 'border-l border-grey-200 pl-4'} 
                    ${classNameContent}`}>
                {children}
            </div>
        </div>
    )
}
GroupBox.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

import React, {useCallback, useMemo, useState} from 'react'
import {Action} from "../datatable";
import {useTranslation} from "react-i18next";
import {MdCreditScore} from "react-icons/md";
import {ModalSubscriptionRenew, ModalSubscriptionUpdate} from "../modals";
import {hasPermission} from "../../redux/reducers/user";
import {useAppSelector} from "../../redux/store";
import {LinkButton} from "../button";
import {FaSkype, FaTelegram} from "react-icons/fa";

export function SubscriptionServices({services, usedServices}){
    const {t} = useTranslation();
    return (
        <>
        {Object.keys(services?.apps_count).map((appType, idx)=>{
                let serviceCount = usedServices?.apps_count && (usedServices.apps_count[appType] || 0);
                return (
                    <div key={appType} className={`flex text-xs`}>
                        <span className={`w-32 text-right`}>{t(`resource_page.app.field.app_type_${appType}`)}:</span>
                        &nbsp;
                        <span className={`font-semibold ${services?.apps_count[appType] > 0 && serviceCount >= services?.apps_count[appType] ? `text-red-500` : `` }`}>
                                {usedServices?.apps_count && `${serviceCount} / `}{services?.apps_count[appType] == -1 ? t(`resource_page.subscription.field.unlimited`) : services?.apps_count[appType]}
                            </span>
                    </div>
                )
            })}
        </>
    )
}

export function TeamSubscription({teamId, id: subscriptionId, is_active: isActive, title, date_end: dateEnd,  price, services, usedServices, onSubscriptionUpdate}){
    const {t} = useTranslation();
    const {user, settings} = useAppSelector();
    const [isModalSubscriptionUpdateVisible, setModalSubscriptionUpdateVisible] = useState(false);
    const [isModalSubscriptionRenewVisible, setModalSubscriptionRenewVisible] = useState(false);

    const daysToExpire = useMemo(()=>{
        if(!dateEnd) return 0;
        let difference = (new Date(dateEnd)).getTime() - (new Date()).getTime();
        return Math.ceil(difference / (1000 * 3600 * 24));
    }, [dateEnd])

    const updateSubscription = useCallback(()=>{
        setModalSubscriptionUpdateVisible(true);
    }, [])

    const renewSubscription = useCallback((teamId)=>{
        setModalSubscriptionRenewVisible(true);
    }, [])

    const onTeamSubscriptionUpdated = useCallback(()=>{
        setModalSubscriptionUpdateVisible(false)
        onSubscriptionUpdate && onSubscriptionUpdate();
    }, [teamId])

    const onTeamSubscriptionRenewed = useCallback(()=>{
        setModalSubscriptionRenewVisible(false)
        onSubscriptionUpdate && onSubscriptionUpdate();
    }, [teamId])

    return (
        <>
            {subscriptionId ?
            <div className={`my-2`}>
                <div className={`flex flex-row space-x-8`}>
                    <div className={`flex flex-col my-1`}>
                        <div className={`font-bold`}>{title}</div>
                        {settings.monetization_active && <div className={`text-sm font-medium`}>${price}</div>}
                    </div>
                    <div className={`flex flex-col my-1`}>
                        <div className={`${daysToExpire > 10 ? `text-green-500` : ( daysToExpire > 5 ? `text-orange-500` : `text-red-500`)} font-semibold uppercase`}>{isActive ? `ENDING ON` : `Expired`}</div>
                        <div className={`text-sm font-medium`}>{dateEnd}</div>
                    </div>
                </div>

                <div className={`my-2`}>
                    <SubscriptionServices services={services} usedServices={usedServices} />
                </div>

            </div> : <div className={`text-center p-4`}>
                    {t(`team_subscription.write_to_manager`)}

                    <div className={`pt-2 flex flex-row flex-wrap justify-center gap-4`}>
                        {settings.manager?.tg &&
                            <LinkButton to={`https://t.me/${settings.manager.tg}`} target={`_blank`} title={`@${settings.manager.tg}`} icon={<FaTelegram className="stroke-current mr-2"/>}
                                className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded btn-icon mb-4" />}
                        {settings.manager?.skype &&
                            <LinkButton to={`skype:${settings.manager.skype}?chat`} target={`_blank`} title={`@${settings.manager.skype}`} icon={<FaSkype className="stroke-current mr-2"/>}
                                className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded btn-icon mb-4" />}
                    </div>
            </div>}

            {subscriptionId &&
                <Action id={teamId}
                        title={t(`team_subscription.btn_renew`)} Icon={MdCreditScore} onClick={renewSubscription}  />}
            {hasPermission(user, ['team subscription_change']) &&
                <Action id={teamId}
                        title={t(`team_subscription.btn_update`)} Icon={MdCreditScore} onClick={updateSubscription}  />}

            <ModalSubscriptionUpdate
                isOpen={isModalSubscriptionUpdateVisible}
                teamId={teamId}
                onCancel={()=>setModalSubscriptionUpdateVisible(false)}
                onConfirm={onTeamSubscriptionUpdated}
            />
            {isModalSubscriptionRenewVisible && <ModalSubscriptionRenew
                isOpen={isModalSubscriptionRenewVisible}
                teamId={teamId}
                subscriptionTitle={title}
                subscriptionPrice={price}
                onCancel={()=>setModalSubscriptionRenewVisible(false)}
                onConfirm={onTeamSubscriptionRenewed}
            />}
        </>
    )
}

import React, {useEffect, useState, useRef, useCallback} from 'react'
import {Trans, useTranslation} from "react-i18next";
import {ModalInfo} from "./modal.info";
import Api from "../../service/api";
import {Loader, OverlayLoader} from "../loader";
import FormGen from "../form/formgen2";
import {useToasts} from "react-toast-notifications";
import {RiMoneyDollarCircleLine} from "react-icons/ri";
import {FiCopy} from "react-icons/fi";
import {copyToClipboard, getMinutesToDateFromNow} from "../../helper";
import {Action} from "../datatable";
import {useDashboard, usePaymentOrder} from "../../service/api.swr";
import {FINANCE} from "../../config/resources";
import {useDispatch} from "react-redux";
import {UserAction} from "../../redux/reducers/user";
import QRCodeStyling from "qr-code-styling";

function GetWallet({onWalletReceived}){
    const {t} = useTranslation();

    const [formFields, setFormFields] = useState([
        {
            name: 'currency',
            type: 'hidden',
            defaultValue: 'usdttrc20'
        },
    ], []);

    const onSubmit = (formData, setError, setAlerts) => {
        return Api.getCSRFCookie().then(() => Api.paymentGetWallet(formData.currency));
    }

    return (
        <>
            <div className={`flex flex-row px-3 py-3 my-2 mt-6 text-xl justify-center border border-gray-400 rounded-md`}>
                <img src={`/images/tether-usdt-logo.svg`} className={`w-6`} />
                <div className={`ml-3`}>USDT (TRC20)</div>
            </div>

            <FormGen items={formFields}
                // initialValues={{}}
                     onSubmit={onSubmit}
                     onComplete={onWalletReceived}
                     submitText={t(`modal.getwallet.btn_getwallet`)}/>
        </>
    )
}

function TopUpForm({onPaymentCreated}){
    const {t} = useTranslation();
    const formRef = useRef()
    const updateFieldAmount = useCallback((amount, e)=>{
        e.preventDefault();
        e.stopPropagation();
        formRef.current.setValue('amount', amount)
    }, [formRef])

    const [formFields, setFormFields] = useState([
        {
            name: 'currency',
            type: 'hidden',
            defaultValue: 'usdttrc20'
        },
        {
            label: t('form.field.amount'),
            error: {
                required: t('form.error.required', {name: t('form.field.amount')}),
                min: {
                    value: 10,
                    message: t('form.error.min', {value: 10, name: t('form.field.amount')}),
                }
            },
            defaultValue: 0,
            name: 'amount',
            type: 'text',
            placeholder: t('form.field.placeholder', {name: t('form.field.amount_topup_placeholder')}),
            hint: <div className={`flex flex-row gap-2 text-xs`}>
                {[200,500,1000,2500].map((amount, idx)=>{
                    return <a key={`${idx}:${amount}`} href={"#"} className={`link`}
                              onClick={(e)=>updateFieldAmount(amount, e)}>${amount}</a>
                })}
            </div>,
        },
    ], [formRef]);

    const onSubmit = (formData, setError, setAlerts) => {
        return Api.getCSRFCookie().then(() => Api.paymentTopUp(formData.currency, formData.amount));
    }

    return (
        <>
            <div className={`flex flex-row px-3 py-3 my-2 mt-6 text-xl justify-center border border-gray-400 rounded-md`}>
                <img src={`/images/tether-usdt-logo.svg`} className={`w-6`} />
                <div className={`ml-3`}>USDT (TRC20)</div>
            </div>

            <FormGen ref={formRef}
                     items={formFields}
                // initialValues={{}}
                     onSubmit={onSubmit}
                     onComplete={onPaymentCreated}
                     submitText={t(`modal.topup.btn_save`)}/>
        </>
    )
}

const qrCodePayAddress = new QRCodeStyling({
    "width": 150,
    "height": 150,
    "data": "",
    "margin": 0,
    "qrOptions": {
        "typeNumber": "0",
        "mode": "Byte",
        "errorCorrectionLevel": "Q"
    },
    "imageOptions": {
        "hideBackgroundDots": true,
        "imageSize": 0.5,
        "margin": 0
    },
    "dotsOptions": {
        "type": "dots",
        "color": "#000000",
        "gradient": null
    },
    "backgroundOptions": {
        "color": "#ffffff"
    },
    "image": "",
    "dotsOptionsHelper": {
        "colorType": {
            "single": true,
            "gradient": false
        },
        "gradient": {
            "linear": true,
            "radial": false,
            "color1": "#000000",
            "color2": "#000000",
            "rotation": "0"
        }
    },
    "cornersSquareOptions": {
        "type": "extra-rounded",
        "color": "#000000"
    },
    "cornersSquareOptionsHelper": {
        "colorType": {
            "single": true,
            "gradient": false
        },
        "gradient": {
            "linear": true,
            "radial": false,
            "color1": "#000000",
            "color2": "#000000",
            "rotation": "0"
        }
    },
    "cornersDotOptions": {
        "type": "dot",
        "color": "#000000",
        "gradient": null
    },
    "cornersDotOptionsHelper": {
        "colorType": {
            "single": true,
            "gradient": false
        },
        "gradient": {
            "linear": true,
            "radial": false,
            "color1": "#000000",
            "color2": "#000000",
            "rotation": "0"
        }
    },
    "backgroundOptionsHelper": {
        "colorType": {
            "single": true,
            "gradient": false
        },
        "gradient": {
            "linear": true,
            "radial": false,
            "color1": "#ffffff",
            "color2": "#ffffff",
            "rotation": "0"
        }
    }
});
function Payment({isStaticAddress, orderId, payAddress, payAmount, payCurrency, payStatus, expireTime}){
    const {t} = useTranslation();
    const [expireMinutes, setExpireMinutes] = useState(60);
    const [intervalId, setIntervalId] = useState(false);
    const {order, isLoading, isError} = usePaymentOrder(orderId, true);
    const dispatch = useDispatch()
    const ref = useRef(null);

    useEffect(() => {
        qrCodePayAddress.append(ref.current);
    }, []);

    useEffect(() => {
        qrCodePayAddress.update({
            data: payAddress
        });
    }, [payAddress]);

    useEffect(()=>{
        if(isStaticAddress) return;

        setExpireMinutes(getMinutesToDateFromNow(expireTime));
        let _intervalId = setInterval(()=>setExpireMinutes(getMinutesToDateFromNow(expireTime)), 1000);
        setIntervalId(_intervalId);

        return ()=>{
            clearInterval(intervalId);
            setIntervalId(false);
        }
    }, [isStaticAddress])

    useEffect(()=>{
        if(expireMinutes !== false) return;
        clearInterval(intervalId)
        setIntervalId(false);
    }, [expireMinutes, intervalId])

    //disable user balance reload, becourse bug with table refresh after user update
    // useEffect(()=>{
    //     if(!order?.status) return;
    //
    //     if(order?.status === FINANCE.STATUS.IN_PROGRESS) return;
    //     dispatch(UserAction.loadUserInfo());
    // }, [order?.status])

    return (
        <div className={`flex flex-col gap-4`}>

            {expireMinutes <= 0 && !isStaticAddress ? <div className={`text-black text-2xl text-left my-8 mr-12`}>
                    {t(`modal.topup.payment_expired`)}
                </div>
                :
                <div className={`flex flex-col gap-4`}>
                    <div className={`flex flex-col md:flex-row gap-4 md:gap-1 `}>
                        <div className={`flex flex-col gap-2`}>
                            <div>
                                <div className={`text-base`}>
                                    {isStaticAddress ? t(`modal.getwallet.static_wallet_description`) : t(`form.field.amount`)}
                                </div>
                                <div className={`text-lg text-black uppercase`}>
                                    {payAmount} {payCurrency}
                                </div>
                            </div>
                            <div>
                                <div className={`text-base`}>
                                    {t(`modal.topup.send_to_address`)}
                                </div>
                                <div className={`flex flex-row`}>
                                    <div className={`text-lg text-black break-all hover:cursor-pointer`} onClick={()=>copyToClipboard(payAddress)}>
                                        {payAddress}
                                    </div>
                                    <Action className={`ml-1 w-auto`} Icon={FiCopy} onClick={()=>copyToClipboard(payAddress)}  />
                                </div>
                                <div ref={ref} className={`mt-1`} />
                            </div>
                        </div>

                        {!isStaticAddress && expireMinutes &&
                            <div className={`flex flex-col text-center`}>
                                <span className={`text-black text-4xl`}>{expireMinutes}</span>
                                <span className={`text-xs`}>
                                    {t(`modal.topup.minutes_to_expire`)}
                                </span>
                            </div>}
                    </div>
                    {!isStaticAddress &&
                        <div className={`text-center py-2 flex flex-col items-center`}>
                            <div className={`uppercase text-base 
                                    ${order?.status === FINANCE.STATUS.COMPLETED && 'text-green-800'}
                                    ${order?.status === FINANCE.STATUS.ERROR && 'text-red-800'}
                                    `}>{order?.data?.payment_status || payStatus}</div>
                            {order?.status === FINANCE.STATUS.IN_PROGRESS && <Loader show={true} className={"my-2"} size={8} />}
                        </div>}
                </div>}
        </div>
    )
}

export const ModalTopUp = ({
                                 onConfirm,
                                 ...rest
                             }) => {
    const {t} = useTranslation();
    const [payment, setPayment] = useState(false);

    const onPaymentCreated = useCallback((_payment)=>{
        setPayment(_payment);
    }, [])

    useEffect(()=>{
        setPayment(false);
    }, [rest.isOpen])

    return (
        <ModalInfo
            isModal={true}
            maxWidth={`max-w-xs sm:max-w-lg md:max-w-xl lg:max-w-2xl`}
            icon={
                <span className="h-10 w-10 bg-blue-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    {/*<FiX size={18} className="stroke-current text-red-500"/>*/}
                    <RiMoneyDollarCircleLine className={`text-blue-500`} />
                </span>
            }
            title={t('modal.topup.title')}
            body={
                <div className="text-sm text-grey-500 pt-2">

                    {payment ? <Payment
                                    isStaticAddress={payment.is_static_address}
                                    orderId={payment.order_id}
                                    payAddress={payment.pay_address}
                                    payAmount={payment.pay_amount}
                                    payCurrency={payment.pay_currency}
                                    expireTime={payment.expiration_estimate_date}
                                    payStatus={payment.payment_status}
                            />
                        // : <TopUpForm onPaymentCreated={onPaymentCreated} />
                        : <GetWallet onWalletReceived={onPaymentCreated} />
                    }
                </div>
            }
            {...rest}
        />
    )
}



import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import {FiTrash2} from "react-icons/fi";
import Dropdown from './../dropdown'

export const TabAction = ({title, icon, dropdownContent, onClick}) => {
    const btn = <button className={`tab rounded-lg flex flex-row items-center justify-around`} onClick={onClick} >
        {icon} <span className={"ml-2"}>{title}</span>
    </button>
    return (
        <div className="flex-none">
            {dropdownContent ?
                <Dropdown
                    width="w-full"
                    button={btn}
                    dropdownToggleClass="btn btn-default btn-outlined btn-outlined-indigo">
                    {dropdownContent}
                </Dropdown> : btn}

        </div>
    )
}

export const VerticalTabs = ({className, activeTab = 0, tabs, action, onTabChange, onTabDelete}) => {
    const [openTab, setOpenTab] = useState(activeTab)

    const _onTabbChange = useCallback((tabIndex)=>{
        typeof onTabChange === 'function' && onTabChange(tabIndex);
        setOpenTab(tabIndex);
    }, [onTabChange])

    useEffect(()=>{
        setOpenTab(activeTab);
    }, [activeTab])

  return (
    <div className={`flex flex-row items-start justify-start tabs ${className}`}>
      <div className="flex-shrink-0">
        <div className="flex flex-wrap flex-col space-y-2">
            {tabs.map((tab, key) => (
                <div key={key} className="flex-none">
                    <button
                        onClick={() => {
                            _onTabbChange(tab.index)
                        }}
                        className={`tab tab-pill ${
                            openTab === tab.index ? 'tab-active' : ''
                        }`}
                        type="button">
                        {tab.title}
                        {typeof onTabDelete === 'function' && tab.canDelete !== false && <span onClick={(e)=>{
                            e.preventDefault();
                            e.stopPropagation();
                            onTabDelete(tab.index)
                        }} className={"ml-2 hover:bg-grey-50 pl-1"}><FiTrash2 /></span>}
                    </button>
                </div>
            ))}

            {action && <TabAction {...action} />}
        </div>
      </div>
      <div className="ml-0">
        {tabs.map((tab, key) => (
          <div
            className={`tab-content ${
              openTab !== tab.index ? 'hidden' : 'block'
            }`}>
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  )
}
VerticalTabs.propTypes = {
    activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onTabChange: PropTypes.func,
    onTabDelete: PropTypes.func,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            content: PropTypes.element,
            title: PropTypes.any
        })
    ).isRequired,
    action: PropTypes.shape({
        title:  PropTypes.string,
        icon: PropTypes.element,
        dropdownContent: PropTypes.element,
        onClick: PropTypes.func,
    })
}
//
// export const Pills = ({tabs}) => {
//   const [openTab, setOpenTab] = useState(0)
//   return (
//     <div className="flex flex-wrap flex-col w-full tabs">
//       <div className="flex lg:flex-wrap flex-row lg:space-x-2">
//         {tabs.map((tab, key) => (
//           <div key={key} className="flex-none">
//             <button
//               onClick={() => {
//                 setOpenTab(tab.index)
//               }}
//               className={`tab tab-pill ${
//                 openTab === tab.index ? 'tab-active' : ''
//               }`}
//               type="button">
//               {tab.title}
//             </button>
//           </div>
//         ))}
//       </div>
//       {tabs.map((tab, key) => (
//         <div
//           className={`tab-content ${
//             openTab !== tab.index ? 'hidden' : 'block'
//           }`}>
//           {tab.content}
//         </div>
//       ))}
//     </div>
//   )
// }
//
// Pills.propTypes = {
//   tabs: PropTypes.arrayOf(
//     PropTypes.shape({
//       index: PropTypes.number,
//       content: PropTypes.element,
//       title: PropTypes.any
//     })
//   ).isRequired
// }
//
// export const IconTabs = ({tabs}) => {
//   const [openTab, setOpenTab] = useState(0)
//   return (
//     <div className="flex flex-wrap flex-col w-full tabs">
//       <div className="flex lg:flex-wrap flex-row lg:space-x-2">
//         {tabs.map((tab, key) => (
//           <div key={key} className="flex-none">
//             <button
//               onClick={() => {
//                 setOpenTab(tab.index)
//               }}
//               className={`tab rounded-lg flex flex-row items-center justify-around ${
//                 openTab === tab.index ? 'tab-active' : ''
//               }`}
//               type="button">
//               {tab.title}
//             </button>
//           </div>
//         ))}
//       </div>
//       {tabs.map((tab, key) => (
//         <div
//           className={`tab-content ${
//             openTab !== tab.index ? 'hidden' : 'block'
//           }`}>
//           {tab.content}
//         </div>
//       ))}
//     </div>
//   )
// }
//
// IconTabs.propTypes = {
//   tabs: PropTypes.arrayOf(
//     PropTypes.shape({
//       index: PropTypes.number,
//       content: PropTypes.element,
//       title: PropTypes.any
//     })
//   ).isRequired
// }

export const UnderlinedTabs = ({className, activeTab = 0, tabs, action, onTabChange, onTabDelete}) => {
  const [tabsId, setTabsId] = useState(Date.now())
  const [openTab, setOpenTab] = useState(activeTab)

  const _onTabbChange = useCallback((tabIndex)=>{
      typeof onTabChange === 'function' && onTabChange(tabIndex);
      setOpenTab(tabIndex);
  }, [onTabChange])

  useEffect(()=>{
      setOpenTab(activeTab);
  }, [activeTab])

  return (
    <div className={`flex flex-wrap flex-col w-full tabs ${className}`}>
      <div className="flex lg:flex-wrap flex-row lg:space-x-2">
        {tabs.map((tab, key) => (
          <div key={key} className="flex-none">
            <button
              onClick={() => {
                  _onTabbChange(tab.index)
              }}
              className={`flex items-center ${
                  openTab === tab.index
                      ? 'tab tab-underline tab-active'
                      : 'tab tab-underline'                  
              }`}
              data-tabidx={key}
              data-tabsid={tabsId}
              type="button">
              {tab.title}
                {typeof onTabDelete === 'function' && tab.canDelete !== false && <span onClick={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    onTabDelete(tab.index)
                }} className={"ml-2 hover:bg-grey-50 pl-1"}><FiTrash2 /></span>}
            </button>
          </div>
        ))}

         {action && <TabAction {...action} />}
      </div>
      {tabs.map((tab, key) => (
        <div
          key={key}
          data-tabidx={key}
          data-tabsid={tabsId}
          className={`tab-content ${
            openTab !== tab.index ? 'hidden' : 'block'
          }`}>
          {tab.content}
        </div>
      ))}
    </div>
  )
}
UnderlinedTabs.propTypes = {
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onTabChange: PropTypes.func,
  onTabDelete: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      content: PropTypes.element,
      title: PropTypes.any
    })
  ).isRequired,
  action: PropTypes.shape({
      title:  PropTypes.string,
      icon: PropTypes.element,
      dropdownContent: PropTypes.element,
      onClick: PropTypes.func,
  })
}

// export const DefaultTabs = ({tabs}) => {
//   const [openTab, setOpenTab] = useState(0)
//   return (
//     <div className="flex flex-wrap flex-col w-full tabs">
//       <div className="flex lg:flex-wrap flex-row lg:space-x-2">
//         {tabs.map((tab, key) => (
//           <div key={key} className="flex-none">
//             <button
//               onClick={() => {
//                 setOpenTab(tab.index)
//               }}
//               className={`tab ${openTab === tab.index ? 'tab-active' : ''}`}
//               type="button">
//               {tab.title}
//             </button>
//           </div>
//         ))}
//       </div>
//       {tabs.map((tab, key) => (
//         <div
//           className={`tab-content ${
//             openTab !== tab.index ? 'hidden' : 'block'
//           }`}>
//           {tab.content}
//         </div>
//       ))}
//     </div>
//   )
// }
// DefaultTabs.propTypes = {
//   tabs: PropTypes.arrayOf(
//     PropTypes.shape({
//       index: PropTypes.number,
//       content: PropTypes.element,
//       title: PropTypes.any
//     })
//   ).isRequired
// }

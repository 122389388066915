import React from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {NotificationContainer} from 'react-notifications'
import {NavigationLoader} from './../../components/loader'
import LeftSidebar from './../../components/left-sidebar'
import Navbar from './../../components/navbar'
import Backdrop from './../../components/backdrop'

import './styles.css'

const Main = ({children}) => {
    const {uiConfig} = useSelector(
        (state) => ({
            uiConfig: state.uiConfig,
        }),
        shallowEqual
    )
    const {layout, collapsed, backgroundTheme, navbarTheme, leftSidebarTheme} = {...uiConfig}

    return (
        <div
            data-layout={layout}
            data-collapsed={collapsed}
            data-background={backgroundTheme}
            data-navbar={navbarTheme}
            data-left-sidebar={leftSidebarTheme}
            className={`${backgroundTheme === 'dark' ? 'dark-mode' : 'default-mode'}`}>
            <NavigationLoader/>
            <Backdrop/>
            <NotificationContainer/>
            <div className="wrapper">
                <LeftSidebar/>
                <div className="main w-full bg-grey-50 text-grey-900 dark:bg-grey-900 dark:text-white">
                    <Navbar/>
                    <div className="min-h-screen w-full p-4">{children}</div>
                </div>
            </div>
        </div>
    )
}
export default Main

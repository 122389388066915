import React from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import AuthLayout from './layout'
import SignIn from '../../components/form/auth/signin'
// import SocialMedia from '../../components/login-3/social-media'
import {useTranslation} from 'react-i18next';
import {useAppSelector} from "../../redux/store";

const Index = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const {settings} = useAppSelector()
    const {isLoggedIn} = useSelector(
        state => ({
            isLoggedIn: state.user.isLoggedIn
        }),
        shallowEqual
    )
    if(isLoggedIn){
        setTimeout(()=>history.push("/dashboard"), 1);
    }

    return (
        <AuthLayout imgUrl="/pages/auth/illustration.svg"
                    title={t('auth.signin.title', {projectName: settings.project_name})}
                    description={t('auth.signin.description')}>
            <SignIn />
            <div className="mt-4 mb-2">
                {/*<SocialMedia />*/}
            </div>
            <div className="flex flex-row w-full">
                <span className="text-secondary mr-1">{t('auth.newuser')}</span>
                <span>
              <Link className="link" to="/auth/signup">
                {t('auth.create_account')}
              </Link>
            </span>
            </div>
            <div className="w-full">
            <span>
              <Link className="link" to="/auth/forgot-password">
                {t('auth.forgotpassword')}
              </Link>
            </span>
            </div>
        </AuthLayout>
    )
}

export default Index

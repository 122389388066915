import React, {useState, useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {useTranslation} from "react-i18next";

const DropZone = ({
                    size = {h: 32, w: 'full'},
                    options = {},
                    dropMessage = false,
                    css,
                    onChange}) => {
    const {t} = useTranslation();
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        maxSize: 10 * 1000 * 1000, //10mb
        ...options,
    })

    if(!dropMessage) dropMessage = t('form.field.drop_message');

    let className = `border-dashed border-4 border-${css?.borderColor || 'grey'}-200 dark:border-${css?.borderColor || 'grey'}-600 flex items-center justify-center p-8`;
    for(let sizeKey in size){
        if(!size.hasOwnProperty(sizeKey)) continue;
        className += ` ${sizeKey}-${size[sizeKey]}`;
    }

    return (
        <>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={`${
                      isDragActive ? 'bg-grey-100 dark:bg-grey-800' : 'bg-grey-50 dark:bg-grey-700'
                  } ${className} `}>
              <div className="font-normal">{dropMessage}</div>
            </div>
          </div>
        </>
    )
}

export default DropZone

import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {UnderlinedTabs, VerticalTabs} from "../../tabs";

export const TABS_TYPE = {
    UNDERLINED: 'underlined',
    VERTICAL: 'vertical',
}

export const Tabs = ({
                             className = '',
                             tabtype = TABS_TYPE.UNDERLINED,
                             ...rest
                         }) => {
    const props = {
        className: className,
        ...rest,
    }
    switch (tabtype){
        case TABS_TYPE.VERTICAL:
            return <VerticalTabs {...props} />
        default:
            return <UnderlinedTabs {...props} />
    }
}
Tabs.propTypes = {
    ...UnderlinedTabs.propTypes,
    className: PropTypes.string,
    tabtype: PropTypes.string,
}

import React from 'react'
import {
    FiPackage,
    FiHome,
    FiUsers,
    FiUserCheck,
    FiLink, FiBriefcase,
    FiSettings,
} from 'react-icons/fi'
import {IoApps, IoGlobeOutline, IoRocketOutline} from 'react-icons/io5'
import {AiOutlineBarChart, AiOutlineNotification} from 'react-icons/ai'
import Immutable from "seamless-immutable";

export const INITIAL_STATE = Immutable([
    {
        title: 'nav_menu.section_menu.title',
        items: [
            {
                url: '/dashboard',
                icon: <FiHome size={20}/>,
                title: 'nav_menu.section_menu.dashboard',
                items: []
            },
            {
                url: '/teams',
                icon: <FiPackage size={20}/>,
                title: 'nav_menu.section_menu.teams',
                permission: 'team list',
                items: []
            },
            {
                url: '/extservices',
                icon: <FiLink size={20}/>,
                title: 'nav_menu.section_menu.external_services',
                permission: 'external_service list',
                items: []
            },
            {
                url: '/domains',
                icon: <IoGlobeOutline size={20}/>,
                title: 'nav_menu.section_menu.domains',
                permission: 'domain list',
                items: []
            },
            {
                url: '/adcampaigns',
                icon: <IoRocketOutline size={20}/>,
                title: 'nav_menu.section_menu.adcampaigns',
                permission: 'adcampaign list',
                items: []
            },
            {
                url: '/apps',
                icon: <IoApps size={20}/>,
                title: 'nav_menu.section_menu.apps',
                permission: 'app list',
                items: []
            },
            {
                url: '/statistics',
                icon: <AiOutlineBarChart size={20}/>,
                title: 'nav_menu.section_menu.statistics.section_title',
                // permission: 'statistics list',
                items: [
                    // {
                    //     url: '/statistics/general',
                    //     icon: false,
                    //     title: 'nav_menu.section_menu.statistics.general',
                    //     permission: 'statistics general',
                    //     items: []
                    // },
                    {
                        url: '/statistics/teams',
                        icon: false,
                        title: 'nav_menu.section_menu.statistics.teams',
                        permission: 'statistics teams',
                        items: []
                    },
                    {
                        url: '/statistics/top100',
                        icon: false,
                        title: 'nav_menu.section_menu.statistics.top100',
                        permission: 'statistics top100',
                        items: []
                    },
                    {
                        url: '/statistics/app',
                        icon: false,
                        title: 'nav_menu.section_menu.statistics.app',
                        permission: 'statistics app',
                        items: []
                    },
                ]
            },
        ]
    },
    {
        title: 'nav_menu.section_push.title',
        items: [
            {
                url: '/push/newsletters',
                icon: <AiOutlineNotification size={20}/>,
                title: 'nav_menu.section_push.notifications',
                permission: 'pushnewsletter list',
                items: []
            },
        ]
    },
    {
        title: 'nav_menu.section_utils.title',
        items: [
            {
                url: '/utils/warmingup',
                icon: <FiHome size={20}/>,
                title: 'nav_menu.section_utils.warmingup',
                permission: 'utils_warmingup list',
                items: []
            },
        ]
    },
    {
        title: 'nav_menu.section_farm.title',
        items: [
            {
                url: '/farm/accounts',
                icon: <FiHome size={20}/>,
                title: 'nav_menu.section_farm.accounts',
                permission: 'farm_account list',
                items: []
            },
        ]
    },
    {
        title: 'nav_menu.section_list.title',
        items: [
            {
                url: '/list/gpusers',
                icon: <FiHome size={20}/>,
                title: 'nav_menu.section_list.gpusers',
                permission: 'list_gp_user list',
                items: []
            },
            {
                url: '/list/gpcomments',
                icon: <FiHome size={20}/>,
                title: 'nav_menu.section_list.gpcomments',
                permission: 'list_gp_comment list',
                items: []
            },
        ]
    },
    {
        title: 'nav_menu.section_user.title',
        items: [
            {
                url: '/user',
                icon: <FiUsers size={20}/>,
                title: 'nav_menu.section_user.profile',
                permission: '',
                items: []
            },
        ]
    },
    {
        title: 'nav_menu.section_admin.title',
        role: 'admin',
        items: [
            {
                url: '/admin/finance',
                icon: <FiUsers size={20}/>,
                title: 'nav_menu.section_admin.finance',
                permission: 'project_finance view',
                items: []
            },
            {
                url: '/admin/users',
                icon: <FiUsers size={20}/>,
                title: 'nav_menu.section_admin.users',
                permission: 'user list',
                items: []
            },
            {
                url: '/admin/roles',
                icon: <FiUserCheck size={20}/>,
                title: 'nav_menu.section_admin.roles',
                permission: 'role list',
                items: []
            },
            {
                url: '/admin/subscriptions',
                icon: <FiBriefcase size={20}/>,
                title: 'nav_menu.section_admin.subscriptions',
                permission: 'subscription list',
                items: []
            },
            {
                url: '/admin/settings',
                icon: <FiSettings size={20}/>,
                title: 'nav_menu.section_admin.settings',
                permission: 'project_settings edit',
                items: []
            },
        ]
    },

]);

export const NavigationMenuAction = {
    updateMenuByUser: (userPermissions) => ({
        type: 'UPDATE_MENU_BY_USER',
        userPermissions: userPermissions,
    }),
}


function prepareMenu(menu, currentValue, userPermissions){
    let menuItems = currentValue.items.reduce((subMenu, subCurrentValue)=>{
        if(!subCurrentValue.permission || subCurrentValue.permission.length === 0 || userPermissions.includes(subCurrentValue.permission)){
            if(subCurrentValue.items && subCurrentValue.items.length > 0){
                subMenu = prepareMenu(subMenu, subCurrentValue, userPermissions);
            } else subMenu.push(subCurrentValue);
        }
        return subMenu;
    }, []);
    if(menuItems.length > 0) menu.push(currentValue.merge({items: menuItems}));

    return menu;
}
function updateMenuByUser(state, action) {
    const {userPermissions} = action;
    state = state.reduce((menu, currentValue)=>{
        return prepareMenu(menu, currentValue, userPermissions);
    }, []);

    return Immutable(state);
}


export default function navigationMenu(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'UPDATE_MENU_BY_USER':
            return updateMenuByUser(INITIAL_STATE, action);
        default:
            return state
    }
}

import React, {useState, useEffect, useRef} from 'react'
import {usePopper} from 'react-popper'
import './styles.css'



export const DropdownContent = ({title, items, onSelect}) => {
  return (
      <>
        {title && <div className="dropdown-title">{title}</div>}
        <div className="flex flex-wrap text-center">
          {items.map((item, i) => (
              <div
                  key={i}
                  onClick={()=>onSelect(item.id)}
                  className="w-12 flex flex-col items-center justify-center h-20 space-y-1 dropdown-item cursor-pointer">
                {item.icon}
                <span className="text-xs">{item.title}</span>
              </div>
          ))}
        </div>
      </>
  )
}

const Dropdown = ({
  placement = 'bottom-start',
  button,
  children,
  width = 'w-full'
}) => {
  const [hidden, setHidden] = useState(true)

  const buttonRef = useRef(null)
  const dropdownRef = useRef(null)

  const {styles, attributes} = usePopper(
    buttonRef.current,
    dropdownRef.current,
    {
      placement: placement,
    }
  )

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false
      }
      setHidden(!hidden)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [hidden, dropdownRef, buttonRef])

  const handleDropdownClick = (e) => {
    e.preventDefault();
    setHidden(!hidden)
  }

  return (
    <div className="hidden lg:flex relative">
      <button.type {...button.props}
        ref={buttonRef}
        onClick={handleDropdownClick}
        />
      <div ref={dropdownRef} style={{...styles.popper, ...{width: '30rem'}}} {...attributes.popper}>
        <div
          style={styles.offset}
          className={`dropdown ${hidden ? '' : 'open'}`}>
          <div className={`dropdown-content ${width} ${placement}`}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dropdown

import React from 'react'
import PropagateLoader from "react-spinners/PropagateLoader";
import {getColor} from "../../helper/colors";


export const Loader = ({show, className = '', size=15, color = getColor('text-light-blue-700')}) => {
  //getColor('text-blue-grey-700')
  return (
      <div style={{height: `${size}px`, width: `${size * 13.5}px`, textAlign: 'center'}} className={className}>
        <PropagateLoader color={color} loading={show} size={size} />
      </div>
  )
}


import React from 'react'
import FormGen from '../formgen2'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {UserAction} from "../../../redux/reducers/user";

const SignIn = ({onComplete}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = (formData, setError, setAlerts) => {
    return dispatch(UserAction.signIn(formData));
  }
  let items = [
    {
      label: t('auth.field.email'),
      error: {required: t('form.error.required', {name: t('auth.field.email')})},
      name: 'email',
      type: 'email',
      autoComplete: "username",
      placeholder: t('form.field.placeholder', {name: t('auth.field.email')})
    },
    {
      label: t('auth.field.password'),
      error: {
        required: t('form.error.required', {name: t('auth.field.password')}),
        minLength: {
          value: 8,
          message: t('form.error.minlength', {count: 8, name: t('auth.field.password')}),
        },
        maxLength: {
          value: 50,
          message: t('form.error.maxlength', {count: 50, name: t('auth.field.password')}),
        }
      },
      name: 'password',
      type: 'password',
      autoComplete: "current-password",
      placeholder: t('form.field.placeholder', {name: t('auth.field.password')})
    },
  ]
  return (
    <>
      <div className="flex flex-col">
        <FormGen items={items}
                 onSubmit={onSubmit}
                 submitText={t('auth.signin.submit_button')}
                 onComplete={onComplete}
        />
      </div>
    </>
  )
}

export default SignIn

import React from 'react'
import FormGen from '../formgen2'
import {useTranslation} from "react-i18next";
import Api from "../../../service/api";

const ForgotPassword = ({onComplete}) => {
    const {t} = useTranslation();

    const onSubmit = (formData, setError, setAlerts) => {
        return Api.getCSRFCookie().then(() => Api.forgotPassword(formData.email));
    }
    let items = [
        {
            label: t('auth.field.email'),
            error: {required: t('form.error.required', {name: t('auth.field.email')})},
            name: 'email',
            type: 'email',
            placeholder: t('form.field.placeholder', {name: t('auth.field.email')})
        },
    ]
    return (
        <>
            <div className="flex flex-col">
                <FormGen items={items}
                         onSubmit={onSubmit}
                         onComplete={onComplete}
                         submitText={t('auth.forgot_password.submit_button')}/>
            </div>
        </>
    )
}

export default ForgotPassword

import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {FiUser, FiLogIn} from 'react-icons/fi'
import {UserAction} from "../../redux/reducers/user";
import {useDispatch} from "react-redux";
import {RiMoneyDollarCircleLine} from "react-icons/ri";
import {ModalTopUp} from "../modals";
import {useAppSelector} from "../../redux/store";

const AccountLinks = () => {
    const dispatch = useDispatch();
    const {settings} = useAppSelector();
    const [isTopUpModalVisible, setTopUpModalVisible] = useState(false)
    const items = [
        settings.monetization_active && {
            url: '#',
            icon: <RiMoneyDollarCircleLine size={18} className="stroke-current"/>,
            name: 'Top Up',
            badge: null,
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                setTopUpModalVisible(true)
            }
        },
        {
            url: '/user/profile',
            icon: <FiUser size={18} className="stroke-current"/>,
            name: 'Profile',
            badge: null,
            onClick: null
        },
        {
            url: '/auth/logout',
            icon: <FiLogIn size={18} className="stroke-current"/>,
            name: 'Logout',
            badge: null,
            onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(UserAction.logout());
            }
        }
    ]

    return (
        <div className="flex flex-col w-full">
            <ul className="list-none">
                {items.filter(item => item).map((item, i) => (
                    <li key={i} className="dropdown-item">
                        <Link
                            onClick={item.onClick}
                            to={item.url}
                            className="flex flex-row items-center justify-start h-10 w-full px-2">
                            {item.icon}
                            <span className="mx-2">{item.name}</span>
                            {item.badge && (
                                <span
                                    className={`uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full ${item.badge.color} ml-auto`}>
                  {item.badge.number}
                </span>
                            )}
                        </Link>
                    </li>
                ))}
            </ul>

            <ModalTopUp
                isOpen={isTopUpModalVisible}
                onCancel={()=>setTopUpModalVisible(false)}
                // onConfirm={onTeamSubscriptionUpdated}
            />
        </div>
    )
}

export default AccountLinks

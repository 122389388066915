import React, {useCallback, useMemo, useState, useEffect, useRef, forwardRef} from 'react'
import {useForm, Controller} from 'react-hook-form'
import Alert from '../../alert'
import * as R from "ramda";
import getField, {getItemDefaultValue} from './get.field'
import {useTranslation} from "react-i18next";
import {Loader} from "./../../loader"

function getErrorObjKeysWithDot(obj, keys=[], prevKey = ''){
    Object.keys(obj).map((objKey, idx)=>{
        if(typeof obj[objKey] === 'object' && obj[objKey].ref){
            return keys.push(`${prevKey}${objKey}`);
        }

        return getErrorObjKeysWithDot(obj[objKey], keys, `${prevKey}${objKey}.`);
    })

    return keys;
}

function prepareFormDataBeforeSubmit(formData, item, prepareValueFunc = (value)=>{}){
    let path = item.name.split('.');
    let itemValue = R.path(path, formData);

    formData = R.assocPath(
        path,
        prepareValueFunc(itemValue, item),
        formData
    );

    if(item.type !== 'checkbox'){
        path[path.length-1] = path[path.length-1]+'_raw';
        formData = R.assocPath(
            path,
            itemValue,
            formData
        );
    }

    // console.log('preparedFoormData', formData)
    return formData;
}



function getOnlyItemsData(allItemsData, item){
    if (item.type === 'groupbox') {
        item.fields && item.fields.map((boxItem, i) => getOnlyItemsData(allItemsData, boxItem));
    }else if (item.type === 'flexgrid') {
        item.columns && item.columns.map(
            ({fields}, idx, allColumns) => fields.map(
                (_field, idx, allColumns) => getOnlyItemsData(allItemsData, _field)
            )
        );
    }else if (item.type === 'tabs') {
        item.tabs && item.tabs.map(
            ({content}, idx, allColumns) => content.map(
                (_field, idx, allColumns) => getOnlyItemsData(allItemsData, _field)
            )
        );
    }else if (item.type === 'multilangbox' || item.type === 'activeitems') {
        item.fields && item.fields.map((_field, idx, allFields) => getOnlyItemsData(allItemsData, _field));
    }
    else{
        allItemsData.push(item);
    }

    return allItemsData;
}


const FormGen = forwardRef(({items, initialValues = false, submitText, onSubmit, onComplete, alertErrors = []}, ref) => {
    const {t} = useTranslation();
    const {handleSubmit, register, unregister, watch, setError, clearErrors, setValue, getValues, formState: { errors }, control, reset, trigger} = useForm({
        // shouldUnregister: false
    })
    const [alerts, setAlerts] = useState(alertErrors)
    const [isLoading, setIsLoading] = useState(false)
    const btnSubmitRef = useRef()

    //получаем списиок всех полей с иименами без лишних визуальных компонентов ии вложеностей.
    let itemsFields = useMemo(()=>{
        return items.reduce(getOnlyItemsData, []);
    }, [items])

    const onSubmitFn = useCallback(async formData => {
        setIsLoading(true);
        //prepare form data for external use
        itemsFields.map((item, idx, allItems)=>{
            if(item.type === 'reactselect'){
                let getVal = (_item)=>typeof _item === 'string' ? _item : _item?.value;
                formData = prepareFormDataBeforeSubmit(
                    formData,
                    item,
                    (value, prepareItem)=>!prepareItem.isMulti ? getVal(value) : (value?.length > 0 ? value.map(getVal) : [])
                );
            }else if(item.type === 'checkbox'){
                formData = prepareFormDataBeforeSubmit(formData, item,
                    (value)=>value ? 1 : 0
                );
            }

        })

        console.log('FormGen.onSubmitFn', {...formData});
        setAlerts([]);
        document.querySelectorAll('.tab').forEach((elem, idx)=>{
            elem.classList.remove('tab-error');
        })

        if(!onSubmit) return setIsLoading(false);

        onSubmit(formData, setError, setAlerts)
            .then((result) => {
                setIsLoading(false);
                console.log('onSubmit result', result);

                if (onComplete) onComplete(result, formData);
            }, (error) => {
                console.log('Error response', error);
                setIsLoading(false);
                if (error) {
                    setAlerts({...(!error.validation_errors ? {0: error.message} : {}), ...error.validation_errors});
                    if (error && error.validation_errors) {
                        let apiErrors = error.validation_errors;
                        for (let k in apiErrors) {
                            if (!apiErrors.hasOwnProperty(k)) continue;
                            setError(k, {
                                type: "api",
                                message: apiErrors[k][0]
                            });
                        }
                    }
                }
            });
    }, [itemsFields])
    const onErrorFn = useCallback(errors => {
        console.log('onErrorFn', errors);
        setAlerts({checkform: t('form.error.checkform')});

        let fieldsKeys = getErrorObjKeysWithDot(errors);
        document.querySelectorAll('.tab').forEach((elem, idx)=>{
            let selector = fieldsKeys.map((fieldKey, fieldIdx)=>{
                return `.tab-content[data-tabidx="${elem.dataset.tabidx}"][data-tabsid="${elem.dataset.tabsid}"] [name="${fieldKey}"]`;
            })
            if(document.querySelectorAll(selector.join(', ')).length > 0){
                elem.classList.add('tab-error');
            }
        })
    }, [])

    let formFieldProps = {
        register: register,
        unregister: unregister,
        reset: reset,
        watch: watch,
        setError: setError,
        clearErrors: clearErrors,
        setValue: setValue,
        getValues: getValues,
        control: control,
        formErrors: errors,
        formInitialValues: initialValues,
    }

    if(ref) ref.current = formFieldProps;

    // const allWatches = watch();
    itemsFields = useMemo(()=>{
        return itemsFields.map(item => {
            if(item.validation && !item.error) item.error = item.validation;
            if (item.error?.validate?.type === 'sameasfield') {
                const validate = item['error'].validate;
                item['error'].validate = (value) => value === watch(validate.field) || validate.message;
            }

            if(item.watch && !item.watching){
                // let watchCl = useCallback(
                //     (data, field) => item.watch(item, data, field),
                //     [item]
                // );
                item.watching = true;
                watch((data, field) => item.watch(item, data, field, items, formFieldProps));
            }

            return item
        })
    }, [itemsFields, items, /*allWatches*/])

    useEffect(() => {
        // console.log('R.pick(R.pluck(\'name\', items), initialValues)', itemsFields,  initialValues)
        // console.log('itemsFields', itemsFields, initialValues);
        if(initialValues) {
            let objDefValues = {...getValues()};
            itemsFields.forEach((item, idx)=>{
                if(!item.name) return;
                let defVal = getItemDefaultValue(item, initialValues);
                if(defVal == null) return;

                let path = item.name.split('.');
                objDefValues = R.assocPath(
                    path,
                    defVal,
                    objDefValues
                );
            })
            // console.log('objDefValues', itemsFields, initialValues, getValues(), objDefValues);
            reset(objDefValues);


            // let objDefValues = Object
            //                     .entries( R.pick(R.pluck('name', itemsFields), initialValues) )
            //                     .reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {})
            // reset({...getValues(),  ...objDefValues } );
            // console.log('getValues()', initialValues, R.pluck('name', itemsFields), getValues(), objDefValues, {...getValues(),  ...objDefValues });
        }
    }, [initialValues, itemsFields]);

    formFieldProps.formInitialValues = initialValues;

    const checkKeyDown = (e) => {
        const keyCode = e.keyCode ? e.keyCode : e.which;
        if(e.target.nodeName.toLowerCase() === 'textarea') return true;
        if (keyCode === 13) {
            e.preventDefault();
            btnSubmitRef.current && btnSubmitRef.current.click();
        }
    };
    return (
        <form
            onSubmit={(e)=>{
                e.preventDefault();
                e.stopPropagation();
                clearErrors();
                handleSubmit(onSubmitFn, onErrorFn)(e);
            }}
            onKeyDown={(e) => checkKeyDown(e)}
            className="form flex flex-wrap w-full">
            {alerts &&
                (alerts.hasOwnProperty('map') ? alerts : Object.values(alerts)).map((item, i) => {
                return (
                    <div key={i + item} className="flex flex-col w-full">
                        <div className="mb-2" key={i}>
                            <Alert
                                color="bg-transparent border-red-500 text-red-500 mb-2"
                                borderLeft
                                raised>
                                {item}
                            </Alert>
                        </div>
                    </div>
                )
            })}
            <div className="w-full">
                {items.map((item, i) => {
                    return getField(formFieldProps, errors, initialValues, item, i);
                })}
            </div>
            {isLoading ? <Loader show={true} className={"mb-4"} /> : <input ref={btnSubmitRef}
                type="submit"
                className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded mb-4"
                value={submitText}
            />}
        </form>
    )
})
export default FormGen

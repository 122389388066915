import React from 'react'
import {Modal} from './modal'
import {FiFileText, FiX} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {CODEMIRROR, CodeMirror} from "../form/field/codemirror";

export const ModalInfo = ({
                                 title, description, icon,
                                 buttonTitle, buttonClassName,
                                 body,
                                 onCancel, onConfirm,
                                 ...rest
                             }) => {
    return (
        <Modal {...rest} onClose={onCancel}>
            <div className="relative p-4 flex-auto">
                <div className="flex items-start justify-start p-2 space-x-4">
                    <div className="flex-shrink-0 w-12">{icon}</div>
                    <div className="flex flex-col w-full">
                        <div className="text-lg mb-2 font-bold">{title}</div>
                        {description && <div className="text-sm text-grey-500">{description}</div>}

                        {body}
                    </div>
                </div>
            </div>
            <div
                className="flex items-center justify-end p-4 border-t border-grey-200 dark:border-grey-700 border-solid rounded-b space-x-2">
            <button
                    className="btn btn-default btn-rounded bg-white hover:bg-grey-100 text-grey-900"
                    type="button"
                    onClick={onCancel}>
                    Close
                </button>
            </div>
        </Modal>
    )
}


export const ModalInfoLog = ({title, data, ...rest}) => {
    const {t} = useTranslation();

    return (
        <ModalInfo
            className={`w-full max-w-full lg:max-w-full`}
            icon={
                <span className="h-10 w-10 bg-red-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    <FiFileText size={18} className="stroke-current text-red-500"/>
                </span>
            }
            title={title || t('modal.infolog.title')}
            body={
                <div className={`text-sm text-grey-500 flex flex-col space-y-4`}>
                    <div className={`w-full `}>
                        <CodeMirror label={`Log`} defaultValue={data?.log} mode={CODEMIRROR.MODE.HTML}  />
                    </div>
                    <div className={`w-full `}>
                        <CodeMirror label={`Manifest`} defaultValue={JSON.stringify(data?.manifest, null, 4)} mode={CODEMIRROR.MODE.JS}  />
                    </div>
                </div>
            }
            {...rest}
        />
    )
}



import React from 'react'
import {useLocation} from 'react-router-dom'
import Centered from './centered'
import Empty from './empty'
import Main from './main/index'

const Layouts = ({children}) => {
  let location = useLocation()
  let {pathname} = {...location}
  // let regExp = /^\/(auth|error-page)/g;
  if (/^\/(auth|error-page)/g.test(pathname)) {
    return <Centered>{children}</Centered>
  } else if (['/landing'].includes(pathname)) {
    return <Empty>{children}</Empty>
  } else {
    return <Main>{children}</Main>
  }
}

export default Layouts

import React, {useState, useCallback, useMemo} from 'react'
import SectionTitle from './../../components/section-title'
import Widget from './../../components/widget'
import Api from './../../service/api'
import {useTranslation} from "react-i18next";
// import FormGen from "../../components/form/formgen";
import FormGen from "../../components/form/formgen2";
import { useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications'


const FormPage = ({resource, action, fields, initialValues,
                      prepareFormDataBeforeSubmit, completeRedirect,
                     updateAction = Api.resourceUpdate
                }) => {
    const { addToast } = useToasts()
    const history = useHistory();
    const {t} = useTranslation();
    const onSubmit = (formData, setError, setAlerts) => {
        console.log('FormPage.formData', formData);
        if(prepareFormDataBeforeSubmit && typeof prepareFormDataBeforeSubmit == 'function')
            formData = prepareFormDataBeforeSubmit(formData);
        return Api.getCSRFCookie().then(() => updateAction(resource, formData))
    }
    const onComplete = (response, formData) => {
        console.log(`onComplete.${resource}.response`, response);
        typeof completeRedirect === 'function' ? completeRedirect(response, formData) : history.goBack();

        addToast(t(`resource_page.${resource}.${action}.message_complete`), {
            appearance: 'success',
            autoDismiss: true,
        })
    }

    return (
        <>
            <SectionTitle title={t(`resource_page.${resource}.${action}.title`)}
                          subtitle={t(`resource_page.${resource}.${action}.subtitle`)}/>
            <Widget >
                <FormGen items={fields}
                         initialValues={initialValues}
                         onSubmit={onSubmit}
                         onComplete={onComplete}
                         submitText={t(`resource_page.${resource}.${action}.submit_button`)}/>
            </Widget>
        </>
    )
}
export default FormPage

import { useHistory } from 'react-router-dom'
import React from "react";

export  function LinkButton({to, title, icon, target, onClick, ...rest}) {
    const { push } = useHistory()
    return (
        <button {...rest} onClick={(event) => {
            onClick && onClick(event)
            if(/^(http|skype)/ig.test(to)){
                if(target === '_blank') window.open(to, target);
                else window.location.href = to;
                return;
            }
            push(to)
        }}>
            {icon}
            <span>{title}</span>
        </button>
    )
}

export function Button({children, size = Button.SIZE.DEFAULT, icon: Icon, style = Button.STYLE.GHOST_BLUE, className = '', ...rest}){
    return (
        <a {...rest} className={`btn ${size} btn-rounded ${Icon ? `btn-icon` : ``}  btn-start ${style} ${className}`}>
            {Icon && <Icon className={`stroke-current ${children ? `mr-2`:``}`} />}
            {children}
        </a>
    )
}
Button.SIZE = {
    DEFAULT: 'btn-default',
    SMALL: 'btn-sm',
    LARGE: 'btn-lg',
}
Button.STYLE = {
    GHOST_BLUE: `bg-transparent hover:bg-blue-50 text-blue-500 hover:text-blue-600`,
    RED: `bg-red-500 hover:bg-red-600 text-white`
}

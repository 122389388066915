import React from 'react'
import PropTypes from 'prop-types'

export const TextInput = ({
                              className = '',
                              inputRef = false,
                              inline = false,
                              label = '',
                              labelClassName,
                              name = '',
                              type = 'text',
                              placeholder = '',
                              hint = '',
                              errorMessage = '',
                              validMessage = '',
                              ...rest
                          }) => {
    return (
        <div className={type.toLocaleLowerCase() === 'hidden' ? 'hidden' : `form-element  ${inline ? 'form-element-inline' : ''} ${className}`}>
            {label && <div className={`form-label ${labelClassName}`}>{label}</div>}
            <input
                {...rest}
                {...(inputRef ? {ref: inputRef} : {})}
                name={name}
                type={type}
                className={`form-input ${
                    // errorMessage ? 'border-red-500' : ''
                    errorMessage ? ' form-input-invalid' : ''
                    + validMessage ? ' form-input-valid' : ''
                }`}
                placeholder={placeholder}
            />
            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (typeof hint === 'string' ? <div className="form-hint" dangerouslySetInnerHTML={{__html: hint}}/> : <div className="form-hint">{hint}</div>)}
        </div>
    )
}
TextInput.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}


export const TextArea = ({
                              inputRef = false,
                              inline = false,
                              label = '',
                              labelClassName,
                              name = '',
                              placeholder = '',
                              hint = '',
                              errorMessage = '',
                              validMessage = '',
                              rows = 4,
                              ...rest
                          }) => (
    <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
        {label && <div className={`form-label ${labelClassName}`}>{label}</div>}
        <textarea
            {...rest}
            {...(inputRef ? {ref: inputRef} : {})}
            name={name}
            className={`form-textarea ${
                // errorMessage ? 'border-red-500' : ''
                errorMessage ? ' form-input-invalid' : ''
                + validMessage ? ' form-input-valid' : ''
            }`}
            placeholder={placeholder}
            rows={rows}
        >
            {rest.value}
        </textarea>
        {errorMessage && (
            <div className="form-error">{errorMessage}</div>
        )}
        {validMessage && (
            <div className="form-success">{validMessage}</div>
        )}
        {hint && (typeof hint === 'string' ? <div className="form-hint" dangerouslySetInnerHTML={{__html: hint}}/> : <div className="form-hint">{hint}</div>)}
    </div>
)
TextArea.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string,
    rows: PropTypes.number
}

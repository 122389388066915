import React, {useMemo} from 'react'
import FormGen from '../formgen2'
import {useTranslation} from "react-i18next";
import Api from './../../../service/api'
import {Link} from "react-router-dom";
import {useCookies} from "react-cookie";

export const Signup = ({onComplete}) => {
    const {t} = useTranslation();
    const [cookies] = useCookies(['ref_id']);

    const onSubmit = (formData, setError, setAlerts) => {
        return Api.getCSRFCookie().then(() => Api.signUp(formData))
    }
    let items = useMemo(()=>{
        return [
            {
                name: 'ref_id',
                type: 'hidden',
                defaultValue: cookies.ref_id || '',
            },
            {
                label: t('auth.field.email'),
                error: {required: t('form.error.required', {name: t('auth.field.email')})},
                name: 'email',
                type: 'email',
                autoComplete: "username",
                placeholder: t('form.field.placeholder', {name: t('auth.field.email')})
            },
            {
                label: t('auth.field.password'),
                error: {
                    required: t('form.error.required', {name: t('auth.field.password')}),
                    minLength: {
                        value: 8,
                        message: t('form.error.minlength', {count: 8, name: t('auth.field.password')}),
                    },
                    maxLength: {
                        value: 50,
                        message: t('form.error.maxlength', {count: 50, name: t('auth.field.password')}),
                    }
                },
                name: 'password',
                type: 'password',
                autoComplete: "new-password",
                placeholder: t('form.field.placeholder', {name: t('auth.field.password')})
            },
            {
                label: t('auth.field.password_confirmation'),
                error: {
                    required: t('form.error.required', {name: t('auth.field.password_confirmation')}),
                    validate: {
                        type: 'sameasfield',
                        field: 'password',
                        message: t('form.error.same_as_field', {name: t('auth.field.password')})
                    },
                },
                name: 'password_confirmation',
                type: 'password',
                autoComplete: "new-password",
                placeholder: t('form.field.placeholder', {name: t('auth.field.password_confirmation')})
            },
            {
                label: t('auth.field.name'),
                error: {required: t('form.error.required', {name: t('auth.field.name')})},
                name: 'name',
                type: 'text',
                placeholder: t('form.field.placeholder', {name: t('auth.field.name')})
            },
            {
                label: t('auth.field.company_name'),
                error: {
                    required: t('form.error.required', {name: t('auth.field.company_name')})
                },
                name: 'company_name',
                type: 'text',
                placeholder: t('form.field.placeholder', {name: t('auth.field.company_name')})
            },
            {
                label: t('auth.field.contact_messenger'),
                error: {
                    required: t('form.error.required', {name: t('auth.field.contact_messenger')})
                },
                name: 'contact_messenger',
                type: 'text',
                placeholder: t('form.field.placeholder', {name: t('auth.field.contact_messenger')})
            },
            {
                label: t('auth.field.promo_code'),
                error: {},
                name: 'promo_code',
                type: 'text',
                placeholder: t('form.field.placeholder', {name: t('auth.field.promo_code')})
            },
            // {
            //   label: null,
            //   error: {
            //     required: 'Please agree to our terms of service'
            //   },
            //   name: 'terms',
            //   type: 'checkbox',
            //   options: [{value: true, label: 'I agree to the terms of service'}]
            // },
            // {
            //   label: null,
            //   error: {
            //     required: 'Please agree to our privacy policy'
            //   },
            //   name: 'privacy-policy',
            //   type: 'checkbox',
            //   options: [{value: true, label: 'I agree to the privacy policy'}]
            // },
        ]
    }, [cookies.ref_id]);
    return (
        <>
            <div className="flex flex-col">
                {true ? <FormGen items={items}
                         onSubmit={onSubmit}
                         onComplete={onComplete}
                         submitText={t('auth.signup.submit_button')}/> : 'Registration disabled'}
                <div className="mt-4 mb-2">
                    {/*<SocialMedia />*/}
                </div>
                <div className="flex flex-row w-full">
                    <span className="text-secondary mr-1">{t('auth.have_account')}</span>
                    <span>
                      <Link className="link" to="/auth/signin">
                        {t('auth.signin_here')}
                      </Link>
                    </span>
                </div>
                <div className="w-full">
                    <span>
                      <Link className="link" to="/auth/forgot-password">
                        {t('auth.forgotpassword')}
                      </Link>
                    </span>
                </div>
            </div>
        </>
    )
}

export default Signup

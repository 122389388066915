import React from 'react'
import Widget from "./index";
import {useTranslation} from "react-i18next";
import {FINANCE} from "../../config/resources";

export const WidgetLatestFinances = ({data, limit = 3, className = ''}) => {
  const {t} = useTranslation();
  if(!data || data.length <= 0) return null;
  return (
      <Widget title={t(`widget.latesttopups.title`)} description={t(`widget.latesttopups.description`)} className={className}>

          <table className="table table-lg">
              <thead>
              <tr>
                  <th>{t(`datatable.column.date`)}</th>
                  <th>{t(`datatable.column.type`)}</th>
                  <th>{t(`datatable.column.description`)}</th>
                  <th>{t(`datatable.column.amount`)}</th>
                  <th>{t(`datatable.column.status`)}</th>
              </tr>
              </thead>
              <tbody>
              {data.slice(0, limit).map((item, i) => {
                  let date = new Date(`${item.created_at}`);
                  let amount = item.amount;
                  switch(item.type){
                      case FINANCE.TYPE.REFUND:
                          amount = <>
                              <span className={`text-green-800`}>+${amount}</span>
                          </>
                          break;
                      case FINANCE.TYPE.WITHDRAW:
                          amount = <>
                                <span className={`text-red-800`}>-${amount}</span>
                              <small>
                                <div className={`font-light text-grey-500 text-xs`}>Will receive{` `}${item.data?.will_receive}</div>
                                <div className={`font-light text-grey-500 text-xs -mt-1`}>Fee (1% + 1$){` `}${item.data?.fee}</div>
                              </small>
                          </>
                          break;
                      case FINANCE.TYPE.TOPUP:
                          if(item.data?.paid_amount && parseFloat(item.data?.paid_amount) !== parseFloat(amount)){
                              amount = <>
                                  <span className={`text-green-800`}>+${item.data?.paid_amount}</span>
                                  <span className={`font-light text-grey-500 line-through text-xs`}>${amount}</span>
                              </>
                          }else{
                              amount = <span className={`text-green-800`}>+${amount}</span>
                          }

                          break;
                      case FINANCE.TYPE.SUBSCRIPTION_RENEWAL:
                          amount = <span className={`text-red-800`}>-${amount}</span>
                          break;
                      case FINANCE.TYPE.REFERRAL_BONUS:
                          if(item.data?.hold_until_at && item.data?.hold_until_at > new Date().toISOString().split('T')[0]){
                              amount = <>
                                  <span className={`font-bold`}>{t(`widget.latesttopups.in_hold`)} {item.data.hold_until_at}</span>
                                  <span className={`font-light text-grey-500 text-xs`}>${amount}</span>
                              </>
                          }else{
                              amount = <span className={`text-green-800`}>+${amount}</span>
                          }

                          break;
                  }
                  let details = '';
                  switch(item.type) {
                      case FINANCE.TYPE.WITHDRAW:
                          details = <small className={`mt-1 `}>
                              <div>{t(`form.field.wallet_address`)}: <a href={`https://tronscan.org/#/address/${item.data?.wallet}`} className={`hover:text-blue-600 cursor-pointer`} target={"_blank"}>{item.data?.wallet}</a></div>
                              {item.data?.tx_id && <div>{t(`form.field.txid`)}: <a href={`https://tronscan.org/#/transaction/${item.data?.tx_id}`} className={`hover:text-blue-600 cursor-pointer`} target={"_blank"}>
                                  {`${item.data?.tx_id.substr(0,15)}...${item.data?.tx_id.substr(-15)}`}
                              </a></div>}
                          </small>
                          break;
                  }
                  return (
                      <tr key={item.order_id}>
                          <td>
                              <small className={"text-xs font-medium"}>
                                  {date.toLocaleDateString()}<br/>{date.toLocaleTimeString()}
                              </small>
                          </td>
                          <td>{t(`list.finance.type_${item.type}`)}</td>
                          <td>
                              <div className={`flex flex-col gap-2`}>
                                  <div className={`font-light text-grey-500 text-sm`}><small>{t(`widget.latesttopups.order_id`)}: {item.order_id}</small></div>
                                  <div className={`mt-0`}>{item.description}</div>
                                  {details}
                              </div>
                          </td>
                          <td>
                              <div className={`flex flex-col gap-1`}>
                                  {amount}
                                  {item.balance_new && <small className={`font-light text-grey-500 `}>{t(`widget.latesttopups.balance`)}: {item.balance_new}</small>}
                              </div>
                          </td>
                          <td className={`${item.status === FINANCE.STATUS.ERROR ? 'text-red-800' : ''}`}>
                              {t(`list.finance.status_${item.status}`)}
                              {item.status === FINANCE.STATUS.ERROR && item.data?.error_message && <small className={`block mt-2`}>
                                  {t(`list.finance.error.${item.data?.error_message}`)}
                              </small>}
                          </td>
                      </tr>
                    )
              })}
              </tbody>
          </table>

      </Widget>
  )
}


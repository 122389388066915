import React, {useState} from 'react'
import AuthLayout from './layout'
import {Signup} from '../../components/form/auth/signup'
import {useTranslation} from 'react-i18next';
import CompleteAndSignIn from "../../components/form/auth/complete.signin";
import {useAppSelector} from "../../redux/store";

const Index = () => {
    const {t} = useTranslation();
    const {settings} = useAppSelector()
    const [{isSignupComplete, signupData}, signUpComplete] = useState({isSignupComplete: false, signupData: false})

    return (
        <AuthLayout imgUrl="/pages/auth/illustration.svg"
                    title={t('auth.signup.title', {projectName: settings.project_name})}
                    description={t('auth.signup.description')} >
            {!isSignupComplete ?
                <Signup onComplete={(result, data) => signUpComplete({isSignupComplete: true, signupData: data})}/>
                : <CompleteAndSignIn title={t('auth.signup.congratulations')}
                                     description={t('auth.signup.complete_description')}
                                     buttonText={t('auth.signup.complete_button')}
                                     signinData={signupData} /> }
        </AuthLayout>
    )
}

export default Index

import {createStore, applyMiddleware, compose} from 'redux'
import { useSelector, shallowEqual } from 'react-redux'
import thunk from 'redux-thunk';
import rootReducer from './reducers'

import Reactotron from './../service/reactotron.config'

import {openReplayReduxMiddleware} from './../service/open.replay'


export const store = createStore(
    rootReducer,
    Reactotron ? compose(applyMiddleware(thunk, openReplayReduxMiddleware), Reactotron.createEnhancer()) : applyMiddleware(thunk, openReplayReduxMiddleware)
)



export function useAppSelector(selector = state=>state, equalityFn = shallowEqual) {
    return useSelector(selector, shallowEqual)
}

import React from 'react'
import {useHistory} from 'react-router-dom'
import AuthLayout from './layout'
import { useTranslation } from 'react-i18next'

export const EmailVerifyError = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onContinue = (e) => {
    history.push("/auth/signin");
  }

  return (
     <AuthLayout imgUrl="/pages/auth/illustration.svg"
                 title={t('auth.email_verification.title')}
                 >
       <div className="w-full mb-6">
         <div className="text-red-700 font-black ">
           <h6>{t('auth.email_verification.error')}</h6>
         </div>

         <button
             onClick={onContinue}
             className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded mt-6"
         >{t('auth.email_verification.complete_button')}</button>
       </div>
     </AuthLayout>
  )
}

export const EmailVerifyCompleted = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onContinue = (e) => {
    history.push("/auth/signin");
  }

  return (
      <AuthLayout imgUrl="/pages/auth/illustration.svg"
                  title={t('auth.email_verification.title')}
      >
        <div className="w-full mb-6">
          <div className="text-green-700 font-black ">
            <h6>{t('auth.email_verification.completed')}</h6>
          </div>

          <button
              onClick={onContinue}
              className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded mt-6"
          >{t('auth.email_verification.complete_button')}</button>
        </div>
      </AuthLayout>
  )
}

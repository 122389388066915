import React, {useCallback, useEffect, useState} from 'react'
import PropTypes from 'prop-types'

export function isCheckBoxEnabled(value){
    return value === true
    || value === 'true'
    || parseInt(value) === 1
}

export const CheckboxOld = ({
                             inputRef = false,
                             inline = false,
                             label = 'Label',
                             options = [
                                 // {value: 0, name: 'checkbox1', label: 'Option 1'},
                                 // {value: 1, name: 'checkbox1', label: 'Option 2'}
                             ],
                             // defaultValue = [], //active options
                             hint = '',
                             errorMessage = '',
                             validMessage = '',
                             className = '',
                         }) => {
    // options = useMemo(()=>{
    //     if(options && options.length > 0){
    //         options = options.map((option)=>{
    //             option.value = option.name && defaultValue && defaultValue.length > 0 && (
    //                 defaultValue.includes(option.name)
    //                 || defaultValue.includes(option.name.match(/\[(\w+)\]/)[1])
    //             ) ? true : option.value;
    //             return option;
    //         })
    //     }
    //     return options;
    // }, [options, defaultValue]);

    return (
        <div className={`form-element ${inline ? 'form-element-inline' : ''} ${className}`}>
            <div className="form-label">{label}</div>
            <div className="flex items-center justify-start space-x-2">
                {options.map((option, i) => (
                    <div className="inline-flex items-center space-x-2" key={i}>
                        <input
                            key={option.name+'_'+option.value}
                            ref={inputRef}
                            type="checkbox"
                            defaultChecked={option.value}
                            id={i+'_'+option.name}
                            name={option.name}
                            className={`form-checkbox text-blue-500 h-4 w-4 ${
                                // errorMessage ? 'border-red-500' : ''
                                errorMessage ? ' form-checkbox-invalid text-red-500 ' : ''
                                + validMessage ? ' form-checkbox-valid text-green-500' : ''
                            }`}
                        />
                        <label htmlFor={i+'_'+option.name} className={` ${
                            errorMessage ? ' text-red-500 ' : ''
                            + validMessage ? ' text-green-500' : ''
                        }`}>{option.label}</label>
                    </div>
                ))}
            </div>
            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (<div className="form-hint">{hint}</div>)}
        </div>
    )
}
CheckboxOld.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            name: PropTypes.string,
            label: PropTypes.string
        })
    ),
    hint: PropTypes.string,
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}



export const Checkbox = ({
                                inputRef = undefined,
                                inline = false,
                                label = 'Label',
                                name = '',
                                defaultValue = false,
                                hint = '',
                                errorMessage = '',
                                validMessage = '',
                                className = '',
                                ...rest
                            }) => {
    const [value, setValue] = useState(!!defaultValue);

    const _onChange = useCallback((e)=>{
        rest.onChange && rest.onChange(e, e.target.checked);
        setValue(e.target.checked)
    }, [])

    useEffect(()=>{
        setValue(!!defaultValue)
    }, [defaultValue])

    return (
        <div className={`form-element ${inline ? 'form-element-inline' : ''} ${className}`}>
            <div className="flex items-center justify-start space-x-2">
                <input
                    {...rest}
                    key={`${name}-${value}`}
                    ref={inputRef}
                    name={name}
                    type="checkbox"
                    defaultChecked={value}
                    id={`chkbox_${name}`}
                    onChange={_onChange}
                    className={`form-checkbox text-blue-500 h-4 w-4 ${
                        // errorMessage ? 'border-red-500' : ''
                        errorMessage ? ' form-checkbox-invalid text-red-500 ' : ''
                        + validMessage ? ' form-checkbox-valid text-green-500' : ''
                    }`}
                />
                <label htmlFor={`chkbox_${name}`} className={` ${
                    errorMessage ? ' text-red-500 ' : ''
                    + validMessage ? ' text-green-500' : ''
                }`}>{label}</label>
            </div>
            {errorMessage && (
                <div className="form-error">{errorMessage}</div>
            )}
            {validMessage && (
                <div className="form-success">{validMessage}</div>
            )}
            {hint && (<div className="form-hint ml-6">{hint}</div>)}
        </div>
    )
}
Checkbox.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultValue: PropTypes.any,
    hint: PropTypes.string,
    errorMessage: PropTypes.string,
    validMessage: PropTypes.string
}


import {useTranslation} from "react-i18next";
import {FiX} from "react-icons/fi";
import React, {useCallback, useState} from "react";
import {ModalConfirm} from "./modal.confirm";
import {MdCreditScore} from "react-icons/md";
import Api, {API_ERROR} from "../../service/api";
import Alert from "../alert";
import {useToasts} from "react-toast-notifications";
import {Button} from "../button";
import {ModalTopUp} from "./modal.topup";
import {useDispatch} from "react-redux";
import {SettingsAction} from "../../redux/reducers/settings";
import {UserAction} from "../../redux/reducers/user";
import {useAppSelector} from "../../redux/store";


export const ModalSubscriptionRenew = ({teamId, subscriptionTitle, subscriptionPrice, onConfirm, ...rest}) => {
    const {t} = useTranslation();
    const {settings} = useAppSelector()
    const { addToast } = useToasts()
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isTopUpModalVisible, setTopUpModalVisible] = useState(false)
    const dispatch = useDispatch()

    const _onConfirm = useCallback(async ()=>{
        setIsLoading(true);
        setError(false);
        try{
            let result = await Api.paymentRenewSubscription(teamId);

            dispatch(UserAction.loadUserInfo());

            addToast(t(`modal.subscriptionrenew.renewal_success`, {endDate: result?.subscription?.date_end}), {
                appearance: 'success',
                autoDismiss: true,
            })
            onConfirm && onConfirm();
        }catch(e){
            setError(e);
            console.log(`paymentRenewSubscription Error `, e);
        }
        setIsLoading(false);
    }, [onConfirm])

    return (
        <ModalConfirm
            isModal={true}
            isLoading={isLoading}
            icon={
                <span className="h-10 w-10 bg-blue-100 text-white flex items-center justify-center rounded-full text-lg font-display font-bold">
                    {/*<FiX size={18} className="stroke-current text-red-500"/>*/}
                    <MdCreditScore className={`text-blue-500`} />
                </span>
            }
            title={t('modal.subscriptionrenew.title')}
            body={
                <div className="text-base text-grey-500 flex flex-col gap-4 mt-2">

                    {error &&
                        <div className="flex flex-col w-full mb-2">
                            <Alert
                                color="bg-transparent border-red-500 text-red-500 mb-2"
                                borderLeft
                                raised>
                                {error?.message || `Unknown error`}

                                {error?.code === API_ERROR.NOT_ENOUGH_FUNDS &&
                                    <div className={`mt-2`}>
                                        <Button size={Button.SIZE.SMALL} onClick={()=>setTopUpModalVisible(true)} >{t(`modal.subscriptionrenew.button_topup`)}</Button>
                                        <ModalTopUp
                                            isOpen={isTopUpModalVisible}
                                            onCancel={()=>setTopUpModalVisible(false)}
                                        />
                                    </div>}
                            </Alert>
                        </div>}

                    <div>
                        <div className={`font-light text-sm`}>{t(`form.field.subscription`)}</div>
                        <div className={`text-black`}>{subscriptionTitle}</div>
                    </div>
                    {settings.monetization_active && <div>
                        <div className={`font-light text-sm`}>{t(`form.field.price`)}</div>
                        <div className={`text-black`}>${subscriptionPrice}</div>
                    </div>}
                    <div>
                        <div className={`font-light text-sm`}>{t(`form.field.period.title`)}</div>
                        <div className={`text-black`}>{t('modal.subscriptionrenew.period.1month')}</div>
                    </div>
                </div>
            }
            buttonTitle={t('modal.subscriptionrenew.button_confirm')}
            buttonClassName="btn btn-default btn-rounded bg-blue-500 hover:bg-blue-600 text-white"
            {...rest}
            onConfirm={_onConfirm}
        />
    )
}

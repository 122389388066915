import React from 'react'
import {UserAction} from "../../../redux/reducers/user";
import {useDispatch} from "react-redux";

export const CompleteAndSignIn = ({title, description, buttonText, signinData}) => {
    const dispatch = useDispatch();

    const onContinue = (e) => {
        console.log('onContinue', signinData);
        dispatch(UserAction.signIn(signinData))
            .then((data) => {
                console.log('successResponse', data);
            }, (error) => {
                console.log('Error response', error);
            });
    }
    return (
        <>
            <div className="w-full mb-6">
                <div className="text-green-700 font-black ">
                    <h6>{title}</h6>
                </div>
                <p>{description}</p>
                <button
                    onClick={onContinue}
                    className="btn btn-default bg-blue-500 hover:bg-blue-600 text-white btn-rounded mt-6"
                >{buttonText}</button>
            </div>
        </>
    )
}

export default CompleteAndSignIn
